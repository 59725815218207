/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { Fragment, useEffect, useState } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import SideMenu from "../menu/menu";
import HorizontalMenu from "../horizontal-menu/menu";
import menuService from "../../services/menu.service";
import ButtonNeoGen from "../../layout/button";

const user = {
    name: "Tom Cook",
    email: "tom@example.com",
    imageUrl:
        "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
};
const navigation = [
    { name: "Dashboard", href: "#", current: true },
    { name: "Team", href: "#", current: false },
    { name: "Projects", href: "#", current: false },
    { name: "Calendar", href: "#", current: false },
    { name: "Reports", href: "#", current: false },
];
const userNavigation = [
    { name: "Your Profile", href: "#" },
    { name: "Settings", href: "#" },
    { name: "Sign out", href: "#" },
];

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
}

type DashboardTopProps = {
    className?: string;
    children?: React.ReactNode;
};

export default function DashboardTop(props: DashboardTopProps) {
    const [darkMode, setDarkMode] = useState(false);
    const [pageTitle, setPageTitle] = useState("Unknown");
    const [haveResettableUser, setHaveResettableUser] = useState(localStorage.getItem("haveResettableUser") === "1");
    //  from react router
    const url = window.location.pathname.split("/").pop();
    // get the associate json for the menuService entry based on the pageName varuable
    useEffect(() => {
        if (url) {
            menuService.getByURL(url).then((data) => {
                // console.log({ url, name: data?.name });
                setPageTitle(data?.name ?? "Unknown");
            });
        }
    }, [url]);

    function resetUser() {
        // alert("Resetting user");
        const items = { ...localStorage };
        for (const key in items) {
            // alert("Testing key: " + key + "   ");//
            if (!key.startsWith("RESETTER")) {
                // alert("Skipping key: " + key + "   ");
                continue;
            }
            // alert("Setting key: " + key.substring(8) + "   ");
            localStorage.setItem(key.substring(8), items[key]);
            localStorage.removeItem(key);
        }
        localStorage.setItem("haveResettableUser", "0");
        window.location.reload();
    }

    // lg:border-t lg:border-b lg:border-gray-200 dark:border-gray-900

    return (
        <>
            <div className="min-h-full">
                <div className="bg-gradient-to-b from-slate-400 to-slate-300 dark:from-slate-800 dark:to-slate-900 dark:bg-indigo-900 pb-40">
                    <HorizontalMenu darkMode={darkMode} setDarkMode={setDarkMode} />
                    {/* <header className="pt-10">
                        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                            <h1 className="text-4xl font-light tracking-wider text-gray-300">{pageTitle}</h1>
                        </div>
                    </header> */}
                </div>

                <main className="-mt-36">
                    <div className="mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8">
                        {/* Replace with your content */}
                        {/* <div className="rounded-lg bg-white px-5 py-6 shadow sm:px-6"> */}
                        {haveResettableUser && (
                            <div className="mb-3">
                                <ButtonNeoGen
                                    icon="fa fa-recycle"
                                    type="primary"
                                    onClick={() => {
                                        console.log("Resetting user");
                                        resetUser();
                                        // setUser(null);
                                        // setAssumedUser(null);
                                    }}
                                    // className="bg-indigo-700 dark:bg-gray-800 text-white"
                                >
                                    Reset User
                                </ButtonNeoGen>
                            </div>
                        )}

                        {props.children}
                        {/* <div className="h-96 rounded-lg border-4 border-dashed border-gray-200" /> */}
                        {/* </div> */}
                        {/* /End replace */}
                    </div>
                </main>
            </div>
        </>
    );
}

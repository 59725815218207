/**
 *
 *
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 9/19/20, 10:58 AM
 *
 */

import { AxiosResponse } from "axios";
import { Flowroute } from "../typings/api/flowroute";
import { Interaction } from "../typings/api/interaction";
import { InteractionType } from "../typings/api/interaction-type";
import { NeogenClickToCall } from "../typings/api/neogen-click-to-call";
import APIService from "./api.service";
import AuthService from "./auth.service";

class InteractionTypesService extends APIService<InteractionType> {
    constructor() {
        super("interaction-types");
    }
}

export default new InteractionTypesService();

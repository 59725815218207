import { z } from "zod";

export const userSchema = z.object({
    id: z.string(),
    company: z.number().optional(),
    name: z.string().optional(),
    email: z.string(),
    firstName: z.string().nullish(),
    lastName: z.string().nullish(),
});

export type User = z.infer<typeof userSchema>;

/**
 *
 *
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 9/19/20, 10:58 AM
 *
 */

import axios, { AxiosResponse } from "axios";
import { NeoGenHelpDocs } from "../typings/api";
import APIService from "./api.service";

class HelpDocsService extends APIService<NeoGenHelpDocs> {
    constructor() {
        super("help-docs");
    }
    async search(query: string) {
        const r = await axios.post("https://helpsearch.neogen.ai/search", { query });
        if (r) {
            if (Array.isArray(r.data)) {
                const ids = r.data.map((entry: any) => entry.id);
                console.log(r.data);
                const filter = {
                    where: {
                        and: [
                            {
                                id: {
                                    inq: ids,
                                },
                            },
                            {
                                public: 1,
                            },
                            {
                                active: 1,
                            },
                        ],
                    },
                };
                console.log(filter);
                return this.getURL(this.endpoint + "?filter=" + encodeURIComponent(JSON.stringify(filter)));
            }
        } else {
            return null;
        }
    }
    getArticles(): Promise<void | AxiosResponse<NeoGenHelpDocs[]>> {
        const filter = {
            where: {
                public: 2,
            },
        };
        return this.getURL(this.endpoint + "?filter=" + encodeURIComponent(JSON.stringify(filter)));
    }
}

export default new HelpDocsService();

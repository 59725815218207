import { makeRequest } from "../../../jason-proof-of-concept/shared/utils";

export const createStep = async ({ authToken, data }: { authToken: string; data: any }) => {
    const response = await makeRequest({
        method: "post",
        path: "/processflows",
        authToken,
        data,
    });

    return response.data;
};

import { ClearERCUser } from "../../../typings/api/clear-erc-user";
import { makeRequest } from "../../shared/utils";

export const setPassword = async ({
    verificationToken,
    userId,
    password,
}: {
    verificationToken: string;
    userId: string;
    password: string;
}) => {
    const response = await makeRequest({
        method: "post",
        path: "/set-password",
        data: {
            verificationToken,
            userId,
            password,
        },
    });

    const user = response.data as ClearERCUser;

    return user;
};

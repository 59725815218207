/**
 *
 *
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 8/25/20, 6:24 AM
 *
 */

import { BusinessRule } from "../typings/api/business-rule";
import APIService from "./api.service";

// const debug        = require('debug')('Neogen:CDRService');

class BusinessRuleService extends APIService<BusinessRule> {
    constructor() {
        super("business-rules");
    }
    getAllByProductId() {
        const filter = {};
        return this.getFilteredWhere(filter);
    }
}

export default new BusinessRuleService();

import { useContext, useState } from "react";
import { Switch } from "@headlessui/react";
// import { Fragment, useState } from 'react'
// import { Dialog, Switch, Transition } from '@headlessui/react'
import PageDescription from "../../layout/page-description";
import UserContext from "../../services/user-context";
import authService from "../../services/auth.service";
import { useRecoilState } from "recoil";
import userAtom from "../../atoms/userAtom";
import { ClearERCUser } from "../../typings/api/clear-erc-user";
// import { MagnifyingGlassIcon } from '@heroicons/react/24/solid'

const tabs = [
    { name: "General", href: "#", current: true },
    { name: "Password", href: "#", current: false },
    { name: "Notifications", href: "#", current: false },
    { name: "Plan", href: "#", current: false },
    { name: "Billing", href: "#", current: false },
    { name: "Team Members", href: "#", current: false },
];

// const user = {
//     name: 'Lisa Marie',
//     email: 'lisamarie@example.com',
//     imageUrl:
//         'https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=80',
// }
// const navigation = [
//     { name: 'Dashboard', href: '#' },
//     { name: 'Jobs', href: '#' },
//     { name: 'Applicants', href: '#' },
//     { name: 'Company', href: '#' },
// ]
// const userNavigation = [
//     { name: 'Your Profile', href: '#' },
//     { name: 'Settings', href: '#' },
//     { name: 'Sign out', href: '#' },
// ]
// const subNavigation = [
//     { name: 'Profile', href: '#', icon: UserCircleIcon, current: false },
//     { name: 'Account', href: '#', icon: CogIcon, current: false },
//     { name: 'Password', href: '#', icon: KeyIcon, current: false },
//     { name: 'Notifications', href: '#', icon: BellIcon, current: false },
//     { name: 'Plan & Billing', href: '#', icon: CreditCardIcon, current: true },
//     { name: 'Integrations', href: '#', icon: ViewGridAddIcon, current: false },
// ]
// const plans = [
//     { name: 'Startup', priceMonthly: 29, priceYearly: 290, limit: 'Up to 5 active job postings' },
//     { name: 'Business', priceMonthly: 99, priceYearly: 990, limit: 'Up to 25 active job postings' },
//     { name: 'Enterprise', priceMonthly: 249, priceYearly: 2490, limit: 'Unlimited active job postings' },
// ]
// const payments = [
//     {
//         id: 1,
//         date: '1/1/2020',
//         datetime: '2020-01-01',
//         description: 'Business Plan - Annual Billing',
//         amount: 'CA$109.00',
//         href: '#',
//     },
//     // More payments...
// ]

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
}

export default function Settings() {
    // const [selectedPlan, setSelectedPlan] = useState(plans[1])
    // const [annualBillingEnabled, setAnnualBillingEnabled] = useState(true)
    //
    // const [sidebarOpen, setSidebarOpen] = useState(false)
    const [automaticTimezoneEnabled, setAutomaticTimezoneEnabled] = useState(true);
    const [autoUpdateApplicantDataEnabled, setAutoUpdateApplicantDataEnabled] = useState(false);
    const [user, setUser] = useRecoilState<ClearERCUser>(userAtom);
    return (
        <main className="flex-1">
            <div className="relative ">
                <div className="">
                    <PageDescription title="Settings" description="Manage your account settings and preferences." />
                    {/* <div className='w-full break-all'>
                        {authService.getCurrentUser().token}
                    </div> */}
                    <div className=" bg-white rounded-xl my-5 shadow-lg px-5">
                        <div className="py-6">
                            {/* Tabs */}
                            <div className="lg:hidden">
                                <label htmlFor="selected-tab" className="sr-only">
                                    Select a tab
                                </label>
                                <select
                                    id="selected-tab"
                                    name="selected-tab"
                                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm rounded-md"
                                    defaultValue={tabs.find((tab) => tab.current)?.name}
                                >
                                    {tabs.map((tab) => (
                                        <option key={tab.name}>{tab.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="hidden lg:block">
                                <div className="border-b border-gray-200">
                                    <nav className="-mb-px flex space-x-8">
                                        {tabs.map((tab) => (
                                            <a
                                                key={tab.name}
                                                href={tab.href}
                                                className={classNames(
                                                    tab.current
                                                        ? "border-purple-500 text-purple-600"
                                                        : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                                                    "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm",
                                                )}
                                            >
                                                {tab.name}
                                            </a>
                                        ))}
                                    </nav>
                                </div>
                            </div>

                            {/* Description list with inline editing */}
                            <div className="mt-10 divide-y divide-gray-200">
                                <div className="space-y-1">
                                    <h3 className="text-lg leading-6 font-medium text-gray-900">Profile</h3>
                                    <p className="max-w-2xl text-sm text-gray-500">
                                        This information will be displayed publicly so be careful what you share.
                                    </p>
                                </div>
                                <div className="mt-6">
                                    <dl className="divide-y divide-gray-200">
                                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                                            <dt className="text-sm font-medium text-gray-500">Name</dt>
                                            <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                <span className="flex-grow">Chelsea Hagon</span>
                                                <span className="ml-4 flex-shrink-0">
                                                    <button
                                                        type="button"
                                                        className="bg-white rounded-md font-medium text-purple-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                                                    >
                                                        Update
                                                    </button>
                                                </span>
                                            </dd>
                                        </div>
                                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
                                            <dt className="text-sm font-medium text-gray-500">Photo</dt>
                                            <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                <span className="flex-grow">
                                                    <img
                                                        className="h-8 w-8 rounded-full"
                                                        src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                                        alt=""
                                                    />
                                                </span>
                                                <span className="ml-4 flex-shrink-0 flex items-start space-x-4">
                                                    <button
                                                        type="button"
                                                        className="bg-white rounded-md font-medium text-purple-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                                                    >
                                                        Update
                                                    </button>
                                                    <span className="text-gray-300" aria-hidden="true">
                                                        |
                                                    </span>
                                                    <button
                                                        type="button"
                                                        className="bg-white rounded-md font-medium text-purple-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                                                    >
                                                        Remove
                                                    </button>
                                                </span>
                                            </dd>
                                        </div>
                                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
                                            <dt className="text-sm font-medium text-gray-500">Email</dt>
                                            <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                <span className="flex-grow">chelsea.hagon@example.com</span>
                                                <span className="ml-4 flex-shrink-0">
                                                    <button
                                                        type="button"
                                                        className="bg-white rounded-md font-medium text-purple-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                                                    >
                                                        Update
                                                    </button>
                                                </span>
                                            </dd>
                                        </div>
                                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-b sm:border-gray-200">
                                            <dt className="text-sm font-medium text-gray-500">Job title</dt>
                                            <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                <span className="flex-grow">Human Resources Manager</span>
                                                <span className="ml-4 flex-shrink-0">
                                                    <button
                                                        type="button"
                                                        className="bg-white rounded-md font-medium text-purple-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                                                    >
                                                        Update
                                                    </button>
                                                </span>
                                            </dd>
                                        </div>
                                    </dl>
                                </div>
                            </div>

                            <div className="mt-10 divide-y divide-gray-200">
                                <div className="space-y-1">
                                    <h3 className="text-lg leading-6 font-medium text-gray-900">Account</h3>
                                    <p className="max-w-2xl text-sm text-gray-500">
                                        Manage how information is displayed on your account.
                                    </p>
                                </div>
                                <div className="mt-6">
                                    <dl className="divide-y divide-gray-200">
                                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                                            <dt className="text-sm font-medium text-gray-500">Language</dt>
                                            <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                <span className="flex-grow">English</span>
                                                <span className="ml-4 flex-shrink-0">
                                                    <button
                                                        type="button"
                                                        className="bg-white rounded-md font-medium text-purple-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                                                    >
                                                        Update
                                                    </button>
                                                </span>
                                            </dd>
                                        </div>
                                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
                                            <dt className="text-sm font-medium text-gray-500">Date format</dt>
                                            <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                <span className="flex-grow">DD-MM-YYYY</span>
                                                <span className="ml-4 flex-shrink-0 flex items-start space-x-4">
                                                    <button
                                                        type="button"
                                                        className="bg-white rounded-md font-medium text-purple-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                                                    >
                                                        Update
                                                    </button>
                                                    <span className="text-gray-300" aria-hidden="true">
                                                        |
                                                    </span>
                                                    <button
                                                        type="button"
                                                        className="bg-white rounded-md font-medium text-purple-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                                                    >
                                                        Remove
                                                    </button>
                                                </span>
                                            </dd>
                                        </div>
                                        <Switch.Group
                                            as="div"
                                            className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5"
                                        >
                                            <Switch.Label as="dt" className="text-sm font-medium text-gray-500" passive>
                                                Automatic timezone
                                            </Switch.Label>
                                            <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                <Switch
                                                    checked={automaticTimezoneEnabled}
                                                    onChange={setAutomaticTimezoneEnabled}
                                                    className={classNames(
                                                        automaticTimezoneEnabled ? "bg-purple-600" : "bg-gray-200",
                                                        "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:ml-auto",
                                                    )}
                                                >
                                                    <span
                                                        aria-hidden="true"
                                                        className={classNames(
                                                            automaticTimezoneEnabled
                                                                ? "translate-x-5"
                                                                : "translate-x-0",
                                                            "inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200",
                                                        )}
                                                    />
                                                </Switch>
                                            </dd>
                                        </Switch.Group>
                                        <Switch.Group
                                            as="div"
                                            className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-b sm:border-gray-200"
                                        >
                                            <Switch.Label as="dt" className="text-sm font-medium text-gray-500" passive>
                                                Auto-update applicant data
                                            </Switch.Label>
                                            <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                <Switch
                                                    checked={autoUpdateApplicantDataEnabled}
                                                    onChange={setAutoUpdateApplicantDataEnabled}
                                                    className={classNames(
                                                        autoUpdateApplicantDataEnabled
                                                            ? "bg-purple-600"
                                                            : "bg-gray-200",
                                                        "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:ml-auto",
                                                    )}
                                                >
                                                    <span
                                                        aria-hidden="true"
                                                        className={classNames(
                                                            autoUpdateApplicantDataEnabled
                                                                ? "translate-x-5"
                                                                : "translate-x-0",
                                                            "inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200",
                                                        )}
                                                    />
                                                </Switch>
                                            </dd>
                                        </Switch.Group>
                                    </dl>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

import { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import Swal from "sweetalert2";
import processflowGroupRoleGroupService from "../../../services/processflow-group-role-group.service";
import processflowGroupService from "../../../services/processflow-group.service";
import roleGroupService from "../../../services/role-group.service";
import { ProcessFlowGroup } from "../../../typings/api/processflow-group";
import { ProcessFlowGroupRoleGroup } from "../../../typings/api/processflow-group-role-group";
import CheckBoxNeoGenControlled from "../../../layout/checkbox-controlled";
import InputControlled from "../../../layout/input-controlled";
import ModalDialog from "../../../layout/modal-dialog";
import PrintPre from "../../../layout/print-pre";
import TextAreaNeoGenControlled from "../../../layout/text-area-controlled";
import Loader2 from "../../utilities/Loader2";

export default function AddEditGroup(props: AddEditGroupProps) {
    const [group, setGroup] = useState<ProcessFlowGroup | null>(null);
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [roles, setRoles] = useState<number[]>([]);
    const cache = useQueryClient();

    useEffect(() => {
        if (props.isEdit && props.group) {
            setGroup(props.group);
            setName(props.group.name);
            setDescription(props.group.description);
            console.error(props.group);
            setRoles(props.group.roleGroups.map((r) => r.id ?? -1));
        }
    }, [props.isEdit, props.group]);

    const roleGroupQuery = useQuery(["roleGroups"], async () => {
        const response = await roleGroupService.getAll();
        if (response) {
            return response.data;
        }
    });

    function saveGroup() {
        if (!group && props.isEdit) {
            Swal.fire({
                title: "Error",
                text: "Please fill in all fields",
                icon: "error",
                confirmButtonText: "OK",
            });
            console.log(props);
            return;
        } else {
            console.log({ group });

            // Create new group
            const newGroup: Group = {
                name,
                description,
            };

            if (props.isEdit && props.group) {
                // Update existing group
                processflowGroupService
                    .update(props.group.id, newGroup)
                    .then(async (response) => {
                        console.error(response);
                        if (response) {
                            const newRoles = roles.filter((r) => r !== -1);
                            const newRoleGroups = newRoles.map((r) => ({
                                roleGroupId: r,
                                processflowGroupId: props.group?.id,
                            }));
                            // Remove groups that are no longer present
                            if (props.group) {
                                const oldRoleGroups = props.group.roleGroups.filter(
                                    (r: any) => !newRoles.includes(r.id),
                                );
                                for (const r of oldRoleGroups) {
                                    console.error("removing", r);
                                    if (props.group) {
                                        const filter = {
                                            and: [
                                                {
                                                    processflowGroupId: props.group.id,
                                                    roleGroupId: r.id,
                                                },
                                            ],
                                        };
                                        await processflowGroupRoleGroupService.deleteWhere(
                                            processflowGroupRoleGroupService.endpoint,
                                            filter,
                                        );
                                    }
                                }

                                for (const r of newRoleGroups) {
                                    console.error("adding", r);
                                    await processflowGroupRoleGroupService.create(r);
                                }

                                props.close();
                                cache.invalidateQueries(["processflowGroups"]);
                                cache.invalidateQueries(["processflow-stages"]);
                                cache.invalidateQueries(["processflows"]);
                                cache.invalidateQueries(["processflow-groups"]);
                                Swal.fire({
                                    title: "Success",
                                    text: "Group updated successfully",
                                    icon: "success",
                                    showConfirmButton: false,
                                    timer: 1500,
                                });
                            }
                        }
                    })
                    .catch((error) => {
                        Swal.fire({
                            title: "Error",
                            text: error.message,
                            icon: "error",
                        });
                    });
            } else {
                // Save new group
                processflowGroupService
                    .create(newGroup)
                    .then((response) => {
                        if (response) {
                            console.log(response.data);
                            const newId = response.data.id;
                            // Create the process flow group role groups
                            for (const role of roles) {
                                const newRoleGroupEntry: ProcessFlowGroupRoleGroup = {
                                    processflowGroupId: newId ?? -1,
                                    roleGroupId: role,
                                };
                                processflowGroupRoleGroupService
                                    .create(newRoleGroupEntry)
                                    .then((response) => {
                                        if (response) {
                                            console.log(response.data);
                                            props.close();
                                            Swal.fire({
                                                title: "Success",
                                                text: "Group saved successfully",
                                                icon: "success",
                                                showCancelButton: false,
                                                showConfirmButton: false,
                                                timer: 1500,
                                            });
                                            cache.invalidateQueries(["processflowGroups"]);
                                            cache.invalidateQueries(["processflow-stages", newId]);
                                            cache.invalidateQueries(["processflows"]);
                                            cache.invalidateQueries(["processflow-groups"]);
                                            return;
                                        }
                                    })
                                    .catch((error) => {
                                        console.error(error);
                                        Swal.fire({
                                            title: "Error",
                                            text: "Error saving group: " + error.message,
                                            icon: "error",
                                            showConfirmButton: false,
                                            timer: 1500,
                                        });
                                    });
                            }
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        Swal.fire({
                            title: "Error",
                            text: "Error saving group: " + error.message,
                            icon: "error",
                            showConfirmButton: false,
                            timer: 1500,
                        });
                    });
            }
        }
    }

    return (
        <>
            <ModalDialog
                close={props.close}
                show={props.show}
                title={props.isEdit ? "Edit Process Flow" : "Add Process Flow"}
                okAction={() => saveGroup()}
                size="md"
                okText="Save Process Flow"
            >
                {roleGroupQuery.isLoading ? (
                    <Loader2 />
                ) : (
                    <>
                        <InputControlled label="Process Flow Name" value={name} setValue={(e) => setName(e)} />
                        <TextAreaNeoGenControlled
                            label="Process Flow Description"
                            value={description}
                            setValue={(e) => setDescription(e)}
                        />
                        <div>
                            <label
                                htmlFor="required_roles"
                                className="block text-sm font-normal tracking-wider text-gray-500 dark:text-gray-400"
                            >
                                Role Groups That Use This Process Flow
                            </label>
                            <div className="relative flex  flex-col justify-center ">
                                <div
                                    className={
                                        "columns-4 w-full " +
                                        "[column-fill:_balance] " +
                                        "box-border mr-auto before:box-inherit after:box-inherit mt-5"
                                    }
                                >
                                    {/* <PrintPre>
                                    {roleGroupQuery.data}
                                </PrintPre> */}
                                    {roleGroupQuery.data &&
                                        roleGroupQuery.data.map((roleGroup) => (
                                            <div
                                                key={roleGroup.id}
                                                className="break-inside-avoid  border border-gray-300 p-3 mr-3 mb-3 rounded-lg shadow"
                                            >
                                                {/* {roleGroup.name} */}
                                                <CheckBoxNeoGenControlled
                                                    noSpace={true}
                                                    label={roleGroup.name}
                                                    setValue={(e) => {
                                                        if (e.target.checked) {
                                                            setRoles([...roles, roleGroup.id ?? -1]);
                                                        } else {
                                                            setRoles(roles.filter((id) => id !== roleGroup.id));
                                                        }
                                                    }}
                                                    value={roles.includes(roleGroup.id ?? -1)}
                                                    name={roleGroup.id?.toString() ?? ""}
                                                    id={roleGroup.id?.toString() ?? ""}

                                                    // value={group && group.roleGroups.includes(roleGroup.id)}
                                                />
                                            </div>
                                        ))}
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </ModalDialog>
        </>
    );
}

export type Group = {
    id?: string;
    name: string;
    description: string;
};

type AddEditGroupProps = {
    isEdit: boolean;
    show: boolean;
    close: () => void;
    group?: ProcessFlowGroup | undefined;
    // onSave: (group: Group | null) => void,
};

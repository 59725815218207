import { string } from "prop-types";

export default function Badge(props: BadgeProps) {
    return (
        <div className={"inline " + props.className}>
            {props.color === "grey" && (
                <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800  dark:brightness-50">
                    {props.label}
                </span>
            )}

            {props.color === "red" && (
                <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-100 dark:brightness-50">
                    {props.label}
                </span>
            )}
            {props.color === "yellow" && (
                <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800 dark:brightness-50">
                    {props.label}
                </span>
            )}
            {props.color === "green" && (
                <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800 dark:bg-green-800 dark:text-green-100 dark:brightness-50">
                    {props.label}
                </span>
            )}
            {props.color === "blue" && (
                <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-100 dark:brightness-50">
                    {props.label}
                </span>
            )}
            {props.color === "indigo" && (
                <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800 dark:text-indigo-200 dark:bg-indigo-500">
                    {props.label}
                </span>
            )}
            {props.color === "purple" && (
                <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-purple-100 text-purple-800 dark:brightness-50">
                    {props.label}
                </span>
            )}
            {props.color === "pink" && (
                <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-pink-100 text-pink-800 dark:brightness-50">
                    {props.label}
                </span>
            )}
        </div>
    );
}

export type BadgeColors = "grey" | "red" | "yellow" | "green" | "blue" | "indigo" | "purple" | "pink";

type BadgeProps = {
    color: BadgeColors;
    label: string;
    className?: string;
};

import { ReactNode } from "react";

export const Table = ({ children }: { children: ReactNode }) => {
    return (
        <table className="w-full divide-y divide-gray-300 dark:divide-gray-900" style={{ tableLayout: "fixed" }}>
            {children}
        </table>
    );
};

export const THead = ({ children }: { children: ReactNode }) => {
    return (
        <thead className="bg-gray-50 dark:bg-gray-800 rounded-t-xl shadow-md" style={{ position: "sticky", top: 0 }}>
            {children}
        </thead>
    );
};

export const TBody = ({ children }: { children: ReactNode }) => {
    return (
        <tbody className="rounded-xl divide-y divide-gray-200 bg-white dark:bg-gray-800 dark:divide-gray-900">
            {children}
        </tbody>
    );
};

export const Tr = ({ children }: { children: ReactNode }) => {
    return <tr>{children}</tr>;
};

export const Th = ({ children }: { children: ReactNode }) => {
    return (
        <th
            scope="col"
            className="dark:text-gray-500 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
        >
            {children}
        </th>
    );
};

export const Td = ({ children }: { children: ReactNode }) => {
    return <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">{children}</td>;
};

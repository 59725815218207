import "./fake-wizard.css";

export default function FakeWizard() {
    return (
        <>
            <div className="flex gap-5">
                <div className="bg-white dark:bg-gray-800 rounded-sm sm:rounded-xl xs:block p-5 shadow-xl">
                    <div className="   w-full pr-5 my-1 top-0  left-0   self-start">
                        <div>
                            <div className="mb-5">
                                <label
                                    htmlFor="email"
                                    className="block text-sm font-medium text-gray-500 dark:text-gray-400 skeleton-box   w-32 h-5"
                                ></label>
                                <div className="mt-1 relative rounded-md ">
                                    <input
                                        type="text"
                                        className="skeleton-box undefined shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md dark:bg-gray-900  dark:border-gray-900 dark:text-gray-300"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="   w-1/2 inline-block pr-5  my-1 top-0  left-0   self-start">
                        <div>
                            <div className="mb-5">
                                <label
                                    htmlFor="email"
                                    className="block text-sm font-medium text-gray-500 dark:text-gray-400 skeleton-box   w-32 h-5"
                                ></label>
                                <div className="mt-1 relative rounded-md ">
                                    <input
                                        type="text"
                                        className="skeleton-box undefined shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md dark:bg-gray-900  dark:border-gray-900 dark:text-gray-300"
                                    />
                                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-1 "></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="   w-1/2 inline-block pr-5  my-1 top-0  left-0   self-start">
                        <div>
                            <div className="mb-5">
                                <label
                                    htmlFor="email"
                                    className="block text-sm font-medium text-gray-500 dark:text-gray-400 skeleton-box   w-32 h-5"
                                ></label>
                                <div className="mt-1 relative rounded-md ">
                                    <input
                                        type="text"
                                        className="skeleton-box undefined shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md dark:bg-gray-900  dark:border-gray-900 dark:text-gray-300"
                                    />
                                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-1 "></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="   w-1/2 inline-block pr-5  my-1 top-0  left-0   self-start">
                        <div>
                            <div className="mb-5">
                                <label
                                    htmlFor="email"
                                    className="block text-sm font-medium text-gray-500 dark:text-gray-400 skeleton-box   w-32 h-5"
                                ></label>
                                <div className="mt-1 relative rounded-md ">
                                    <input
                                        type="text"
                                        className="skeleton-box undefined shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md dark:bg-gray-900  dark:border-gray-900 dark:text-gray-300"
                                    />
                                    <div className="pointer-eventsskeleton-box -none absolute inset-y-0 right-0 flex items-center pr-1 "></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="   w-1/2 inline-block pr-5  my-1 top-0  left-0   self-start">
                        <div>
                            <div className="mb-5">
                                <label
                                    htmlFor="email"
                                    className="block text-sm font-medium text-gray-500 dark:text-gray-400 skeleton-box   w-32 h-5"
                                ></label>
                                <div className="mt-1 relative rounded-md ">
                                    <input
                                        type="text"
                                        className="skeleton-box undefined shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md dark:bg-gray-900  dark:border-gray-900 dark:text-gray-300"
                                    />
                                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-1 "></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="   w-full pr-5 my-1 top-0  left-0   self-start">
                        <div>
                            <div className="mb-5">
                                <label
                                    htmlFor="email"
                                    className="block text-sm font-medium text-gray-500 dark:text-gray-400 skeleton-box   w-64 h-5"
                                >
                                    {" "}
                                </label>
                                <div className="mt-1 relative rounded-md ">
                                    <input
                                        type="text"
                                        className="skeleton-box undefined shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md dark:bg-gray-900  dark:border-gray-900 dark:text-gray-300"
                                    />
                                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-1 "></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="   w-full pr-5 my-1 top-0  left-0   self-start">
                        <div>
                            <div className="mb-5">
                                <label
                                    htmlFor="email"
                                    className="block text-sm font-medium text-gray-500 dark:text-gray-400 skeleton-box   w-64 h-5"
                                ></label>
                                <div className="mt-1 relative rounded-md ">
                                    <input
                                        type="text"
                                        className="skeleton-box undefined shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md dark:bg-gray-900  dark:border-gray-900 dark:text-gray-300"
                                    />
                                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-1 "></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=" 300 mb-5">
                        <div className="ql-snow ql-read-only ">
                            <p>
                                <div style={{ height: "321px" }} className="skeleton-box"></div>
                                <span className="skeleton-box"> </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

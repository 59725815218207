import { useQuery } from "@tanstack/react-query";
import helpDocsService from "../../services/help-docs.service";
import Main from "../../layout/Main";
import PrintPre from "../../layout/print-pre";
import Title from "../../layout/Title";
import Loader2 from "../utilities/Loader2";
import { Editor } from "react-draft-wysiwyg";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
// import {  } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useEffect, useState } from "react";
import { convertToHTML } from "draft-convert";
import DOMPurify from "dompurify";
import authService from "../../services/auth.service";
import AddFaq from "./modals/add-faq";
import { NeoGenHelpDocs } from "../../typings/api";
import { Breadcrumb, ModalBody } from "react-bootstrap";
// import "./faq.css";
import DeleteFaq from "./modals/delete-faq";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PageDescription from "../../layout/page-description";
import RecordingButton from "../../layout/recording-button";
import ButtonNeoGen from "../../layout/button";
// import { faCircleO } from "@fortawesome/pro-light-svg-icons";

export default function Faq() {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [convertedContent, setConvertedContent] = useState(null);
    const [canAddFaqs, setCanAddFaqs] = useState(false);
    const [showAddFaq, setShowAddFaq] = useState(false);
    const [searched, setSearched] = useState(false);
    const [searching, setSearching] = useState(false);
    const [showDeleteFaq, setShowDeleteFaq] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [showAll, setShowAll] = useState(false);
    const [searchResults, setSearchResults] = useState<NeoGenHelpDocs[]>([]);
    const [existing, setExisting] = useState<null | NeoGenHelpDocs>(null);
    const location: { state: unknown } = useLocation();
    const { term } = useParams();
    const navigate = useNavigate();
    // console.log(location);
    const helpDocsQuery = useQuery(["HelpDocs"], async () => {
        const response = await helpDocsService.getAll();
        console.log(response);
        // alert("!")
        if (response) {
            return response.data;
        }
    });
    useEffect(() => {
        authService.canIAccess("ADD_FAQ").then((r) => {
            setCanAddFaqs(r);
        });
    }, []);
    const convertContentToHTML = () => {
        const currentContentAsHTML = convertToHTML({
            styleToHTML: (style: any) => {
                console.log(style);
                if (style === "BOLD") {
                    return <span style={{ fontWeight: "bold" }} />;
                }
                if (style.substring(0, 9) === "fontsize-") {
                    return <span style={{ fontSize: style.substring(9) + "px" }} />;
                }
            },
            blockToHTML: (block: any) => {
                console.log(block);
                if (block.type === "PARAGRAPH") {
                    return <p />;
                } else if (block.type === "unstyled") {
                    return <div style={{ minHeight: "2rem" }} />;
                }
            },
            entityToHTML: (entity: any, originalText: any) => {
                console.log(entity, originalText);
                if (entity.type === "LINK") {
                    return <a href={entity.data.url}>{originalText}</a>;
                }
                return originalText;
            },
        })(editorState.getCurrentContent(), { flat: true });
        // console.log(convertToRaw(editorState.getCurrentContent()));
        setConvertedContent(currentContentAsHTML);
    };

    // const createMarkup = (html: any) => {
    //     console.log(DOMPurify.sanitize(html));
    //     return {
    //         __html: DOMPurify.sanitize(html)
    //     };
    // };
    // const onEditorStateChange = (editorState: any) => {
    //     console.log(editorState);
    //     setEditorState(editorState);
    //     convertContentToHTML();
    // };

    useEffect(() => {
        if (term) {
            search(term);
        }
    }, [term]);

    function getMarkupFromRaw(h: NeoGenHelpDocs) {
        const currentContentAsHTML = convertToHTML({
            styleToHTML: (style: any) => {
                console.log(style);
                if (style === "BOLD") {
                    return <span style={{ fontWeight: "bold" }} />;
                }
                if (style.substring(0, 9) === "fontsize-") {
                    return <span style={{ fontSize: style.substring(9) + "px" }} />;
                }
            },
            blockToHTML: (block: any) => {
                console.log(block);
                if (block.type === "PARAGRAPH") {
                    return <p />;
                } else if (block.type === "unstyled") {
                    return <div style={{ minHeight: "2rem" }} />;
                }
            },
            entityToHTML: (entity: any, originalText: any) => {
                console.log(entity, originalText);
                if (entity.type === "LINK") {
                    return <a href={entity.data.url}>{originalText}</a>;
                }
                return originalText;
            },
        })(convertFromRaw(JSON.parse(h.body)));
        console.info(currentContentAsHTML);
        console.info(convertFromRaw(JSON.parse(h.body)));
        return currentContentAsHTML;
    }

    function search(subject: string) {
        setSearching(true);
        helpDocsService.search(subject).then((r) => {
            console.info(r);
            if (r) {
                setSearching(false);
                setSearched(true);
                setSearchResults(r.data);
            }
        });
    }
    useEffect(() => {
        if ((location?.state as string)?.toString()?.length > 0) {
            // console.log((location?.state as string).toString());
            search(location?.state as string);
            // location.state = "";
        }
    }, [location, location.state]);
    function loadExact(id: number) {
        helpDocsService.getOne(id).then((r) => {
            console.info(r);
            if (r) {
                setSearching(false);
                setSearched(true);
                setSearchResults([r.data]);
            }
        });
    }

    const handleKeyDown = (event: { key: string }) => {
        if (event.key === "Enter") {
            search(searchTerm);
        }
    };

    return (
        <div>
            <div>
                <div>
                    <div className="grid grid-cols-12 gap-5">
                        {!helpDocsQuery.isSuccess ? (
                            <Loader2 />
                        ) : (
                            <>
                                {searching ? (
                                    <>Searching...</>
                                ) : (
                                    <>
                                        {searched && searchResults.length === 0 ? (
                                            <div className="p-0 col-span-12 text-center w-full">
                                                <PageDescription>
                                                    <p className="text-xl font-bold text-gray-600 sm:text-2xl dark:text-gray-400 mb-3">
                                                        No Results Found
                                                    </p>
                                                    <p className="mb-5">
                                                        Sorry, we couldn&apos;t find anything for that search
                                                    </p>
                                                </PageDescription>
                                            </div>
                                        ) : (
                                            <div className="p-0 col-span-12 text-center w-full">
                                                <PageDescription>
                                                    <p className="text-xl font-bold text-gray-600 sm:text-2xl dark:text-gray-400 mb-3">
                                                        Frequently Asked Questions
                                                    </p>
                                                    <p className="mb-5">
                                                        Use the search bar above to search for help on something.{" "}
                                                    </p>
                                                    <p className="mb-5">You can use normal English sentences</p>
                                                </PageDescription>
                                            </div>
                                        )}
                                        {canAddFaqs && (
                                            // <div className="mb-4">
                                            <div className="col-span-12 -mt-5">
                                                <>
                                                    <button
                                                        type="button"
                                                        className="bg-indigo-600 hover:bg-indigo-700 text-white py-2 px-4 rounded-lg"
                                                        onClick={() => {
                                                            navigate("/faq/new");
                                                        }}
                                                    >
                                                        Add FAQ
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="ml-3 bg-transparent hover:bg-blue-500 text-blue-500  hover:text-white py-2 px-4 border border-blue-300 hover:border-transparent rounded-lg"
                                                        onClick={() => {
                                                            setShowAll((c) => !c);
                                                        }}
                                                    >
                                                        <i
                                                            className={
                                                                "fa mr-3 " +
                                                                (showAll
                                                                    ? "text-green-500 fa-check"
                                                                    : "text-red-500 fa-times")
                                                            }
                                                        ></i>
                                                        Toggle Show All
                                                    </button>
                                                </>
                                            </div>
                                            // </div>
                                        )}
                                        {searchResults.length === 0 && canAddFaqs && !showAll ? (
                                            <>
                                                {/* <div className="p-5 text-center" style={{width: "100%"}}> */}
                                                {/* <div className="grid grid-cols-12 gap-2"> */}
                                                <div className="col-span-4 p-0">
                                                    <RecordingButton
                                                        title="Buy A Phone Number"
                                                        action={() => loadExact(4)}
                                                        icon="fa-thin fa-phone-office fa-2x  wizard-icon"
                                                    />
                                                </div>

                                                <div className="col-span-4 p-0">
                                                    <RecordingButton
                                                        title="Topping Up Your Account"
                                                        action={() => loadExact(6)}
                                                        icon="fa-thin fa-credit-card fa-2x  wizard-icon"
                                                    />
                                                </div>
                                                <div className="col-span-4 p-0">
                                                    <RecordingButton
                                                        title="Using the virtual agent wizard"
                                                        action={() => loadExact(5)}
                                                        icon="fa-thin fa-hat-wizard fa-2x  wizard-icon"
                                                    />
                                                </div>
                                                {/* </div> */}
                                                {/* </div> */}
                                            </>
                                        ) : (
                                            <div className="col-span-12">
                                                <ButtonNeoGen
                                                    onClick={() => {
                                                        setSearched(false);
                                                        setSearchResults([]);
                                                    }}
                                                    text="Go Back"
                                                />
                                            </div>
                                        )}
                                    </>
                                )}

                                {(showAll ? helpDocsQuery.data : searchResults)?.map((h: NeoGenHelpDocs) => {
                                    return (
                                        <div key={h?.id} className="col-span-12">
                                            <div className="border-gray-600 bg-white p-4 rounded-lg">
                                                <div className="">
                                                    <div className="card-title mb-0">{h?.title}</div>
                                                    {canAddFaqs && (
                                                        <>
                                                            <button
                                                                className="bg-transparent hover:bg-red-500 text-red-500  hover:text-white py-2 px-4 border border-red-300 hover:border-transparent rounded-lg float-right "
                                                                onClick={() => {
                                                                    setExisting(h);
                                                                    setShowDeleteFaq(true);
                                                                }}
                                                            >
                                                                <i className="fal fa-trash mr-2"></i>
                                                                Delete FAQ
                                                            </button>
                                                            <button
                                                                className="bg-transparent hover:bg-indigo-500 text-indigo-500  hover:text-white py-2 px-4 border border-indigo-300 hover:border-transparent rounded-lg mr-3 float-right "
                                                                onClick={() => {
                                                                    setExisting(h);
                                                                    setShowAddFaq(true);
                                                                    navigate("/faq/edit/" + h.id);
                                                                }}
                                                            >
                                                                <i className="fal fa-pencil mr-2"></i>
                                                                Edit FAQ
                                                            </button>
                                                        </>
                                                    )}
                                                    <br />
                                                    {h?.tags?.split(",").map((tag: string, idx: number) => {
                                                        return (
                                                            <span key={idx} className="badge badge-secondary mr-1 mb-1">
                                                                {tag.trim()}
                                                            </span>
                                                        );
                                                    })}
                                                    {canAddFaqs && h.active ? (
                                                        <span className="badge badge-success mr-1 mb-1">Active</span>
                                                    ) : (
                                                        <span className="badge badge-danger mr-1 mb-1">Inactive</span>
                                                    )}
                                                    {canAddFaqs && h.public ? (
                                                        <span className="badge badge-info mr-1 mb-1">Public</span>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div>
                                                <div className=" border-gray-500">
                                                    <Editor
                                                        toolbarHidden
                                                        readOnly
                                                        editorState={EditorState.createWithContent(
                                                            convertFromRaw(JSON.parse(h?.body)),
                                                        )}
                                                        toolbarClassName="toolbarClassName"
                                                        wrapperClassName="wrapperClassName"
                                                        editorClassName="editorClassName"
                                                        onEditorStateChange={() => {
                                                            //
                                                        }}
                                                    />
                                                    {/* <div className="preview" dangerouslySetInnerHTML={createMarkup(getMarkupFromRaw(h))}></div> */}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </>
                        )}
                    </div>
                </div>
            </div>

            {/* <AddFaq show={showAddFaq} hide={() => setShowAddFaq(false)} existingEntry={existing}/> */}
            <DeleteFaq show={showDeleteFaq} hide={() => setShowDeleteFaq(false)} existingEntry={existing} />
        </div>
    );
}

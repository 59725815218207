import { ReactNode } from "react";
import SelectT from "react-tailwindcss-select";
import { SelectValue } from "react-tailwindcss-select/dist/components/type";
import { Label } from "../label";

export type Option = {
    value: string;
    label: string;
};

export const Select = ({
    value,
    onChange,
    options,
    label,
}: {
    value: string | string[] | null;
    onChange: (value?: string | string[] | undefined | null) => any;
    options: Option[];
    label?: ReactNode;
}) => {
    return (
        <>
            {label && (
                <div style={{ marginBottom: 4 }}>
                    <Label text={label} />
                </div>
            )}
            <SelectT
                classNames={{
                    menuButton: () =>
                        "flex bg-white dark:bg-gray-800 dark:text-gray-400 border border-gray-300 dark:border-gray-900 rounded-md shadow-sm text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm overflow-hidden",
                }}
                isSearchable
                primaryColor="white"
                value={options.find((o) => o.value === value) as SelectValue}
                onChange={(v) => {
                    const value = v ? (Array.isArray(v) ? v.map((s) => s.value || null) : v.value || null) : undefined;
                    onChange(value as any);
                }}
                options={options}
            />
        </>
    );
};

import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router";
import Swal from "sweetalert2";
import defaultUsersService from "../../../services/default-users.service";
import PageDescription from "../../../layout/page-description";
import PopoverComponent from "../../../layout/popover-component";
import TableNeogen from "../../../layout/table-neogen";
import Loader2 from "../../utilities/Loader2";

export default function DefaultUsers() {
    const cache = useQueryClient();
    const navigate = useNavigate();
    const defaultUsersQuery = useQuery(["defaultUsers"], async () => {
        const response = await defaultUsersService.getAll();
        if (response) {
            return response.data;
        }
    });

    return (
        <>
            <PageDescription
                title="Default Users"
                description="Default users are basically user templates for various types of users. You can create a default user for a specific type of user, and then when you create a new user, you can select the default user to use as a template for the new user."
                description2="This will also be used when new users sign up for an account. If you have a default user for a specific type of user, then when a new user signs up, they will be assigned the default user parameters for that type of user."
                buttons={[
                    {
                        label: "Add Default User",
                        icon: "fal fa-layer-plus",
                    },
                    {
                        label: "Test Sign Up With No Source",
                        onClick: () => navigate("/sign-up"),
                        icon: "fal fa-user-plus",
                        bg: "bg-orange-500",
                    },
                ]}
            />

            {defaultUsersQuery.isLoading ? (
                <Loader2 />
            ) : (
                <>
                    <TableNeogen
                        entries={defaultUsersQuery.data ?? []}
                        formatters={[
                            {
                                field: "processFlowGroup",
                                type: "ProcessFlowGroup",
                            },
                            {
                                field: "roleGroup",
                                type: "RoleGroup",
                            },
                            {
                                field: "source",
                                type: "DefaultSource",
                            },
                        ]}
                        actions={[
                            {
                                label: "Edit",
                                onClick: () => console.log("Edit"),
                                icon: "fas fa-edit",
                            },
                            {
                                label: "Test Sign Up",
                                onClick: (id) => navigate("/sign-up/" + id),
                                icon: "fal fa-user-plus",
                                className: "bg-orange-500",
                            },
                            {
                                label: "Delete",
                                className: " bg-red-500 hover:bg-red-800",
                                onClick: (id) => {
                                    Swal.fire({
                                        title: "Are you sure?",
                                        text: "You won't be able to revert this!",
                                        icon: "warning",
                                        showCancelButton: true,
                                        confirmButtonText: "Yes, delete it!",
                                        confirmButtonColor: "#3085d6",
                                        cancelButtonColor: "#d33",
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            defaultUsersService.deleteByID(id).then(() => {
                                                cache.invalidateQueries(["defaultUsers"]);
                                                Swal.fire({
                                                    title: "Deleted!",
                                                    text: "Your default user has been deleted.",
                                                    icon: "success",
                                                    showConfirmButton: false,
                                                    timer: 1500,
                                                });
                                            });
                                        }
                                    });
                                },
                                icon: "fas fa-trash",
                            },
                        ]}
                    />
                </>
            )}
        </>
    );
}

/**
 *
 *
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 8/25/20, 6:24 AM
 *
 */

import { BusinessRuleGroupsPerProduct } from "../typings/business-rule-groups-per-product";
import APIService from "./api.service";

// const debug        = require('debug')('Neogen:CDRService');

class BusinessRuleGroupsPerProductService extends APIService<BusinessRuleGroupsPerProduct> {
    constructor() {
        super("business-rule-groups-per-products");
    }

    // createBusinessRuleGroupsPerProduct(businessRuleGroupsPerProduct: BusinessRuleGroupsPerProduct) {

    // getAllByProductId(productId: number) {
    //     const filter = {

    //     };
    //     return this.getFilteredWhere(filter);
    // }
}

export default new BusinessRuleGroupsPerProductService();

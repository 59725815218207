/* This example requires Tailwind CSS v2.0+ */
// import { CheckIcon } from "@heroicons/react/24/solid";
import { CheckIcon } from "@heroicons/react/24/solid";
import { useEffect, useRef } from "react";

const steps = [
    { id: "01", name: "Job details", href: "#", status: "complete" },
    { id: "02", name: "Application form", href: "#", status: "current" },
    { id: "03", name: "Preview", href: "#", status: "upcoming" },
];

type WizardStepsType = {
    steps: { id: string; name: string; action: () => void; status: "complete" | "current" | "upcoming" }[];
};

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
}

// function scrollTo (el: HTMLElement) {

//     if (!el?.parentNode?.offsetLeft) {
//         return;
//     }
//     const elLeft = el.offsetLeft + el.offsetWidth;
//     const elParentLeft = el.parentNode.offsetLeft + el.parentNode.offsetWidth;

//     // check if element not in view
//     if (elLeft >= elParentLeft + el.parentNode.scrollLeft) {
//         el.parentNode.scrollLeft = elLeft - elParentLeft;
//     } else if (elLeft <= el.parentNode.offsetLeft + el.parentNode.scrollLeft) {
//         el.parentNode.scrollLeft = el.offsetLeft - el.parentNode.offsetLeft;
//     }
// }

export default function WizardSteps({ steps }: WizardStepsType) {
    // const currentStepRef = useRef<HTMLButtonElement|null>(null);

    return (
        <div className="lg:border-t lg:border-b lg:border-gray-200 dark:border-gray-900 rounded-xl">
            {/* <button onClick={() => currentStepRef.current?.scrollIntoView()} >
                a button
            </button> */}
            <nav className="mx-auto " aria-label="Progress">
                <ol
                    role="list"
                    className="overflow-scroll rounded-xl lg:flex  lg:border-l lg:border-r lg:border-gray-200 dark:lg:border-gray-800 "
                >
                    {steps.map((step, stepIdx) => (
                        <li key={step.id} className="relative lg:flex-1 ">
                            <div
                                className={classNames(
                                    stepIdx === 0 ? "border-b-0 rounded-l-md" : "",
                                    stepIdx === steps.length - 1 ? "border-t-0 rounded-r-md" : "",
                                    "border border-gray-200 dark:border-gray-800 overflow-hidden lg:border-0 dark:bg-gray-700 ",
                                )}
                            >
                                {step.status === "complete" ? (
                                    <button type="button" onClick={step.action} className="group w-full mx-auto">
                                        <span
                                            className="absolute top-0 left-0 h-full w-1 bg-transparent group-hover:bg-gray-200  dark:group-hover:bg-gray-700   lg:bottom-0 lg:top-auto lg:h-1 mx-auto"
                                            aria-hidden="true"
                                        />
                                        <span
                                            className={classNames(
                                                stepIdx !== 0 ? "lg:pl-8" : "",
                                                "px-3 py-5 flex items-center text-sm font-medium w-full",
                                            )}
                                        >
                                            <span className="mx-auto">
                                                <span className="flex h-10 w-10 items-center justify-center rounded-full bg-indigo-600 dark:bg-indigo-800">
                                                    <CheckIcon
                                                        className="h-6 w-6 text-white dark:text-gray-300"
                                                        aria-hidden="true"
                                                    />
                                                </span>
                                            </span>
                                            <span className="mt-0.5 ml-2 min-w-0 h-full text-left mx-auto">
                                                <span className="text-sm font-medium dark:text-gray-400 ">
                                                    {step.name}
                                                </span>
                                            </span>
                                        </span>
                                    </button>
                                ) : step.status === "current" ? (
                                    <button
                                        type="button"
                                        onClick={step.action}
                                        aria-current="step"
                                        className="group w-full mx-auto"
                                        // ref={currentStepRef}
                                    >
                                        <span
                                            className="absolute top-0 left-0 h-full w-1 bg-indigo-600 dark:bg-green-500 lg:bottom-0 lg:top-auto lg:h-1 lg:w-full"
                                            aria-hidden="true"
                                        />
                                        <span
                                            className={classNames(
                                                stepIdx !== 0 ? "lg:pl-8" : "",
                                                "px-3 py-5 flex items-center text-sm font-medium w-full",
                                            )}
                                        >
                                            <span className="mx-auto">
                                                <span className="flex h-10 w-10 items-center justify-center rounded-full border-2 border-indigo-600 dark:border-indigo-500">
                                                    <span className="text-indigo-600 dark:text-indigo-200">
                                                        {step.id}
                                                    </span>
                                                </span>
                                            </span>
                                            <span className="mt-0.5 ml-2 min-w-0  h-full text-left mx-auto">
                                                <span className="text-sm font-medium text-indigo-600 dark:text-green-400">
                                                    {step.name}
                                                </span>
                                            </span>
                                        </span>
                                    </button>
                                ) : (
                                    <button type="button" onClick={step.action} className="group w-full mx-auto">
                                        <span
                                            className="absolute top-0 left-0 h-full w-1 bg-transparent group-hover:bg-gray-200  dark:group-hover:bg-gray-700 lg:bottom-0 lg:top-auto lg:h-1 lg:w-full"
                                            aria-hidden="true"
                                        />

                                        <span
                                            className={classNames(
                                                stepIdx !== 0 ? "lg:pl-8" : "",
                                                "px-3 py-5 flex items-center text-sm font-medium w-full",
                                            )}
                                        >
                                            <span className="mx-auto">
                                                <span className="flex h-10 w-10 items-center justify-center rounded-full border-2 border-gray-300 dark:border-gray-600">
                                                    <span className="text-gray-400">{step.id}</span>
                                                </span>
                                            </span>
                                            <span className="mt-0.5 ml-2 min-w-0  h-full text-left mx-auto">
                                                <span className="text-sm font-medium text-gray-400 mx-auto text-left">
                                                    {step.name}
                                                </span>
                                            </span>
                                        </span>
                                    </button>
                                )}

                                {stepIdx !== 0 ? (
                                    <>
                                        {/* Separator */}
                                        <div
                                            className="absolute inset-0 top-0 left-0 hidden w-3 lg:block text-center"
                                            aria-hidden="true"
                                        >
                                            <svg
                                                className="h-full w-full text-gray-300"
                                                viewBox="0 0 12 82"
                                                fill="none"
                                                preserveAspectRatio="none"
                                            >
                                                <path
                                                    d="M0.5 0V31L10.5 41L0.5 51V82"
                                                    stroke="currentcolor"
                                                    vectorEffect="non-scaling-stroke"
                                                />
                                            </svg>
                                        </div>
                                    </>
                                ) : null}
                            </div>
                        </li>
                    ))}
                </ol>
            </nav>
        </div>
    );
}

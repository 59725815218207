import { useQuery } from "@tanstack/react-query";
import { useRecoilState } from "recoil";
import userAtom from "../atoms/userAtom";
import { getEstimatedPayoutFromData } from "../jason-proof-of-concept/other/actions/getEstimatedPayout";
import { getProcessflowProgresses } from "../jason-proof-of-concept/processflow-progresses/actions/get-processflow-progresses";
import { getDataFromProgressData } from "../jason-proof-of-concept/wizard/wizard-container";
import PageDescription from "../layout/page-description";
import { getAuthTokenNoThrow } from "../services/auth-header";
import { BillingSummary } from "./billingSummary";
import { paymentOptionsKey } from "./paymentOptions";

export const BillingPage = () => {
    const [user] = useRecoilState(userAtom);
    const userId = user.id;
    const authToken = getAuthTokenNoThrow() || "no-auth-token";

    const query = useQuery(["billing-page-calcs", userId, authToken], async () => {
        const group = 7;
        const [usersProgress] = await getProcessflowProgresses({
            authToken,
            filters: { userId, group },
        });
        const data = getDataFromProgressData(usersProgress.data as any);
        const estimatedAmount = getEstimatedPayoutFromData({ data });
        const paymentOption = data[paymentOptionsKey];
        return { estimatedAmount, paymentOption };
    });
    const estimatedAmount = query?.data?.estimatedAmount || 0;
    const paymentOption = query?.data?.paymentOption;

    return (
        <>
            <PageDescription title="Billing" description="View and manage your billing details." />
            <div className="rounded-xl bg-slate-50 p-5 shadow border-t-2 border-l-2 border-white dark:bg-gradient-to-br dark:from-gray-900 dark:to-gray-800 dark:border-2 dark:border-gray-700">
                <BillingSummary estimatedAmount={estimatedAmount} paymentOption={paymentOption} />
            </div>
        </>
    );
};

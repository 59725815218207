export function calculate(val1: any, val2: any, operator: string) {
    // Convert val1 and val2 to numbers
    val1 = parseFloat(val1);
    val2 = parseFloat(val2); // Perform the operation

    switch (operator) {
        case "+":
            return val1 + val2;

        case "-":
            return val1 - val2;

        case "*":
            return val1 * val2;

        case "/":
            return val1 / val2;

        default:
            return 0;
    }
}

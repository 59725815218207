import { NavLink } from "react-router-dom";

// type BoxChoicesProps = {
//     data: readonly T[] | undefined,
//     deleteFunction: (entry: T) => void,
// }

export default function BoxChoices(props: BoxChoicesProps) {
    return (
        <div className="grid grid-cols-1 gap-5 sm:grid-cols-4">
            {props.data?.map((group) => (
                <div key={group.id} className="w-full">
                    <div
                        className={
                            "dark:bg-gray-800 dark:border-gray-600 dark:hover:bg-gray-700 relative rounded-lg " +
                            "border border-white bg-white pl-5 pr-6 py-3 shadow-md flex items-center space-x-3" +
                            " hover:border-indigo-200 hover:shadow-sm focus-within:ring-2 focus-within:ring-offset-2 " +
                            "focus-within:ring-indigo-500 bg-gradient-to-br to-indigo-50  from-slate-200 " +
                            "hover:to-indigo-50 hover:from-blue-50 via-gray-100 dark:from-gray-900"
                        }
                    >
                        {/* <button
                        onClick={(e) => {
                            props.deleteFunction(group.id);

                            
                        } }
                        onMouseOver={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                        } }
                        onMouseDown={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                        } }
                        className=" absolute right-2 top-3 z-5 text-right px-1 py-0.5 text-xs border border-red-300 text-red-500 rounded-md hover:text-white hover:bg-red-500">
                        Delete
                    </button> */}

                        <div className="flex-shrink-0">
                            <span className={"fa-2x fal " + props.icon + " text-gray-400 dark:text-gray-400"} />
                        </div>
                        <div className="flex-1 min-w-0">
                            <NavLink to={props.urlBase + group.id} className="focus:outline-none">
                                <span className="absolute inset-0 " aria-hidden="true" />
                                <p className="text-sm font-medium text-gray-900 dark:text-gray-300 truncate">
                                    {group.name}
                                </p>
                                <p className="text-sm text-gray-500 truncate dark:text-gray-400">{group.description}</p>
                            </NavLink>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}
type BoxChoicesProps = {
    deleteFunction: (entryId: string | number) => void;
    data: any[];
    icon: string;
    urlBase: string;
};

import { z } from "zod";
import { makeRequest, parseSchema } from "../../jason-proof-of-concept/shared/utils";
import { magicLinkSchema } from "../domain/magic-link";

const createMagicLinkSchema = magicLinkSchema.extend({ id: z.string().uuid().optional(), code: z.string().optional() });

export type CreateMagicLinkData = z.infer<typeof createMagicLinkSchema>;

export const createMagicLink = async ({ authToken, data }: { authToken: string; data: CreateMagicLinkData }) => {
    const response = await makeRequest({
        method: "post",
        path: "/magic-links",
        authToken,
        data,
    });

    const magicLink = parseSchema(magicLinkSchema, response.data);
    return magicLink;
};

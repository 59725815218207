import { z } from "zod";
import { makeRequest, parseSchema } from "../../shared/utils";
import { userSchema } from "../domain/user";

export const userResponseSchema = z.object({
    id: z.string(),
    company: z.number().optional().nullable(),
    name: z.string().nullable(),
    email: z.string(),
    phoneNumber: z.string().optional().nullable(),
    firstName: z.string().nullish(),
    lastName: z.string().nullish(),
    utm: z.string().optional().nullable(),
    affiliateUserId: z.string().optional().nullable(),
    defaultProcessflowId: z.number().optional().nullable(),
});

const getUsersResponseSchema = z.array(userResponseSchema);

export const getUsers = async ({ authToken }: { authToken: string }) => {
    const response = await makeRequest({
        method: "get",
        path: "/users",
        authToken,
    });

    const userDtos = parseSchema(getUsersResponseSchema, response.data);

    const users = parseSchema(
        z.array(userSchema),
        userDtos.map((dto) => ({
            ...dto,
            company: dto.company || undefined,
            name: dto.name || undefined,
        })),
    );

    return users;
};

/**
 *
 *
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 8/25/20, 6:24 AM
 *
 */

import APIService from "./api.service";
import { ProcessflowRuleGroup } from "../typings/api/processflow-rule-group";

class ProcessflowRuleGroupService extends APIService<ProcessflowRuleGroup> {
    constructor() {
        super("processflow-rule-groups");
    }
}

export default new ProcessflowRuleGroupService();

export const VersionPage = () => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
            }}
        >
            <div>
                <h1>Build info</h1>
                <h3>
                    Environment: <b>{process.env.REACT_APP_ENVIRONMENT}</b>
                </h3>
                <h3>
                    Commit id: <b>{process.env.REACT_APP_COMMIT_ID}</b>
                </h3>
                <h3>
                    Tag: <b>{process.env.REACT_APP_TAG}</b>
                </h3>
                <h3>
                    Build date: <b>{process.env.REACT_APP_BUILD_DATE}</b>
                </h3>
            </div>
        </div>
    );
};

import { ChangeEvent } from "react";
import { Path, useForm, UseFormRegister, SubmitHandler, useFormState } from "react-hook-form";
import { useController } from "react-hook-form";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import PrintPre from "./print-pre";
import PopoverComponent from "./popover-component";
interface IFormValues {
    "First Name": string;
    Age: number;
}

type InputProps = {
    label: Path<any>;
    register: UseFormRegister<any>;
    required?: boolean;
    defaultValue?: string;
    name: string;
    onChange?: any;
    fieldState?: any;
    rules?: any;
    errors?: any;
    type?: string;
    className?: string;
    helpText?: string;
    helpDocument?: string | undefined;
};

// const InputNeoGen = ({ label, register, required, defaultValue,name }: InputProps) => (
//     <>
//         <div className="mb-5">
//             <label htmlFor="email" className="block text-sm font-medium text-gray-700">
//                 {label}
//             </label>
//             <div className="mt-1">
//                 <input
//                     type="text"
//                     id="email"
//                     className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
//                     {...register(name, { required })}
//                     defaultValue={defaultValue}
//                 />
//             </div>geronimo gedeci
//         </div>
//     </>
// );
//const InputNeoGen = ({ label, register, required, fieldState, ...rest }: InputProps) => (
export default function InputNeoGen({
    helpDocument,
    defaultValue,
    type,
    className,
    name,
    label,
    register,
    required,
    rules,
    errors,
    helpText,
    ...rest
}: InputProps) {
    return (
        <>
            <div>
                {/* <input {...field} placeholder={props.name} /> */}
                <div className="mb-5">
                    <label
                        htmlFor="email"
                        className="block text-sm font-normal tracking-wider text-gray-500 dark:text-gray-400"
                    >
                        {label}
                        {helpDocument && (
                            <>
                                <PopoverComponent document={helpDocument} />
                            </>
                        )}
                    </label>
                    {/* <PrintPre>{errors?true:false}</PrintPre> */}
                    {/* <PrintPre>{rest}</PrintPre> */}
                    <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                            type={type ?? "text"}
                            {...register(name, { required: rules?.required ?? false })}
                            {...rest}
                            defaultValue={defaultValue}
                            className={
                                className +
                                " " +
                                ((errors ? true : false)
                                    ? "block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                                    : "shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md dark:bg-gray-800  dark:border-gray-900 dark:text-gray-300")
                            }
                        />
                        {(errors ? true : false) && (
                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                            </div>
                        )}
                    </div>
                    {helpText && (
                        <p className="mt-2 text-sm text-gray-500" id="email-description">
                            {helpText}
                        </p>
                    )}
                </div>
                {/* <p>{fieldState.isTouched && "Touched"}</p>
            <p>{fieldState.isDirty && "Dirty"}</p>
            <p>{fieldState.invalid ? "invalid" : "valid"}</p> */}
            </div>
        </>
    );
}

// export default function InputNeoGen(props:any) {
//     const { field, fieldState } = useController(props);

//     return (
// <div>
//     {/* <input {...field} placeholder={props.name} /> */}
//     <div className="mb-5">
//         <label htmlFor="email" className="block text-sm font-medium text-gray-700">
//             {props.label}
//         </label>
//         <div className="mt-1 relative rounded-md shadow-sm">
//             <input
//                 type="text"
//                 id="email"
//                 className={fieldState.invalid?"block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md":"shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"}
//                 {...field}
//                 onChange={(e) => {
//                     if (props.onChange) {
//                         props.onChange(e);
//                     }

//                 }}
//             />
//             {fieldState.invalid && (
//                 <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
//                     <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
//                 </div>
//             )}
//         </div>
//     </div>
//     {/* <p>{fieldState.isTouched && "Touched"}</p>
//     <p>{fieldState.isDirty && "Dirty"}</p>
//     <p>{fieldState.invalid ? "invalid" : "valid"}</p> */}
// </div>
//     );
// }

// InputNeoGen.displayName = "InputNeoGen";

// export default InputNeoGen;

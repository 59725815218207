/**
 *
 *
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 9/23/20, 7:36 PM
 *
 */

import { AxiosResponse } from "axios";
import { Company } from "../typings/api";
import APIService from "./api.service";
import authService from "./auth.service";
import AuthService from "./auth.service";

class CompanyService extends APIService<Company> {
    constructor() {
        super("companies");
    }
    async getAll(): Promise<void | AxiosResponse<Company[]>> {
        // alert(1);
        if (await AuthService.canIAccess("LIST_ALL_COMPANIES")) {
            return super.getAll();
        } else {
            // if the user doesn't have access to all phone numbers they should see a subset
            const me = authService.getCurrentUser();
            if (me?.company > 0) {
                // alert("mc " + me?.company);
                return super.getURL(`my-company`);
            } else {
                // const response: AxiosResponse = {
                //     // `data` is the response that was provided by the server
                //     data: [],

                //     // `status` is the HTTP status code from the server response
                //     status: 200,

                //     // `statusText` is the HTTP status message from the server response
                //     // As of HTTP/2 status text is blank or unsupported.
                //     // (HTTP/2 RFC: https://www.rfc-editor.org/rfc/rfc7540#section-8.1.2.4)
                //     statusText: "OK",

                //     // `headers` the HTTP headers that the server responded with
                //     // All header names are lower cased and can be accessed using the bracket notation.
                //     // Example: `response.headers['content-type']`
                //     headers: {},

                //     // `config` is the config that was provided to `axios` for the request
                //     config: {},

                //     // `request` is the request that generated this response
                //     // It is the last ClientRequest instance in node.js (in redirects)
                //     // and an XMLHttpRequest instance in the browser
                //     request: {},
                // };
                return {} as AxiosResponse<Company[]>;
            }
            // alert("Can access my company");
            // return super.getURL("my-company");

            // return [];
        }
    }
}

export default new CompanyService();

import { HomeIcon } from "@heroicons/react/24/outline";
import { ChevronRightIcon } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";

export default function BreadCrumbs(props: BreadCrumbProps) {
    return (
        <nav
            className={"flex ml-0 cursor-pointer border-gray-200 border-b pb-4 " + (props.addMb ? "mb-5" : "my-0")}
            aria-label="Breadcrumb"
        >
            <ol role="list" className="px-1 xs:px-6 flex w-full ">
                {!props.hideHome && (
                    <li className="flex">
                        <div className="flex items-center">
                            <Link to="/dashboard" className="text-gray-200 hover:text-gray-500 dark:text-gray-400">
                                <HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
                                <span className="sr-only">Home</span>
                            </Link>
                        </div>
                    </li>
                )}
                {props.pages.map((page, index) => (
                    <li key={page.name} className="flex " onClick={() => (page.onClick ? page.onClick() : undefined)}>
                        <div className="flex items-center">
                            {index !== 0 && (
                                <div className="mx-4">
                                    <ChevronRightIcon width={14} height={14} />
                                </div>
                            )}
                            {page.href ? (
                                <Link
                                    to={page.href}
                                    className={
                                        "text-sm " +
                                        (page.current
                                            ? "font-semibold  text-indigo-700 dark:text-indigo-700 "
                                            : "font-medium text-gray-900 ") +
                                        "  hover:text-indigo-500 dark:hover:text-indigo-500  dark:text-gray-400"
                                    }
                                    aria-current={page.current ? "page" : undefined}
                                >
                                    {page.name}
                                </Link>
                            ) : (
                                <h2
                                    className={
                                        "ml-0 md:ml-4 text-[10px] xs:text-xs " +
                                        (page.current
                                            ? "font-semibold  text-indigo-700 dark:text-indigo-700 "
                                            : "font-medium text-gray-900 ") +
                                        "  hover:text-indigo-500 dark:hover:text-indigo-500  dark:text-gray-400"
                                    }
                                >
                                    {page.name}
                                </h2>
                            )}
                        </div>
                    </li>
                ))}
            </ol>
        </nav>
    );
}

export type Page = {
    name: string;
    href?: string;
    onClick?: () => void;
    current?: boolean;
};

type BreadCrumbProps = {
    pages: Page[];
    addMb?: boolean;
    hideHome?: boolean;
};

import { useState } from "react";
import InputControlled from "./input-controlled";
import ModalDialog from "./modal-dialog";

export default function ModalSingleText(props: ModalSingleTextProps) {
    const [field, setField] = useState<string>("");
    function saveRecord() {
        props.save(field);
    }
    return (
        <>
            <ModalDialog title={props.title} close={props.close} show={props.show} okAction={saveRecord}>
                <InputControlled
                    label={props.label}
                    name={props.name}
                    value={field}
                    setValue={(value: string) => setField(value)}
                />
            </ModalDialog>
        </>
    );
}

type ModalSingleTextProps = {
    title: string;
    show: boolean;
    close: () => void;
    label: string;
    name: string;
    save: (value: string) => void;
};

import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import userAtom from "../../atoms/userAtom";
import PrintPre from "../../layout/print-pre";
import { ClearERCUser } from "../../typings/api/clear-erc-user";
import { useGetMap } from "../processflows/components/get-map";
// import useCurrentProgress, { getMap } from "../processflows/hooks/use-current-progress";

export default function MapDemo() {
    const getMap = useGetMap();
    const [user, setUser] = useRecoilState<ClearERCUser>(userAtom);
    const [displau, setDisplay] = useState<any[]>([]);
    // const {getMap} = useCurrentProgress(Number(7), false);
    useEffect(() => {
        getMap(user.id ?? "", (7).toString()).then((map: any) => {
            console.error({ map });
            if (map) {
                setDisplay(map);
            }
        });
    }, [getMap, user.id]);
    return (
        <>
            <PrintPre>{displau}</PrintPre>
        </>
    );
}

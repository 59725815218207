import { useState } from "react";
import { Currency } from "./components";
import { classNames } from "./utils";

export const paymentOptionsKey = "payment_options";

export const getTiers = ({ estimatedAmount }: { estimatedAmount: number }) => {
    const tiers = [
        {
            name: "15% flat fee on CPI filing",
            id: "15_percent_flat_fee",
            href: "#",
            price: { deposit: estimatedAmount * 0.15, balance: 0, total: estimatedAmount * 0.15 },
            description: "Pay a 15% fee when the CPA files your 941X",
            mostPopular: false,
        },
        {
            name: "20% fee with 5% deposit",
            id: "20_percent_5_percent_deposit",
            href: "#",
            price: { deposit: estimatedAmount * 0.05, balance: estimatedAmount * 0.15, total: estimatedAmount * 0.2 },
            description: "Pay a 5% deposit immediately, with the 15% balance due once checks are received by the IRS",
            mostPopular: true,
        },
        {
            name: "25% flat fee",
            id: "25_percent_flat_fee",
            href: "#",
            price: { deposit: 0, balance: estimatedAmount * 0.25, total: estimatedAmount * 0.25 },
            description: "Pay 25% once checks are received by the IRS",
            mostPopular: false,
        },
    ];
    return tiers;
};

export const PaymentOptions = ({
    defaultValue,
    estimatedAmount,
    onChange,
}: {
    defaultValue?: string;
    estimatedAmount: number;
    onChange: (key: string) => any;
}) => {
    const [selectedFeeOption, setFeeOption] = useState(defaultValue || "");

    const handleChange = (id: string) => {
        setFeeOption(id);
        onChange(id);
    };

    const tiers = getTiers({ estimatedAmount });

    return (
        <div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "flex-end",
                }}
                className="pb-2"
            >
                <div>
                    <h2 style={{ fontSize: 22 }}>Choose how you would like to pay</h2>
                    <span className="text-gray-400">Choose from one of our flexible payment options.</span>
                </div>
                <div>
                    Your expected payout from the IRS:{" "}
                    <b>
                        <Currency amount={estimatedAmount} />
                    </b>
                </div>
            </div>
            <div className="isolate mx-auto mt-6 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                {tiers.map((tier) => {
                    const isSelected = selectedFeeOption === tier.id;
                    return (
                        <div
                            key={tier.id}
                            className={classNames(
                                isSelected ? "ring-2 ring-indigo-600" : "ring-1 ring-gray-200",
                                "rounded-2xl p-6 xl:p-8 relative bg-gray-50",
                            )}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                handleChange(tier.id);
                            }}
                        >
                            {tier.mostPopular && (
                                <p className="absolute right-4 top-0 -translate-y-1/2 transform rounded-full bg-indigo-600 py-1.5 px-4 text-sm font-semibold text-white">
                                    Most popular
                                </p>
                            )}
                            <div className="flex items-center gap-x-4">
                                <h3
                                    id={tier.id}
                                    className={classNames(
                                        isSelected ? "text-indigo-600" : "text-gray-900",
                                        "text-xl font-semibold leading-8 text-center flex-1",
                                    )}
                                >
                                    {tier.name}
                                </h3>
                            </div>
                            <p className="mt-1 text-sm leading-6 text-gray-500 text-center h-16">{tier.description}</p>
                            <p className="mt-12 text-md leading-6 text-gray-600 text-center">
                                Deposit due on CPA filing
                            </p>
                            <p className="text-lg leading-6 text-gray-900 text-center">
                                <b>
                                    <Currency amount={tier.price.deposit} />
                                </b>
                            </p>
                            <p className="mt-4 text-md leading-6 text-gray-600 text-center">
                                Balance due on IRS completion
                            </p>
                            <p className="text-lg leading-6 text-gray-900 text-center">
                                <b>
                                    <Currency amount={tier.price.balance} />
                                </b>
                            </p>
                            <hr className="mt-6" />
                            <p className="mt-6 text-lg text-center text-gray-900">Total fee</p>
                            <p className="text-3xl text-center text-gray-900">
                                <b>
                                    <Currency amount={tier.price.total} />
                                </b>
                            </p>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

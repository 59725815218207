/**
 *
 *
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 8/25/20, 6:24 AM
 *
 */

import { ComparisonType } from "../typings/api/comparison-type";
import APIService from "./api.service";

// const debug        = require('debug')('Neogen:CDRService');

class ComparisonTypeService extends APIService<ComparisonType> {
    constructor() {
        super("comparison-types");
    }

    // createBusinessRuleGroupsPerProduct(businessRuleGroupsPerProduct: BusinessRuleGroupsPerProduct) {

    // getAllByProductId(productId: number) {
    //     const filter = {

    //     };
    //     return this.getFilteredWhere(filter);
    // }
}

export default new ComparisonTypeService();

import { DefaultUsers } from "../typings/api/default-users";
import APIService from "./api.service";

class DefaultUserService extends APIService<DefaultUsers> {
    constructor() {
        super("default-users");
    }

    // Either returns the first user with the correct source or if no source, the first user
    async getDefaultUser(source?: number): Promise<DefaultUsers | void> {
        console.log("getDefaultUser");
        const response = await this.getAll();
        if ((response?.data ?? []).length === 0) {
            throw new Error("No default users found");
        }
    }
}

export default new DefaultUserService();

import InputNeoGen from "../../../layout/input-neogen";
import SelectNeoGen from "../../../layout/select-neogen";

export function AddClientSection(props: AddClientSectionProps) {
    return (
        <div className="p-8 grid grid-cols-2 gap-5">
            {/* <PrintPre>
               {usersQuery.data}
            </PrintPre> */}
            <InputNeoGen label={"Business Name"} register={props.register} name={"name"} />
            <InputNeoGen label={"Email Address"} register={props.register} name={"email"} />
            <InputNeoGen label={"Phone Number"} register={props.register} name={"phone"} />
            <InputNeoGen label={"Payroll Contact Name"} register={props.register} name={"payrollContactName"} />
            <InputNeoGen label={"Payroll Contact Phone"} register={props.register} name={"payrollContactPhone"} />
            <InputNeoGen label={"Payroll Contact Email"} register={props.register} name={"payrollContactEmail"} />
            <SelectNeoGen
                label={"Account Manager"}
                value={props.accountManager}
                options={props.usersQuery.data ?? []}
                onChange={(entry) => props.setAccountManager(entry.toString())}
            />
            <SelectNeoGen
                label={"Sales User"}
                value={props.salesUser}
                options={props.usersQuery.data ?? []}
                onChange={(entry) => {
                    console.error({
                        entry,
                    });
                    props.setSalesUser(entry.toString());
                }}
            />
            <SelectNeoGen
                label={"Company Created By"}
                value={props.companyCreatedBy}
                options={props.data ?? []}
                onChange={(entry) => props.setCompanyCreatedBy(Number(entry))}
            />
        </div>
    );
}

type AddClientSectionProps = {
    register: any;
    usersQuery: any;
    data: any;
    accountManager: string;
    setAccountManager: (accountManager: string) => void;
    salesUser: string;
    setSalesUser: (salesUser: string) => void;
    companyCreatedBy: number;
    setCompanyCreatedBy: (companyCreatedBy: number) => void;
};

import { makeRequest, parseSchema } from "../../jason-proof-of-concept/shared/utils";
import { magicLinkSchema } from "../domain/magic-link";

export const getMagicLinkByCode = async ({ authToken, code }: { authToken: string; code: string }) => {
    const response = await makeRequest({
        method: "get",
        path: `/magic-links/${code}`,
        authToken,
    });
    const magicLink = parseSchema(magicLinkSchema, response.data);
    return magicLink;
};

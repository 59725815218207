/**
 *
 *
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 9/19/20, 10:58 AM
 *
 */

import { AxiosResponse } from "axios";
import { UTMLink } from "../typings/api/utm-links";
import { UTMLog } from "../typings/api/utm-logs";
import APIService from "./api.service";
import AuthService from "./auth.service";

class UTMLogService extends APIService<UTMLog> {
    constructor() {
        super("utm-logs");
    }
    async getAll(): Promise<void | AxiosResponse<UTMLog[]>> {
        if (await AuthService.canIAccess("ADMIN_DASHBOARD")) {
            //
            return super.getAll();
        } else {
            // if the user doesn't have access to all phone numbers they should see a subset
            // alert("sub");
            return super.getURL("my-utm-logs");

            // return [];
        }
    }
    async getLastSevenDays(): Promise<void | AxiosResponse<UTMLog[]>> {
        if (await AuthService.canIAccess("ADMIN_DASHBOARD")) {
            const thirtyDays = 14 * 24 * 60 * 60 * 1000;

            return super.getFilteredWhere({
                eventDatetime: {
                    gt: new Date(Date.now() - thirtyDays).toISOString(),
                },
            });
            // return super.getAll();
        } else {
            // if the user doesn't have access to all phone numbers they should see a subset
            // alert("sub");
            return super.getURL("my-utm-logs");
            // const thirtyDays = 7 * 24 * 60 * 60 * 1000;

            // return super.getFilteredWhere({
            //     eventDatetime: {
            //         gt: new Date(Date.now() - thirtyDays).toISOString(),
            //     },
            // });

            // return [];
        }
    }
}

export default new UTMLogService();

import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { Stage } from "../../jason-proof-of-concept/components/stages";
import { Wizard } from "../../jason-proof-of-concept/wizard/wizard";
import PageDescription from "../../layout/page-description";
import PrintPre from "../../layout/print-pre";
import processflowProgressService from "../../services/processflow-progress.service";
import processflowStageService from "../../services/processflow-stage.service";
import processflowService from "../../services/processflow.service";
import usersService from "../../services/users.service";
import { ProcessFlowProgressData } from "../../typings/api/processflow-progress-data";
import Loader2 from "../utilities/Loader2";

import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import ButtonNeoGen from "../../layout/button";

const getDataFromProgressData = (data: ProcessFlowProgressData) => {
    const entryData = data?.entriesByField || {};
    return Object.keys(entryData).reduce((acc: any, key: any) => {
        return { ...acc, [key]: entryData[key]?.fieldValue };
    }, {});
};
export default function CustomerSummary({ withoutHeader }: { withoutHeader?: boolean }) {
    const { id } = useParams();
    const processflowStagesQuery = useQuery(["processflowStages", 7], async () => {
        const response = await processflowStageService.getAllByGroupIdIncludingRuleGroups(7);
        if (response) {
            return response.data;
        }
    });
    const processflowStagesQuery9 = useQuery(["processflowStages", 9], async () => {
        const response = await processflowStageService.getAllByGroupIdIncludingRuleGroups(9);
        if (response) {
            return response.data;
        }
    });
    const processflowStepsQuery = useQuery(["processflowSteps", 25], async () => {
        const response = await processflowService.getAllByOrderForStageAndGroup(25, 7);
        if (response) {
            return response.data;
        }
    });
    const processflowStepsQuery9 = useQuery(["processflowSteps", 33], async () => {
        const response = await processflowService.getAllByOrderForStageAndGroup(33, 9);
        if (response) {
            return response.data;
        }
    });
    // const usersQuery = useQuery(["users"], async () => {
    //     const response = await usersService.getAll();
    //     if (response) {
    //         return response.data;
    //     }
    // });
    const usersQuery = useQuery(["users"], async () => {
        const response = await usersService.getAll();
        if (response) {
            return response.data;
        }
    });

    const processflowProgressQuery = useQuery(["processflowProgress", id, 7], async () => {
        const response = await processflowProgressService.getProgress(id ?? "", 7);
        if (response) {
            return response.data;
        }
    });
    const processflowProgressQuery9 = useQuery(["processflowProgress", id, 9], async () => {
        const response = await processflowProgressService.getProgress(id ?? "", 9);
        if (response) {
            return response.data;
        }
    });

    // if (processflowStagesQuery.isLoading || processflowStepsQuery.isLoading || processflowProgressQuery.isLoading) {
    if (
        usersQuery.isLoading ||
        processflowStagesQuery.isLoading ||
        processflowStepsQuery.isLoading ||
        processflowProgressQuery.isLoading
    ) {
        return <Loader2 />;
    }
    /*
    entriesByField: Object.keys(newData).reduce((acc, curr) => {
                    return {
                        ...acc,
                        [curr]: {
                            fieldName: curr,
                            fieldValue: newData[curr],
                        },
                    }
                }, {}),
    */

    function printDiv(divName: string) {
        const printContents = document.getElementById(divName)?.innerHTML;
        const originalContents = document.body.innerHTML;
        if (printContents) {
            document.body.innerHTML = printContents;

            window.print();

            document.body.innerHTML = originalContents;
        }
    }

    function saveCSV() {
        // const data = JSON.parse(processflowProgressQuery.data?.[0].data ?? "[]");
        const data = getDataFromProgressData(JSON.parse(processflowProgressQuery.data?.[0]?.data ?? "[]") ?? "");
        const entries = JSON.parse(processflowStepsQuery.data?.[1]?.entries ?? "[]");
        const csvContent = "data:text/csv;charset=utf-8,";
        const header = entries.map((e: any) => e.value).join(",");
        const row = entries.map((e: any) => data[e.field]).join(",");
        const csv = csvContent + header + "\n" + row;
        console.error({ entries, data, csv });
        const encodedUri = encodeURI(csv);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "my_data.csv");
        document.body.appendChild(link); // Required for FF
        link.click();
    }

    function save2() {
        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";

        const progress = getDataFromProgressData(JSON.parse(processflowProgressQuery.data?.[0]?.data ?? "[]") ?? "");
        const entries = JSON.parse(processflowStepsQuery.data?.[1]?.entries ?? "[]");
        console.error({ entries, progress });
        const excelRow: any = {};
        entries.forEach((e: any) => {
            excelRow[e.value] = progress[e.field];
        });
        const excelData = [excelRow];
        // const excelData = [{ test: 123 }, { test: 456 }];

        const ws = XLSX.utils.json_to_sheet(excelData);
        const excelBuffer = XLSX.write(
            {
                Sheets: { data: ws },
                SheetNames: ["data"],
            },
            { bookType: "xlsx", type: "array" },
        );
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, (usersQuery.data?.find((u) => u.id === id)?.name ?? "No Name").trim() + fileExtension);
    }

    return (
        <>
            {withoutHeader ? (
                <div className="flex flex-row gap-5">
                    <ButtonNeoGen
                        text="Print Summary"
                        icon="fal fa-print"
                        onClick={() => {
                            printDiv("printable");
                        }}
                    />
                    <ButtonNeoGen
                        text="Save Excel"
                        icon="fal fa-file-excel"
                        onClick={() => {
                            save2();
                        }}
                        type="info"
                    />
                </div>
            ) : (
                <PageDescription
                    title={"" + usersQuery.data?.find((u) => u.id === id)?.name + " Summary"}
                    description={
                        "This page shows a summary of " +
                        usersQuery.data?.find((u) => u.id === id)?.name +
                        "'s information"
                    }
                    buttons={[
                        {
                            label: "Print Summary",
                            icon: "fal fa-print",
                            onClick: () => {
                                printDiv("printable");
                            },
                        },
                        {
                            label: "Save Excel",
                            icon: "fal fa-file-excel",
                            onClick: () => {
                                save2();
                            },
                            bg: "bg-blue-500",
                        },
                    ]}
                />
            )}

            <div id="printable" className="">
                {/* <PrintPre>{processflowStepsQuery9.data}</PrintPre>
                <Wizard
                    drawWithFlex={true}
                    stages={processflowStagesQuery9.data as Stage[]}
                    stageId={33}
                    noMargin={true}
                    onStageChange={(stage) => {
                        console.log("stage", stage);
                    }}
                    steps={
                        processflowStepsQuery9.data?.map((step) => {
                            return {
                                id: step.id ?? -1,
                                name: step.name ?? "",
                                action: () => console.log,
                                status: "complete",
                            };
                        }) ?? []
                    }
                    entries={JSON.parse(processflowStepsQuery9.data?.[0]?.entries ?? "[]")}
                    onEntryChange={() => console.log("entry changed")}
                    data={getDataFromProgressData(JSON.parse(processflowProgressQuery9.data?.[0]?.data ?? "[]") ?? "")}
                    onButtonClick={({ action }) => {
                        console.log("button clicked", action);
                    }}
                    hideStages={true}
                    hideSteps={true}
                /> */}
                <Wizard
                    drawWithFlex={true}
                    stages={processflowStagesQuery.data as Stage[]}
                    stageId={44}
                    noMargin={true}
                    onStageChange={(stage) => {
                        console.log("stage", stage);
                    }}
                    steps={
                        processflowStepsQuery.data?.map((step) => {
                            return {
                                id: step.id ?? -1,
                                name: step.name ?? "",
                                action: () => console.log,
                                status: "complete",
                            };
                        }) ?? []
                    }
                    entries={JSON.parse(processflowStepsQuery.data?.[1]?.entries ?? "[]")}
                    onEntryChange={() => console.log("entry changed")}
                    data={getDataFromProgressData(JSON.parse(processflowProgressQuery.data?.[0]?.data ?? "[]") ?? "")}
                    onButtonClick={({ action }) => {
                        console.log("button clicked", action);
                    }}
                    hideStages={true}
                    hideSteps={true}
                />
            </div>
        </>
    );
}

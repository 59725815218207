/*
  This example requires Tailwind CSS v2.0+

  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { Fragment, useState } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";

import { useContext, useEffect, useLayoutEffect } from "react";
import { Dialog, Switch } from "@headlessui/react";
import { Bars3CenterLeftIcon } from "@heroicons/react/24/outline";
import logo from "../../images/logo.png";
import { NavLink, useLocation } from "react-router-dom";
import UserContext from "../../services/user-context";
import authService from "../../services/auth.service";
import PrintPre from "../../layout/print-pre";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import Loader2 from "../utilities/Loader2";
import menuService from "../../services/menu.service";
import { Navigation } from "../../typings/api/navigations";
import { UserIcon } from "@heroicons/react/24/solid";

type NavigationType = {
    name: string;
    icon: string;
    href: string;
    current: boolean;
    requiredRoles?: string[];
};

const checker = (arr: string[], target: string[]) => target.every((v) => arr.includes(v));

const user = {
    name: "Tom Cook",
    email: "tom@example.com",
    imageUrl:
        "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
};
const navigation = [
    { name: "Dashboard", href: "#", current: true },
    { name: "Team", href: "#", current: false },
    { name: "Projects", href: "#", current: false },
    { name: "Calendar", href: "#", current: false },
    { name: "Reports", href: "#", current: false },
];
const userNavigation = [
    // { name: "Your Profile", href: "#" },
    { name: "Your Application", href: "/processflow-wizard/7" },
    { name: "Sign out", href: "/logout" },
];

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
}

type DashboardTopProps = {
    className?: string;
    children?: React.ReactNode;

    darkMode: boolean;
    setDarkMode: (darkMode: boolean) => void;
    sideBarOpen?: boolean;
    setSideBarOpen?: (sidebarOpen: boolean) => void;
};

export default function DashboardTop(props: DashboardTopProps) {
    const [darkMode, setDarkMode] = useState(false);
    const [usePadding, setUsePadding] = useState(true);
    const [navigation, setNavigation] = useState<Navigation[]>([]);
    // lg:border-t lg:border-b lg:border-gray-200 dark:border-gray-900

    const cache = useQueryClient();
    // const [navigation, setNavigation] = useState<NavigationType[]>(initialNavigation.filter(n => n.requiredRoles === null || checker(authService.roles, n.requiredRoles ?? [])));
    // const [navigation, setNavigation] = useState<NavigationType[]>(initialNavigation);
    function setWrapperEnabledDarkMode(enabled: boolean) {
        // $('body').toggleClass('dark-theme');

        // alert(enabled);

        props.setDarkMode(enabled);
        if (enabled) {
            document.body.classList.add("dark-theme");
            document.body.classList.add("dark");
            localStorage.setItem("theme", "dark");
        } else {
            document.body.classList.remove("dark-theme");
            document.body.classList.remove("dark");
            localStorage.setItem("theme", "light");
        }
        // window.location.reload();
    }

    const menusQuery = useQuery(["menus"], async () => {
        return await menuService.getAllVisibleOrdered();
        // if (result) {
        // return result.data
        // }
    });

    useLayoutEffect(() => {
        if (location.pathname === "/dashboard") {
            setUsePadding(false);
        } else {
            setUsePadding(true);
        }

        setNavigation((current) =>
            current.map((item) => {
                if (item.href === location.pathname) {
                    item.current = 1;
                } else {
                    item.current = 0;
                }
                return item;
            }),
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);
    useEffect(() => {
        if (menusQuery.isSuccess) {
            const roles = authService.roles;
            // let newNav = initialNavigation.filter(n => n.requiredRoles === null || checker(authService.roles.map(r => r.roleCode ?? ""), n.requiredRoles ?? []))
            const newNav = (menusQuery.data ?? [])
                .map((item) => {
                    if (item.href === location.pathname) {
                        item.current = 1;
                    } else {
                        item.current = 0;
                    }
                    return item;
                })
                .filter(
                    (n) =>
                        n.requiredRoles === null ||
                        checker(
                            authService.roles.map((r) => r?.roleCode ?? ""),
                            JSON.parse(n.requiredRoles).roleGroups ?? [],
                        ),
                );
            setNavigation(newNav);

            // setNavigation()
            // });
            // .catch((err) => {});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [menusQuery.isSuccess, menusQuery.data, location.pathname]);

    // const [sidebarOpen, setSidebarOpen] = useState(false);

    if (menusQuery.isLoading) {
        return <Loader2 />;
    }

    return (
        <Disclosure
            as="nav"
            className="border-b border-indigo-300 border-opacity-25 bg-gradient-to-br from-indigo-700 to-indigo-800 lg:border-none"
        >
            {({ open }) => (
                <>
                    <div className="mx-auto max-w-7xl px-2 sm:px-4 lg:px-8">
                        <div className="relative flex h-16 items-center justify-between lg:border-b lg:border-indigo-400 lg:border-opacity-25">
                            <div className="flex items-center px-2 lg:px-0">
                                <div className="flex-shrink-0">
                                    <img className="block h-8 " src={logo} alt="Clear ERC" />
                                </div>
                                <div className="hidden lg:ml-10 lg:block">
                                    <div className="flex space-x-4">
                                        {navigation.map((item) => (
                                            <a
                                                key={item.name}
                                                href={item.href}
                                                className={classNames(
                                                    item.current
                                                        ? "bg-indigo-700 text-white"
                                                        : "text-gray-200 hover:bg-indigo-500 hover:bg-opacity-75",
                                                    "rounded-md py-2 px-3 text-sm font-medium",
                                                )}
                                                aria-current={item.current ? "page" : undefined}
                                            >
                                                {item.name}
                                            </a>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-1 justify-center px-2 lg:ml-6 lg:justify-end">
                                <div className="w-full max-w-lg lg:max-w-xs">
                                    <label htmlFor="search" className="sr-only">
                                        Search
                                    </label>
                                    <div className="relative text-gray-400 focus-within:text-gray-600">
                                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                            <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
                                        </div>
                                        <input
                                            id="search"
                                            className="block w-full rounded-md border border-transparent bg-white dark:bg-gray-800  py-2 pl-10 pr-3 leading-5 text-gray-900 placeholder-gray-500 focus:border-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600 sm:text-sm"
                                            placeholder="Search"
                                            type="search"
                                            name="search"
                                        />
                                    </div>
                                </div>
                            </div>
                            <Switch.Group
                                as="li"
                                className="flex-shrink-0 py-0 px-5 flex items-center justify-between mr-4 pb-0"
                            >
                                <div className="flex flex-col">
                                    <Switch.Label
                                        as="p"
                                        className="text-sm font-medium text-gray-100 dark:text-gray-300"
                                        passive
                                    >
                                        {!props.darkMode ? "Light Mode" : "Dark Mode"}
                                    </Switch.Label>
                                </div>
                                <Switch
                                    checked={props.darkMode}
                                    onChange={setWrapperEnabledDarkMode}
                                    className={classNames(
                                        props.darkMode ? "bg-blue-800" : "bg-gray-200",
                                        "ml-4 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-light-blue-500",
                                    )}
                                >
                                    <span className="sr-only">Use setting</span>
                                    <span
                                        aria-hidden="true"
                                        className={classNames(
                                            props.darkMode ? "translate-x-5" : "translate-x-0",
                                            "inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200",
                                        )}
                                    />
                                </Switch>
                            </Switch.Group>
                            <div className="flex lg:hidden">
                                {/* Mobile menu button */}
                                <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-indigo-600 p-2 text-indigo-200 hover:bg-indigo-500 hover:bg-opacity-75 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600">
                                    <span className="sr-only">Open main menu</span>
                                    {open ? (
                                        <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                                    ) : (
                                        <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                                    )}
                                </Disclosure.Button>
                            </div>
                            <div className="hidden lg:ml-4 lg:block">
                                <div className="flex items-center">
                                    <button
                                        type="button"
                                        className="flex-shrink-0 rounded-full bg-indigo-600 p-1 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600"
                                    >
                                        <span className="sr-only">View notifications</span>
                                        <BellIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>

                                    {/* Profile dropdown */}
                                    <Menu as="div" className="relative ml-3 flex-shrink-0">
                                        <div>
                                            <Menu.Button className="flex rounded-full bg-indigo-600 text-sm text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600">
                                                <span className="sr-only">Open user menu</span>
                                                <UserIcon className="fas fa-user pt-1 h-8 w-8 rounded-full bg-slate-900" />
                                            </Menu.Button>
                                        </div>
                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-100"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                        >
                                            <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white dark:bg-gray-800  py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                {userNavigation.map((item) => (
                                                    <Menu.Item key={item.name}>
                                                        {({ active }) => (
                                                            <a
                                                                href={item.href}
                                                                className={classNames(
                                                                    active ? "bg-gray-700" : "",
                                                                    "block py-2 px-4 text-sm text-gray-700 dark:text-gray-400",
                                                                )}
                                                            >
                                                                {item.name}
                                                            </a>
                                                        )}
                                                    </Menu.Item>
                                                ))}
                                            </Menu.Items>
                                        </Transition>
                                    </Menu>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </Disclosure>
    );
}

/**
 *
 *
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 8/26/20, 8:47 PM
 *
 */

import { useEffect, useState } from "react";
import Switch from "react-switch";
import { useRef } from "react";
import Loader2 from "../../../utilities/Loader2";
import ModalDialog from "../../../../layout/modal-dialog";
import { NeogenRoles } from "../../../../typings/api";
import { RoleGroup } from "../../../../typings/api/role-group";
import { useQuery } from "@tanstack/react-query";
import roleGroupService, { roleAssignments } from "../../../../services/role-group.service";
import authService from "../../../../services/auth.service";

/**
 * @component
 * @category Users
 */
function AddEditRoleGroupsModal(props: AddEditRoleGroupsModalProps) {
    const [roles, setRoles] = useState(props.roleGroups);
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [stPass, setStPass] = useState("");
    const [stUrl, setStUrl] = useState("");
    const [company, setCompany] = useState(-1);
    // const [open, setOpen] = useState(true);

    const cancelButtonRef = useRef(null);

    useEffect(() => {
        setRoles(props.roleGroups);
    }, [props.roleGroups]);

    useEffect(() => {
        if (!props.isEdit) {
            setEmail("");
            setName("");
            setCompany(-1);
        } else {
            if (props.userBeingEdited) {
                setEmail(props.userBeingEdited.email);
                setName(props.userBeingEdited.name);
                setStPass(props.userBeingEdited.stPass);
                setStUrl(props.userBeingEdited.stUrl);
                setCompany(props.userBeingEdited.company ?? -1);
            }
        }
    }, [props.isEdit, props.userBeingEdited]);

    function setRole(role: NeogenRoles, value: any) {
        console.log("setRole(" + role.id + ", " + value);
        const newRoles = [...roles];
        if (value) {
            newRoles.push(role);
        } else {
            for (let i = 0; i < newRoles.length; i++) {
                if (newRoles[i].id === role.id) {
                    newRoles.splice(i, 1);
                    break;
                }
            }
            // props.roles.find(checkRole => checkRole.id === role.id).delete();
        }
        setRoles(newRoles);
    }

    function saveUser() {
        props.saveRoleGroups(
            props.isEdit,
            roles,
            email,
            name,
            company,
            props.userBeingEdited,
            props.roleGroups,
            stPass,
            stUrl,
        );
    }

    const roleGroupsQuery = useQuery(
        ["role-groups"],
        async () => {
            const response = await roleGroupService.getAll();
            if (response) {
                return response.data;
            }
        },
        {
            cacheTime: 600000,
            staleTime: 600000,
            refetchOnMount: true,
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
        },
    );

    function compare(a: any, b: any) {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    }

    const allRoleGroups = roleGroupsQuery.data || [];
    const usersRoleGroups = (authService.getCurrentUser()?.user?.roleGroups || []) as any[];
    const roleGroupsIds = usersRoleGroups.reduce<number[]>((acc, roleGroup: any) => {
        return [...acc, ...(roleAssignments?.[roleGroup.id] || [])];
    }, []);
    const roleGroups = allRoleGroups.filter((rg) => roleGroupsIds.includes(rg.id || 9999));

    return (
        <>
            <ModalDialog
                size="md"
                okText="Save"
                okAction={() => saveUser()}
                show={props.show}
                close={() => props.handleClose(false)}
                title={
                    props.isEdit
                        ? props.userBeingEdited === null
                            ? "Loading..."
                            : "Editing: " + props.userBeingEdited.email
                        : "New User"
                }
            >
                <div className="mt-2">
                    {roleGroupsQuery.isLoading ||
                    !props.roleGroups ||
                    (props.userBeingEdited === null && props.isEdit) ||
                    props.saving ? (
                        <div className={"mt-5 pt-5 mb-5 pb-5 text-center"}>
                            <Loader2 />
                        </div>
                    ) : (
                        <div>
                            {/* Roles */}
                            <div className={"grid grid-cols-1 gap-2"}>
                                {roleGroups?.sort(compare).map((role: NeogenRoles, idx: number) => {
                                    return (
                                        <div key={role.id ?? -1} className="col-span-1 mb-3">
                                            <Switch
                                                height={18}
                                                width={40}
                                                className={"mr-3"}
                                                onChange={(e) => {
                                                    setRole(role, e);
                                                }}
                                                checked={
                                                    !!roles?.find((checkRole: NeogenRoles) => checkRole.id === role.id)
                                                }
                                            />
                                            <span className="align-self-center">{role.name}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                </div>
            </ModalDialog>
        </>
    );
}

type AddEditRoleGroupsModalProps = {
    handleClose: (x: boolean) => void;
    show: boolean;
    userBeingEdited: any;
    roleGroups: RoleGroup[];
    // allRoles: any,
    setRoles: any;
    saveRoleGroups: (
        isEdit: boolean,
        roles: NeogenRoles[],
        email: string,
        name: string,
        company: number,
        userBeingEdited: any,
        roleGroups: RoleGroup[],
        stPass: string,
        stUrl: string,
    ) => void;
    saving: boolean;
    companiesQuery: any;
    isEdit: boolean;
};

export default AddEditRoleGroupsModal;

/*
 *
 *
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 8/22/20, 10:29 AM
 *
 */

import "./LoadingIndicator.css";
// import { usePromiseTracker } from "react-promise-tracker";

function Loader2(props: LoaderProps) {
    // const { promiseInProgress } = usePromiseTracker();

    return (
        <div className={"flex justify-center flex-col align-middle " + (props.noHeight ? "" : "h-48")}>
            <div className={(props.noMargin ? "" : "mt-10") + " self-center inline-block align-middle"}>
                <div
                    className={
                        "loader ease-linear rounded-full border-4 border-t-4 border-gray-200  h-12 w-12 " +
                        (props.noMargin ? "" : "mb-4")
                    }
                ></div>
            </div>
        </div>
    );
}

type LoaderProps = {
    noHeight?: boolean;
    noMargin?: boolean;
};

export default Loader2;

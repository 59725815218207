/* This example requires Tailwind CSS v2.0+ */
export default function BadgeDismissible(props: BadgeDismissibleProps) {
    return (
        <span className={"mr-3 mb-3 " + props.className}>
            {props.isLarge ? (
                <span className="inline-flex rounded-full items-center py-0.5 pl-2.5 pr-1 text-sm font-medium bg-indigo-100 text-indigo-700">
                    {props.label}
                    <button
                        type="button"
                        className="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500 focus:outline-none focus:bg-indigo-500 focus:text-white"
                        onClick={() => props.onRemove()}
                    >
                        <span className="sr-only">Remove {props.label} option</span>
                        <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                            <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                        </svg>
                    </button>
                </span>
            ) : (
                <span className="inline-flex items-center py-0.5 pl-2 pr-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-700 dark:bg-gray-600 dark:text-gray-300">
                    {props.label}
                    <button
                        type="button"
                        className="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500 focus:outline-none focus:bg-indigo-500 focus:text-white dark:text-gray-200"
                        onClick={() => props.onRemove()}
                    >
                        <span className="sr-only">Remove {props.label} option</span>
                        <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                            <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                        </svg>
                    </button>
                </span>
            )}
        </span>
    );
}

type BadgeDismissibleProps = {
    label: string;
    onRemove: () => void;
    isLarge?: boolean;
    className?: string;
};

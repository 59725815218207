import { atom } from "recoil";
import { ProcessFlow } from "../typings/api/processflow";
import { localStorageEffect } from "./local-storage-effect";

const stepAtom = atom<ProcessFlow>({
    key: "stepAtom",
    default: {} as ProcessFlow,
    // Commented out to prevent local storage Quota Exceeded error.
    // effects: [
    //     localStorageEffect("stepAtom"),
    // ]
});

export default stepAtom;

import _ from "lodash";
import processflowProgressService from "../../../services/processflow-progress.service";
import { FileUpload } from "../../../typings/api/file-upload";
import { ProcessFlowProgressData } from "../../../typings/api/processflow-progress-data";
import { useRecoilState, useRecoilValue } from "recoil";
import savingAtom, { SaveType } from "../../../atoms/savingAtom";
import { ClearERCUser } from "../../../typings/api/clear-erc-user";
import userAtom from "../../../atoms/userAtom";
import processflowProgressIdAtom from "../../../atoms/processflowProgressIdAtom";
import groupAtom from "../../../atoms/groupAtom";
import { useCallback } from "react";
import progressDataAtom from "../../../atoms/progressDataAtom";
import positionAtom, { Position } from "../../../atoms/positionAtom";
import { number } from "prop-types";

export type FieldChangeParams = {
    (
        fieldType:
            | "checkbox"
            | "textfield"
            | "textarea"
            | "yesNo"
            | "selectBox"
            | "fileUpload"
            | "state"
            | "datePicker",
        fieldValue: string | number | boolean | FileUpload | Date,
        fieldName: string,
        fromAPI: boolean,
    ): Promise<boolean>;
};

export default function useFieldChanged(userId: string, processFlowGroup: number) {
    // console.trace({ fieldType, fieldName, fieldValue });
    // const [user, setUser] = useRecoilState<ClearERCUser>(userAtom);
    const [saving, setSaving] = useRecoilState<SaveType>(savingAtom);
    // const [group, setGroup] = useRecoilState<number>(groupAtom);
    const [processflowProgressId, setprocessflowProgressId] = useRecoilState<number>(processflowProgressIdAtom);
    const [progressData, setProgressData] = useRecoilState<ProcessFlowProgressData>(progressDataAtom);
    const position = useRecoilValue<Position>(positionAtom);
    // fieldChanged("datePicker", e, entry.field, false);
    const fieldChanged = useCallback(
        async (
            fieldType:
                | "checkbox"
                | "textfield"
                | "textarea"
                | "yesNo"
                | "selectBox"
                | "fileUpload"
                | "state"
                | "datePicker",
            fieldValue: string | number | boolean | FileUpload | Date,
            fieldName: string,
            fromAPI: boolean,
            isError = false,
        ) => {
            const currentData: ProcessFlowProgressData | undefined = _.cloneDeep(progressData);
            if (currentData && position.stageId) {
                currentData.currentStage = position.stageId;
            }
            if (currentData && position.stepId) {
                currentData.currentStep = position.stepId;
            }
            // alert(
            //     `fieldChanged(${fieldType}, ${fieldValue}, ${fieldName}, ${fromAPI})`
            // );
            if (!currentData) {
                console.log("No current data: ", currentData);
            } else {
                const cloned = _.cloneDeep(currentData.entriesByField ?? {});
                if (!currentData.entriesByField) {
                    currentData.entriesByField = {};
                }
                const currentValue = cloned[fieldName]?.fieldValue;

                if (_.isEqual(currentValue, fieldValue) && !isError) {
                    // No change
                    console.log("No change", { currentValue, fieldValue });
                    return false;
                } else if (currentValue === undefined && (fieldValue === null || fieldValue === "")) {
                    console.log("blank load");
                    console.error(localStorage.getItem("progressDataAtom"));
                } else if (currentValue === undefined) {
                    // No change
                    console.log("First change", { currentValue, fieldValue });
                    const newEntry = {
                        fieldName,
                        fieldType,
                        fieldValue,
                    };
                    currentData.entriesByField[fieldName] = newEntry;
                    // alert(JSON.stringify(value))
                    setProgressData(currentData);
                    return false;
                } else {
                    console.log("Field changed", { fieldName, fieldType, from: currentValue, to: fieldValue });
                }
                const newEntry = {
                    fieldName,
                    fieldType,
                    fieldValue,
                };
                currentData.entriesByField[fieldName] = newEntry;
                setProgressData(currentData); //TODO: Bring back side effects but not in here
                let progressId;
                try {
                    // await saveProgressData(currentData);
                    if (!processflowProgressId || processflowProgressId === -1) {
                        // TODO: We need to have different Ids for each process flow
                        const response = await processflowProgressService.getProgress(userId ?? "", processFlowGroup);
                        if (response) {
                            // Not guaranteed to be in the DB
                            if (response.data.length === 0) {
                                // Not in DB
                                const newProgress = {
                                    userId: userId,
                                    data: '{"completedSteps":[],"entriesByField":{}}',
                                    client: 0,
                                    group: processFlowGroup,
                                    currentStep: position.stepId,
                                    currentStage: position.stageId,
                                };
                                const response = await processflowProgressService.create(newProgress);
                                if (!response) {
                                    console.log("Error creating progress");
                                } else {
                                    console.log(response.data);
                                }
                            } else {
                                console.error("found");
                                progressId = response.data[0]?.id;
                                if (progressId) {
                                    setprocessflowProgressId(progressId);
                                }
                            }

                            // console.log(result);
                        }
                    } else {
                        const trace = new Error().stack;
                        setSaving({ saving: true, from: trace });
                        // alert(progressId);
                        const result = await processflowProgressService.update(processflowProgressId, {
                            data: JSON.stringify(currentData),
                        });
                        setSaving({ saving: false });
                    }
                } catch (e) {
                    console.log(e);
                    return false;
                }
            }

            return true;
        },
        [
            position,
            processFlowGroup,
            processflowProgressId,
            progressData,
            setProgressData,
            setSaving,
            setprocessflowProgressId,
            userId,
        ],
    );
    return { fieldChanged };
}

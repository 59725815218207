import { makeRequest, parseSchema } from "../../shared/utils";
import { ProcessflowProgress, processflowProgressSchema } from "../domain/processflow-progress";
import { processflowProgressResponseSchema } from "./get-processflow-progresses";

export const createProcessflowProgress = async ({
    authToken,
    data,
}: {
    authToken?: string;
    data: Partial<ProcessflowProgress>;
}) => {
    const response = await makeRequest({
        method: "post",
        path: "/processflow-progresses",
        authToken,
        data: { ...data, data: JSON.stringify(data.data) },
    });

    const processflowProgressDto = parseSchema(processflowProgressResponseSchema, response.data);

    const processflowProgress = parseSchema(processflowProgressSchema, {
        ...processflowProgressDto,
        data: processflowProgressDto.data ? JSON.parse(processflowProgressDto.data) : undefined,
    });

    return processflowProgress;
};

import { useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

export default function Logout() {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    useEffect(() => {
        queryClient.removeQueries();
        localStorage.removeItem("userId");
        localStorage.removeItem("publicUserId");
        localStorage.removeItem("name");
        localStorage.removeItem("email");
        localStorage.removeItem("company");
        localStorage.removeItem("user");
        localStorage.clear();
        navigate("/login");
    }, [navigate, queryClient]);
    return (
        <div>
            <h1>Logout</h1>
            <p>You are now logged out.</p>
        </div>
    );
}

import { useState } from "react";
import { Currency } from "./components";
import { classNames } from "./utils";

export const FinanceOptions = ({
    estimatedAmount,
    defaultValue,
    onChange,
}: {
    estimatedAmount: number;
    defaultValue?: string;
    onChange: (id: string) => any;
}) => {
    const [selectedFinanceOption, setFinanceOption] = useState(defaultValue || "");

    const handleChange = (id: string) => {
        setFinanceOption(id);
        onChange(id);
    };

    const financeOptions = [
        {
            name: "Up to 65% financed",
            id: "up_to_65_percent",
            description: "Get up to 65% of your ERC refund paid to you upfront.",
            items: ["Get approved in under 4 weeks, then get 65% payout within 48 hours."],
            payoutPercentage: 0.65,
        },
        {
            name: "100% financed",
            id: "100_percent",
            description: "Get 100% of your ERC refund paid to you upfront.",
            items: ["Get approved in under 2 weeks, then get 100% payout within 24 hours."],
            payoutPercentage: 1,
            mostPopular: true,
        },
        {
            name: "No financing",
            id: "no_financing",
            href: "#",
            description: "Don't finance your refund. You may have to wait up to 8 months for IRS payouts.",
            items: ["You'll have to wait for up to 8 months for the IRS to make your payouts."],
            payoutPercentage: 0,
        },
    ];

    return (
        <div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "flex-end",
                    gap: 80,
                }}
                className="pb-2"
            >
                <div>
                    <h2 style={{ fontSize: 22 }}>Would you like to finance your ERC refund?</h2>
                    <span className="text-gray-400">
                        Payout from the IRS is slow and can take up to 8 months. <br /> Finance your refund to get your
                        payout in as little as 2 weeks.{" "}
                    </span>
                </div>
                <div>
                    Your expected payout from the IRS:{" "}
                    <b>
                        <Currency amount={estimatedAmount} />
                    </b>
                </div>
            </div>
            <div className="mt-2">
                <div className="isolate mx-auto mt-6 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                    {financeOptions.map((option) => {
                        const isSelected = selectedFinanceOption === option.id;
                        return (
                            <div
                                key={option.id}
                                className={classNames(
                                    isSelected ? "ring-2 ring-indigo-600" : "ring-1 ring-gray-200",
                                    "rounded-2xl p-6 xl:p-8 relative bg-gray-50",
                                )}
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    handleChange(option.id);
                                }}
                            >
                                {option.mostPopular && (
                                    <p className="absolute right-4 top-0 -translate-y-1/2 transform rounded-full bg-indigo-600 py-1.5 px-4 text-sm font-semibold text-white">
                                        Most popular
                                    </p>
                                )}
                                <div className="flex items-center gap-x-4">
                                    <h3
                                        id={option.id}
                                        className={classNames(
                                            isSelected ? "text-indigo-600" : "text-gray-900",
                                            "text-xl font-semibold leading-8 text-center flex-1",
                                        )}
                                    >
                                        {option.name}
                                    </h3>
                                </div>
                                <p className="mt-1 text-sm leading-6 text-gray-500 text-center h-16">
                                    {option.description}
                                </p>
                                {option.items?.[0] && (
                                    <p className="mt-10 text-md leading-6 text-gray-600 text-center">
                                        {option.items?.[0]}
                                    </p>
                                )}
                                <p className="mt-6 text-md leading-6 text-gray-600 text-center">
                                    Balance paid out once funds are received
                                </p>
                                <p className="text-lg leading-6 text-gray-900 text-center">
                                    <b>
                                        <Currency amount={(1 - option.payoutPercentage || 0) * estimatedAmount} />
                                    </b>
                                </p>
                                {option.payoutPercentage !== undefined && (
                                    <>
                                        <hr className="mt-6" />
                                        <p className="mt-6 text-lg text-center text-gray-900">Paid to you upfront</p>
                                        <p className="text-3xl text-center text-gray-900">
                                            <b>
                                                <Currency amount={(option.payoutPercentage || 0) * estimatedAmount} />
                                            </b>
                                        </p>
                                    </>
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

import { Modal } from "react-bootstrap";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useEffect, useState } from "react";
import { convertToHTML } from "draft-convert";
import { ContentState, convertToRaw, convertFromRaw } from "draft-js";
import DOMPurify from "dompurify";
import authService from "../../../services/auth.service";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import helpDocsService from "../../../services/help-docs.service";
import { NeoGenHelpDocs } from "../../../typings/api";
import "./add-faq.css";
import { faTags } from "@fortawesome/pro-regular-svg-icons";
import ModalDialog from "../../../layout/modal-dialog";

export default function DeleteFaq(props: DeleteFaqProps) {
    const queryCache = useQueryClient();
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [convertedContent, setConvertedContent] = useState(null);
    const [canAddFaqs, setCanAddFaqs] = useState(false);
    const [showAddFaq, setShowAddFaq] = useState(false);
    const [title, setTitle] = useState("");
    const [tags, setTags] = useState("");
    const [active, setActive] = useState(0);
    const [isPublic, setIsPublic] = useState(0);
    // const helpDocsQuery = useQuery[("HelpDocs"], async () => {
    //     let response = await helpDocsService.getAll();
    //     if (response) {
    //         return response.data;
    //     }
    // });
    useEffect(() => {
        authService.canIAccess("ADD_FAQ").then((r) => {
            setCanAddFaqs(r);
            if (props.existingEntry) {
                setTitle(props.existingEntry.title);
                setTags(props.existingEntry.tags);
                setIsPublic(props.existingEntry.public);
                setActive(props.existingEntry.active);
                setEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(props.existingEntry.body))));
            } else {
                setEditorState(EditorState.createEmpty());
            }
        });
    }, [props.existingEntry]);
    const convertContentToHTML = () => {
        const currentContentAsHTML = convertToHTML({
            styleToHTML: (style: any) => {
                console.log(style);
                if (style === "BOLD") {
                    return <span style={{ fontWeight: "bold" }} />;
                }
                if (style.substring(0, 9) === "fontsize-") {
                    return <span style={{ fontSize: style.substring(9) + "px" }} />;
                }
            },
            blockToHTML: (block: any) => {
                console.log(block);
                if (block.type === "PARAGRAPH") {
                    return <p />;
                } else if (block.type === "unstyled") {
                    return <div style={{ minHeight: "2rem" }} />;
                }
            },
            entityToHTML: (entity: any, originalText: any) => {
                console.log(entity, originalText);
                if (entity.type === "LINK") {
                    return <a href={entity.data.url}>{originalText}</a>;
                }
                return originalText;
            },
        })(editorState.getCurrentContent(), { flat: true });
        console.log(convertToRaw(editorState.getCurrentContent()));
        setConvertedContent(currentContentAsHTML);
    };

    const onEditorStateChange = (editorState: any) => {
        console.log(editorState);
        setEditorState(editorState);
        convertContentToHTML();
    };

    function deleteFaq() {
        // alert(props.existingEntry?.id);
        if (props.existingEntry?.id) {
            helpDocsService.deleteByID(props.existingEntry?.id).then((r) => {
                queryCache.invalidateQueries(["HelpDocs"]).then((r: any) => {
                    console.log(r);
                    console.log("Invalidated");
                });

                props.hide();
            });
        }
    }

    function save() {
        console.log("Saving");
        const body = JSON.stringify(convertToRaw(editorState.getCurrentContent()));

        if (props.existingEntry) {
            props.existingEntry.body = body;
            props.existingEntry.tags = tags;
            props.existingEntry.title = title;
            props.existingEntry.public = Number(isPublic);
            props.existingEntry.active = Number(active);
            helpDocsService
                .patchURL(helpDocsService.endpoint + "/" + props.existingEntry.id, props.existingEntry)
                .then((r) => {
                    console.log(r);
                });
        } else {
            const newEntry: NeoGenHelpDocs = {
                title: title,
                body: body,
                tags: tags ?? "",
                updated: new Date(),
                created: new Date(),
                active: 0,
                public: 0,
                preview: "",
            };
            helpDocsService.create(newEntry).then((r) => {
                console.log(r);
            });
        }

        queryCache.invalidateQueries(["HelpDocs"]).then((r: any) => {
            console.log(r);
            console.log("Invalidated");
        });

        props.hide();
        console.log(body);
    }

    return (
        <ModalDialog
            title="Delete an Entry"
            show={props.show}
            close={() => props.hide()}
            okAction={() => deleteFaq()}
            okText="Delete FAQ"
        >
            <p>Are you sure you want to delete this FAQ Entry?</p>
        </ModalDialog>
    );
}

type DeleteFaqProps = {
    show: boolean;
    hide: () => void;
    existingEntry?: NeoGenHelpDocs | null;
};

export const localStorageEffect =
    (key: string) =>
    ({ setSelf, onSet }: { setSelf: any; onSet: any }) => {
        const savedValue = localStorage.getItem(key);
        if (savedValue != null) {
            try {
                setSelf(JSON.parse(savedValue));
            } catch (error) {
                localStorage.removeItem(key);
            }
        }

        onSet((newValue: any, _: any, isReset: any) => {
            isReset
                ? localStorage.removeItem(key)
                : newValue !== undefined
                ? localStorage.setItem(key, JSON.stringify(newValue))
                : localStorage.removeItem(key);
        });
    };

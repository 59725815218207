import WizardSteps from "../wizard-steps";
import positionAtom, { Position } from "../../../atoms/positionAtom";
import { useRecoilState, useRecoilValue } from "recoil";
import userAtom from "../../../atoms/userAtom";
import { ClearERCUser } from "../../../typings/api/clear-erc-user";
import useRulePasses from "../../business-rules/hooks/use-rule-passes";
import progressDataAtom from "../../../atoms/progressDataAtom";
import { ProcessFlowProgressData } from "../../../typings/api/processflow-progress-data";
import { ProcessFlow } from "../../../typings/api/processflow";
// import enabledEntriesAtom from "../../../atoms/enabledEntriesAtom";
import { useParams } from "react-router-dom";
import PrintPre from "../../../layout/print-pre";
import enabledEntriesAtom from "../../../atoms/enabledEntriesAtom";
import useNavigateProcess from "../hooks/use-navigate-process";

export default function WizardTabs() {
    const { processflow: id } = useParams();
    // const [enabledEntries, setEnabledEntries] = useRecoilState<ProcessFlow[]>(enabledEntriesAtom);
    // const [enabledEntries, ] = useRecoilValue<ProcessFlow[]>(enabledEntriesAtom);
    // const enabledEntries:any[] = [
    //     { id: 31, name: "Job details", href: "#", status: "complete" },
    //     { id: 32, name: "Application form", href: "#", status: "current" },
    //     { id: 33    , name: "Preview", href: "#", status: "upcoming" },
    // ];
    const user = useRecoilValue<ClearERCUser>(userAtom);
    const position = useRecoilValue<Position>(positionAtom);
    const { checkIfStepPasses } = useRulePasses(Number(id));
    const [progressData, setProgressData] = useRecoilState<ProcessFlowProgressData>(progressDataAtom);
    const enabledEntries = useRecoilValue<ProcessFlow[]>(enabledEntriesAtom);
    const { setStepId } = useNavigateProcess(Number(id));
    return (
        <>
            {/* ref=ref removed */}
            <div className="mb-5 bg-white dark:bg-gray-700 rounded-none md:rounded-xl hidden xs:block">
                <div className="">
                    {/* <PrintPre>
                        {enabledEntries}
                    </PrintPre> */}
                    <WizardSteps
                        steps={(enabledEntries ?? [])
                            .map((entry: any, idx: number) => {
                                let status: "current" | "complete" | "upcoming" = "upcoming";
                                if (entry.id === position.stepId) {
                                    status = "current";
                                } else if (
                                    idx < (enabledEntries ?? []).findIndex((e: any) => e?.id === position.stepId)
                                ) {
                                    status = "complete";
                                } else if (
                                    idx > (enabledEntries ?? []).findIndex((e: any) => e?.id === position.stepId)
                                ) {
                                    status = "upcoming";
                                }

                                return {
                                    status,
                                    id: (idx + 1).toString(),
                                    origId: entry.id,
                                    name: entry.name,
                                    action: () => {
                                        setStepId(Number(entry.id));
                                        // return false;
                                    },
                                };
                            })
                            .filter((entry: any) => checkIfStepPasses(entry.origId, progressData).passed)}
                    />
                </div>
            </div>
        </>
    );
}

import { useState, useEffect } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
// Exmaple usgae:
// const [text, setText] = useState("");
// const debounced = useDebounce(text, 500);
//
// useEffect(() => {
//   console.log(debounced);
// }, [debounced]);
//
// In this example the effect would only be called at most once every 500ms

// Hook
// T is a generic type for value parameter, our case this will be string
export default function useDebounce<T>(value: T, delay: number): T {
    const queryClient = useQueryClient();
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState<T>(value);
    useEffect(
        () => {
            // queryClient.invalidateQueries();
            // Update debounced value after delay
            const handler = setTimeout(() => {
                // queryClient.invalidateQueries();
                setDebouncedValue(value);
            }, delay);
            // Cancel the timeout if value changes (also on delay change or unmount)
            // This is how we prevent debounced value from updating if value is changed ...
            // .. within the delay period. Timeout gets cleared and restarted.
            return () => {
                clearTimeout(handler);
            };
        },
        [value, delay, queryClient], // Only re-call effect if value or delay changes
    );
    return debouncedValue;
}

import { atom, DefaultValue } from "recoil";
import { ProcessFlow } from "../typings/api/processflow";
import { ProcessFlowProgressData } from "../typings/api/processflow-progress-data";
import { ProcessFlowStage } from "../typings/api/processflow-stage";
import { localStorageEffect } from "./local-storage-effect";

const enabledEntriesAtom = atom<ProcessFlow[]>({
    key: "enabledEntriesAtom",
    default: [],
    // effects: [
    //     localStorageEffect("enabledEntriesAtom"),
    // ]
});

export default enabledEntriesAtom;

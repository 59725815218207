/**
 *
 *
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 9/19/20, 10:58 AM
 *
 */

import { AxiosResponse } from "axios";
import { Flowroute } from "../typings/api/flowroute";
import { Interaction } from "../typings/api/interaction";
import { NeogenClickToCall } from "../typings/api/neogen-click-to-call";
import APIService from "./api.service";
import AuthService from "./auth.service";

class InteractionsService extends APIService<Interaction> {
    constructor() {
        super("interactions");
    }
    getAll() {
        const myId = AuthService.getCurrentUser().id;
        const filter = {
            or: [{ interactionFrom: myId }, { interactionTo: myId }],
        };
        return this.getFilteredWhere(filter);
    }
    getAllForId(id: string) {
        const filter = {
            or: [{ interactionFrom: id }, { interactionTo: id }, { interactionRegarding: id }],
        };
        return this.getFilteredWhere(filter);
    }
}

export default new InteractionsService();

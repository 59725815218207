import { z } from "zod";
import { makeRequest, parseSchema } from "../../shared/utils";
import { processflowStageSchema } from "../domain/processflow-stage";

const processflowStageResponseSchema = z.object({
    id: z.number(),
    name: z.string(),
    description: z.string(),
    order: z.number(),
    group: z.number(),
    isPublic: z.number(),
});

const getProcessflowStagesResponseSchema = z.array(processflowStageResponseSchema);

export const getProcessflowStages = async ({
    processflowGroup,
    authToken,
}: {
    processflowGroup: number;
    authToken?: string;
}) => {
    const response = await makeRequest({
        method: "get",
        path: "/processflow-stages",
        authToken,
        queryParams: {
            filter: JSON.stringify({
                where: { group: processflowGroup },
                include: [
                    {
                        relation: "businessRuleGroups",
                        scope: {
                            include: [
                                {
                                    relation: "businessRules",
                                },
                            ],
                        },
                    },
                ],
                order: ["order ASC", "id ASC"],
            }),
        },
    });

    const processflowStageDtos = parseSchema(getProcessflowStagesResponseSchema, response.data);

    const processflowStages = parseSchema(
        z.array(processflowStageSchema),
        processflowStageDtos.map((dto) => ({
            ...dto,
            isPublic: dto.isPublic === 0 ? false : true,
        })),
    );

    return processflowStages;
};

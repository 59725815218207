import { makeRequest, parseSchema } from "../../shared/utils";
import { userSchema } from "../domain/user";
import { userResponseSchema } from "./get-users";

export const getUser = async ({ authToken, id }: { authToken: string; id: string }) => {
    const response = await makeRequest({
        method: "get",
        path: `/users/${id}`,
        authToken,
    });

    const userDto = parseSchema(userResponseSchema, response.data);

    const user = parseSchema(userSchema, {
        ...userDto,
        company: userDto.company || undefined,
        name: userDto.name || undefined,
    });

    return user;
};

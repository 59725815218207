import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import PrintPre from "../../../layout/print-pre";
import PropTypes from "prop-types";

export function SortableItem(props: SortableItemProps) {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <div ref={setNodeRef} style={style} className="">
            {props.leftSide ? (
                <>
                    <div className={"flex"}>
                        <button
                            {...listeners}
                            {...attributes}
                            className="rounded-l-lg  left-0 h-full  mt-1 z-1 pt-3 bg-gray-200 p-1 text-xs px-2 text-gray-400
            hover:bg-indigo-700 hover:text-gray-200 dark:bg-green-900 dark:text-green-700 z-2 break-inside-avoid py-2"
                        >
                            <span className="fa-2x fal fa-grip-dots-vertical " />
                        </button>
                        {/* <div> */}
                        {props.value}
                        {/* </div> */}
                    </div>
                </>
            ) : (
                <>
                    {/* A step */}
                    <button
                        {...listeners}
                        {...attributes}
                        className="rounded-tl-lg rounded-tr-lg left-2 relative  bg-gray-200 p-1 text-xs px-2 text-gray-400
            hover:bg-indigo-700 hover:text-gray-200 dark:bg-green-900 dark:text-green-700 z-10 "
                    >
                        <span className="fal fa-grip-dots-vertical mr-3" />
                        Drag Me
                    </button>
                    {props.value}
                </>
            )}

            {/* ... */}
            {/* aaaaa */}
        </div>
    );
}

type SortableItemProps = {
    id: any;
    value: any;
    leftSide?: boolean;
};

// A sign up page that will take into account the source if it is a referral.
// It should display the default process flow for that source

import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import userAtom from "../../atoms/userAtom";
import { ClearERCUser } from "../../typings/api/clear-erc-user";
import { v4 as uuidv4 } from "uuid";
import { Position } from "../../atoms/positionAtom";
import positionAtom from "../../atoms/positionAtom";
import { WizardContainer } from "../../jason-proof-of-concept/wizard/wizard-container";
import axios from "axios";
import { sendSlackDebug } from "../../services/slack-notifications";
import { UTMLog } from "../../typings/api/utm-logs";
import utmLogService from "../../services/utm-log.service";
import progressDataAtom, { defaultProgressState } from "../../atoms/progressDataAtom";
import processflowProgressIdAtom from "../../atoms/processflowProgressIdAtom";
import Loader2 from "../utilities/Loader2";
import { getGeoData } from "../../jason-proof-of-concept/other/actions/getMyIp";

export default function SignUp(props: any) {
    const setPosition = useSetRecoilState<Position>(positionAtom);
    const [user, setUser] = useRecoilState<ClearERCUser>(userAtom);
    const [utm, setUtm] = useState(useParams().utm);
    const { processflowGroup: id } = useParams();
    const navigate = useNavigate();
    const [progressData, setProgressData] = useRecoilState(progressDataAtom);
    const [processflowProgressId, setProcessflowProgressId] = useRecoilState(processflowProgressIdAtom);
    const [hasLoaded, setHasLoaded] = useState(false);

    useEffect(() => {
        (async () => {
            if (!id) {
                // window.location.href = `/sign-up/9`;
                navigate(`/sign-up/9`);
                return;
            } else {
                localStorage.clear();
                setProgressData(defaultProgressState);
                setProcessflowProgressId(-1);
                setUser({
                    company: -1,
                    email: "",
                });
                await createPublicUser();
            }
            setHasLoaded(true);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        if (props.utmInQuery) {
            setUtm(window.location.search.replace("?utm=", ""));
        }
    }, [props.utmInQuery]);

    useEffect(() => {
        const saveLog = async () => {
            let geoData: Record<string, any> = {};
            try {
                // This request is sometimes blocked by ad-blockers (AdBlock, uBlock etc)
                geoData = await getGeoData();
            } catch (error) {
                console.error("Failed to get geolocation data.", error);
            }
            const utmLog: UTMLog = {
                utm: utm ?? "Unknown",
                ipAddress: geoData.IPv4,
                data: "",
                eventDatetime: new Date(),
                notes: "",
                site: "",
                userId: "",
            };
            try {
                utmLogService.create(utmLog);
            } catch (e) {
                console.error(e);
            }
            sendSlackDebug(
                `UTM hit: ${utm}\nCountry:  ${geoData.country_name}\nCity: ${geoData.city}\nState: ${geoData.state}\nIP: ${geoData.IPv4}\nURL: ${window.location}\nReferrer: ${document.referrer}`,
            );
        };
        saveLog();
    }, [utm]);

    const createPublicUser = useCallback(async () => {
        const userId = uuidv4();

        // Set userId in localStorage
        localStorage.setItem("userId", userId);

        // So we know it is not in API
        localStorage.setItem("publicUserId", userId);

        // Create the user
        const newUser = {
            id: userId,
            name: localStorage.getItem("name") ?? "",
            email: localStorage.getItem("email") ?? "",
            company: Number(localStorage.getItem("company") ?? "-1"),
            // TODO: This shouldn't be hard coded - also it doesn't exist on user
            // currentStage: Number(localStorage.getItem("currentStage") ?? 19),
            // currentStep: Number(localStorage.getItem("currentStep") ?? 32),
        };

        setUser(newUser);
        localStorage.setItem("user", JSON.stringify(newUser));
    }, [setUser]);

    const isReady = hasLoaded && user;

    return (
        <div className="w-screen flex flex-col ">
            <div className="basis-1  max-w-3xl mx-auto mb-auto rounded-xl  p-4">
                {isReady ? <WizardContainer hideStages hideSteps /> : <Loader2 />}
            </div>
        </div>
    );
}

import { useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import Swal from "sweetalert2";
import menuService from "../../services/menu.service";
import { Navigation } from "../../typings/api/navigations";
import PageDescription from "../../layout/page-description";
import PrintPre from "../../layout/print-pre";
import TableNeogen from "../../layout/table-neogen";
import Loader2 from "../utilities/Loader2";
import AddEditMenu from "./modal/add-edit-menu";

export default function MenuAdmin() {
    const [showAddEditMenu, setShowAddEditMenu] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [menu, setMenu] = useState<Navigation | undefined>();
    const cache = useQueryClient();
    const menuQuery = useQuery(["menus"], async () => {
        let response = await menuService.getAllVisibleOrdered();
        response = response.map((item) => {
            item.requiredRolesObject = JSON.parse(item.requiredRoles).roleGroups ?? [];
            return item;
        });
        return response;
    });
    if (!menuQuery.isSuccess) {
        return <Loader2 />;
    }
    return (
        <>
            <PageDescription
                title="Menus"
                description="Manage the entries in the menu"
                buttons={[
                    {
                        label: "Add Menu",
                        onClick: () => {
                            setMenu(undefined);
                            setIsEdit(false);
                            setShowAddEditMenu(true);
                        },
                        icon: "fa-plus",
                    },
                ]}
            />

            <TableNeogen
                entries={menuQuery.data ?? []}
                ignoreFields={["current", "requiredRoles"]}
                formatters={[
                    {
                        field: "icon",
                        type: "iconShort",
                    },

                    {
                        field: "requiredRolesObject",
                        type: "CustomFunction",
                        customFunction: (value: any) => {
                            console.log(value);
                            return value.map((item: any) => item).join(", ");
                        },
                    },
                ]}
                expandJSON={true}
                actions={[
                    {
                        label: "Edit",
                        icon: "fas fa-edit",
                        onClick: (entry) => {
                            setMenu(menuQuery.data?.find((i) => i.id === entry));
                            setIsEdit(true);
                            setShowAddEditMenu(true);
                        },
                    },
                    {
                        label: "Delete",
                        icon: "fas fa-trash",
                        className: "bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded",
                        onClick: (entry) => {
                            Swal.fire({
                                title: "Are you sure?",
                                text: "You won't be able to revert this!",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#3085d6",
                                confirmButtonText: "Yes, delete it!",
                            }).then(async (result) => {
                                if (result.value) {
                                    await menuService.deleteByID(entry.id);
                                    cache.invalidateQueries(["menus"]);
                                    Swal.fire({
                                        title: "Deleted!",
                                        text: "Your file has been deleted.",
                                        icon: "success",
                                        showConfirmButton: false,
                                        timer: 1500,
                                    });
                                }
                            });
                        },
                    },
                ]}
            />
            {showAddEditMenu && (
                <AddEditMenu
                    show={showAddEditMenu}
                    close={() => setShowAddEditMenu(false)}
                    isEdit={isEdit}
                    editingEntry={menu}
                />
            )}
        </>
    );
}

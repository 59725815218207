import { Currency } from "./components";
import { getTiers } from "./paymentOptions";

export const BillingSummary = ({
    estimatedAmount,
    paymentOption,
}: {
    estimatedAmount: number;
    paymentOption?: string;
}) => {
    const tiers = getTiers({ estimatedAmount });
    const tier = tiers.find((t) => t.id === paymentOption);

    const transactions: any[] = [];

    return (
        <div>
            <div className="flex flex-row space-x-1 space-y-1 content-center">
                <div />
                <div>
                    Your expected payout from the IRS:{" "}
                    <b style={{ fontSize: 22 }}>
                        <Currency amount={estimatedAmount} />
                    </b>
                </div>
            </div>
            <table className="min-w-full divide-y mt-4">
                <tbody className="">
                    <tr style={{ height: 50, borderBottom: "1px lightgray solid" }}>
                        <td className="text-gray-600">Fee structure</td>
                        <td style={{ textAlign: "right", fontWeight: "bold" }}>{tier?.name || "-"}</td>
                    </tr>
                    <tr style={{ height: 50, borderBottom: "1px lightgray solid" }}>
                        <td className="text-gray-600">Deposit</td>
                        <td style={{ textAlign: "right", fontWeight: "bold" }}>
                            {tier?.price.deposit !== undefined ? <Currency amount={tier.price.deposit || 0} /> : "-"}
                        </td>
                    </tr>
                    <tr style={{ height: 50, borderBottom: "1px lightgray solid" }}>
                        <td className="text-gray-600">Balance due on IRS completion</td>
                        <td style={{ textAlign: "right", fontWeight: "bold" }}>
                            {tier?.price.balance !== undefined ? <Currency amount={tier.price.balance || 0} /> : "-"}
                        </td>
                    </tr>
                    <tr style={{ height: 50, borderBottom: "1px lightgray solid" }}>
                        <td className="text-gray-600">Total fee</td>
                        <td style={{ textAlign: "right", fontWeight: "bold" }}>
                            {tier?.price.total !== undefined ? <Currency amount={tier.price.total || 0} /> : "-"}
                        </td>
                    </tr>
                    <tr style={{ height: 50, borderBottom: "1px lightgray solid" }}>
                        <td className="text-gray-600">Finance application</td>
                        <td style={{ textAlign: "right", fontWeight: "bold" }}>-</td>
                    </tr>
                </tbody>
            </table>
            <div style={{ marginTop: 50 }}>
                <h2 style={{ fontSize: 22 }}>Transactions</h2>
                <table className="min-w-full mt-4">
                    <thead style={{ textAlign: "left" }}>
                        <tr>
                            <th>Description</th>
                            <th>Date</th>
                            <th style={{ textAlign: "right" }}>Debit</th>
                            <th style={{ textAlign: "right" }}>Credit</th>
                            <th style={{ textAlign: "right" }}>Balance</th>
                        </tr>
                    </thead>
                    <tbody className="">
                        {transactions.length === 0 && (
                            <tr>
                                <td colSpan={5} style={{ textAlign: "center" }} className="p-5">
                                    No transactions
                                </td>
                            </tr>
                        )}
                        {transactions.length > 0 &&
                            transactions.map((transaction) => {
                                return (
                                    <tr
                                        key={transaction.id}
                                        style={{ height: 50, borderBottom: "1px lightgray solid" }}
                                    >
                                        <td>Deposit due</td>
                                        <td>01/02/2023</td>
                                        <td style={{ textAlign: "right" }}>
                                            <Currency amount={-7500} />
                                        </td>
                                        <td style={{ textAlign: "right" }}></td>
                                        <td style={{ textAlign: "right" }}>
                                            <Currency amount={-7500} />
                                        </td>
                                    </tr>
                                );
                            })}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

import { Disclosure, Transition } from "@headlessui/react";

export type CollapsibleProps = {
    linkText: string;
    children: React.ReactNode;
};

export default function Collapsible(props: CollapsibleProps) {
    return (
        <Disclosure>
            {({ open }) => (
                /* Use the `open` state to conditionally change the direction of an icon. */
                <div className="">
                    <Disclosure.Button>
                        <div className="justify-center text-sm mt-3 text-gray-500">
                            {props.linkText}
                            <span className={"h-8 fas  " + (open ? " fa-chevron-down" : "fa-chevron-right")} />
                        </div>
                    </Disclosure.Button>
                    <Transition
                        show={open}
                        enter="transition duration-100 ease-out"
                        enterFrom="transform scale-95 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition duration-75 ease-out"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-95 opacity-0"
                    >
                        <Disclosure.Panel static>
                            <div className={"h-full flex flex-col justify-center mt-3 " + (open ? "w-full" : "")}>
                                {props.children}
                            </div>
                        </Disclosure.Panel>
                    </Transition>
                </div>
            )}
        </Disclosure>
    );
}

import { useState } from "react";
import InputControlled from "../../layout/input-controlled";
import { LockClosedIcon } from "@heroicons/react/24/solid";
import logo from "../../images/logo.png";
import Loader2 from "../utilities/Loader2";
import { usePromiseLazy } from "../../jason-proof-of-concept/shared/hooks";
import { makeRequest } from "../../jason-proof-of-concept/shared/utils";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

export default function ResetPassword() {
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isComplete, setIsComplete] = useState(false);
    const { verificationToken, userId } = useParams() as {
        verificationToken: string;
        userId: string;
    };

    const action = usePromiseLazy(async () => {
        await makeRequest({
            method: "post",
            path: `/users/reset-do/${userId}/${verificationToken}`,
            data: { password },
        });
    }, []);

    const handleSubmit = async () => {
        setError("");
        if (!password) {
            setError("Please enter a password");
            return;
        }
        if (password.length < 8) {
            setError("Please enter a password of more than 8 characters");
            return;
        }
        if (password !== password2) {
            setError("Passwords do not match. Please ensure that the passwords match.");
            return;
        }
        try {
            setIsLoading(true);
            const { error } = await action.execute();
            if (!error) {
                setIsComplete(true);
            } else {
                setError(error?.message);
            }
            setIsLoading(false);
        } catch (e) {
            setError("Failed to reset your password. Please try again.");
        }
    };

    return (
        <>
            <div className="bg-slate-300 dark:bg-slate-600 h-screen m-0 p-0 min-h-screen">
                <>
                    <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-12 ">
                        <form>
                            <div className="max-w-lg md:w-[600px] space-y-10 p-14 rounded-xl  bg-indigo-800 shadow-2xl shadow-slate-700/90 border-white/80 dark:border-slate-500 border    ">
                                <div>
                                    <img className="mx-auto h-12 w-auto" src={logo} alt="Workflow" />
                                </div>

                                {isComplete ? (
                                    <div>
                                        <p style={{ color: "white", marginTop: 10, textAlign: "center" }}>
                                            Your password has been successfully reset. You can now{" "}
                                            <Link to="/login" style={{ textDecoration: "underline" }}>
                                                sign in
                                            </Link>{" "}
                                            with your new password.
                                        </p>
                                    </div>
                                ) : (
                                    <>
                                        <h1 style={{ color: "white", textAlign: "center" }}>
                                            Set a new ClearERC password
                                        </h1>
                                        <p style={{ color: "white", marginTop: 10, textAlign: "center" }}>
                                            Please enter a new password for your ClearERC account.
                                        </p>

                                        <div className="rounded-md shadow-sm -space-y-px">
                                            {error.length > 0 && <p className="text-red-500 text-sm mb-5">{error}</p>}
                                            <div>
                                                <InputControlled
                                                    darkBackground={true}
                                                    label={"New password"}
                                                    type="password"
                                                    value={password}
                                                    setValue={setPassword}
                                                    name={"password"}
                                                    noDebounce={true}
                                                />
                                            </div>
                                        </div>

                                        <div className="rounded-md shadow-sm -space-y-px">
                                            <div>
                                                <InputControlled
                                                    darkBackground={true}
                                                    label={"New password (Again)"}
                                                    type="password"
                                                    value={password2}
                                                    setValue={setPassword2}
                                                    name={"password2"}
                                                    noDebounce={true}
                                                />
                                            </div>
                                        </div>

                                        {isLoading ? (
                                            <Loader2 />
                                        ) : (
                                            <>
                                                <div>
                                                    <button
                                                        type="button"
                                                        className={
                                                            "group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md " +
                                                            "text-white bg-indigo-600 hover:bg-indigo-500 hover:text-gray-200 focus:outline-none focus:ring-2 " +
                                                            "focus:ring-offset-2 focus:ring-indigo-500 transition-all duration-500"
                                                        }
                                                        onClick={() => {
                                                            handleSubmit();
                                                        }}
                                                    >
                                                        <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                                                            {isLoading ? (
                                                                <svg
                                                                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    fill="none"
                                                                    viewBox="0 0 24 24"
                                                                >
                                                                    <circle
                                                                        className="opacity-25"
                                                                        cx="12"
                                                                        cy="12"
                                                                        r="10"
                                                                        stroke="currentColor"
                                                                        strokeWidth="4"
                                                                    ></circle>
                                                                    <path
                                                                        className="opacity-75"
                                                                        fill="currentColor"
                                                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                                    ></path>
                                                                </svg>
                                                            ) : (
                                                                <LockClosedIcon
                                                                    className="h-5 w-5  c group-hover:text-indigo-400 transition-all duration-500"
                                                                    aria-hidden="true"
                                                                />
                                                            )}
                                                        </span>
                                                        Set password
                                                    </button>
                                                </div>
                                            </>
                                        )}
                                    </>
                                )}
                                <div className="flex items-center justify-between">
                                    <div className="text-sm">
                                        <Link to="/login" className="font-medium text-indigo-200 hover:text-indigo-500">
                                            Sign in
                                        </Link>
                                    </div>
                                    <div className="text-sm">
                                        <Link
                                            to="/sign-up"
                                            className="font-medium text-indigo-200 hover:text-indigo-500"
                                        >
                                            Create a new Account
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </>
            </div>
        </>
    );
}

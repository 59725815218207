/**
 *
 *
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 8/25/20, 6:24 AM
 *
 */

import { BusinessRuleGroup } from "../typings/api/business-rule-group";
import APIService from "./api.service";

// const debug        = require('debug')('Neogen:CDRService');

class BusinessRuleService extends APIService<BusinessRuleGroup> {
    constructor() {
        super("business-rule-groups");
    }
    getAllIncludingChildren() {
        const filter = {
            include: [
                {
                    relation: "businessRules",
                },
            ],
        };
        return this.getFiltered(filter);
    }
    getByGroupIncludingChildren(groupId: number) {
        const filter = {
            where: {
                groupId: groupId,
            },
            include: [
                {
                    relation: "businessRules",
                },
            ],
        };
        return this.getFiltered(filter);
    }
    // createBusinessRuleGroupsPerProduct(businessRuleGroupsPerProduct: BusinessRuleGroupsPerProduct) {

    // getAllByProductId(productId: number) {
    //     const filter = {

    //     };
    //     return this.getFilteredWhere(filter);
    // }
}

export default new BusinessRuleService();

/* eslint-disable prettier/prettier */
import { Log } from "../typings/api";
import APIService from "./api.service";
import authService from "./auth.service";

class LogService extends APIService<Log> {
    constructor() {
        super("logs");
    }
    async getAll() {
        if (await authService.canIAccess("URL_LOGS")) {
            return this.getURL("logs");
        } else {
            return this.getURL("my-logs");
        }
    }
    async getLogins(userId: string) {
        if (await authService.canIAccess("URL_LOGS")) {
            return this.getURL(
                "logs?filter=" +
                JSON.stringify({
                    where: {
                        and: [{ userId }, { url: "/login" }],
                    },
                }),
            );
        } else {
            return this.getURL(
                "my-logs?filter=" +
                JSON.stringify({
                    where: {
                        and: [{ userId }, { url: "/login" }],
                    },
                }),
            );
        }
    }
}

export default new LogService();

import { makeRequest, parseSchema } from "../../shared/utils";
import { processflowProgressSchema } from "../domain/processflow-progress";
import { processflowProgressResponseSchema } from "./get-processflow-progresses";

export const getProcessflowProgress = async ({ authToken, id }: { authToken?: string; id: number }) => {
    const response = await makeRequest({
        method: "get",
        path: `/processflow-progresses/${id}`,
        authToken,
    });

    const processflowProgressDto = parseSchema(processflowProgressResponseSchema, response.data);

    const processflowProgress = parseSchema(processflowProgressSchema, {
        ...processflowProgressDto,
        data: processflowProgressDto.data ? JSON.parse(processflowProgressDto.data) : undefined,
    });

    return processflowProgress;
};

import { Path } from "react-hook-form";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import { useState, useEffect } from "react";
import useDebounce from "../hooks/useDebounce";
import { Label } from "./label";

type InputProps = {
    label?: Path<any>;
    required?: boolean;
    value: string;
    setValue?: (e: string) => void;
    onChange?: (e: string) => void;
    name?: string;
    fieldState?: any;
    rules?: any;
    errors?: string[];
    type?: string;
    className?: string;
    autoComplete?: string;
    description?: string;
    placeholder?: string;
    darkBackground?: boolean;
    helpDocument?: string | undefined;
    noDebounce?: boolean;
    password?: boolean;
    noMargin?: boolean;
};

export default function InputControlled({
    helpDocument,
    darkBackground,
    placeholder,
    value,
    setValue,
    onChange,
    type,
    className,
    name,
    label,
    required,
    rules,
    description,
    errors,
    noDebounce,
    password,
    noMargin,
    ...rest
}: InputProps) {
    const [text, setText] = useState(value);
    const debounced = useDebounce(text, 500);
    const [isDirty, setIsDirty] = useState(false);

    useEffect(() => {
        if (typeof setValue === "function") {
            // safe to use the function
            if (isDirty) {
                setValue(debounced);
            }
        }
        if (typeof onChange === "function") {
            // safe to use the function
            onChange(debounced);
        }

        setText(debounced);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debounced]);

    useEffect(() => {
        setText(value);
    }, [value]);

    return (
        <>
            <div className="">
                <div className={noMargin ? "m-0" : "mb-5"}>
                    {label === "" || !label ? (
                        ""
                    ) : (
                        <Label
                            text={label || ""}
                            htmlFor="email"
                            isDarkBackground={!!darkBackground}
                            helpDocument={helpDocument}
                            isRequired={!!(required && !errors)}
                        />
                    )}
                    <div className={(noMargin ? "m-0" : "mt-1 ") + "relative rounded-md"}>
                        <input
                            type={type ?? "text"}
                            {...rest}
                            value={text}
                            onChange={(e) => {
                                setIsDirty(true);
                                if (noDebounce) {
                                    if (typeof setValue === "function") {
                                        // safe to use the function
                                        setValue(debounced);
                                    }
                                    if (typeof onChange === "function") {
                                        // safe to use the function
                                        onChange(debounced);
                                    }
                                    setText(e.target.value);
                                } else {
                                    setText(e.target.value);
                                }
                            }}
                            className={
                                className +
                                " dark:bg-gray-800  " +
                                (errors
                                    ? "block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                                    : "shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md dark:bg-gray-900  dark:border-gray-900 dark:text-gray-300")
                            }
                            placeholder={placeholder ?? ""}
                        />
                        {description && (
                            <p id="comments-description" className="text-gray-500 tracking-wider text-xs">
                                {description}
                            </p>
                        )}
                        {(errors ?? []).length > 0 && (
                            <div
                                className={
                                    "absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none " +
                                    (description ? "-mt-6 " : "")
                                }
                            >
                                <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

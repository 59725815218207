import { makeRequest } from "../../shared/utils";
import { Sms } from "../domain/sms";

export const sendSms = async ({ authToken, data }: { authToken: string; data: Sms }) => {
    try {
        const response = await makeRequest({
            method: "post",
            path: "/send-sms",
            authToken,
            data,
        });
        console.error("send-sms response", response);
    } catch (error) {
        console.error("send-sms error", error);
    }
};

import { DefaultSources } from "../typings/api/default-sources";
import APIService from "./api.service";

class DefaultSourceService extends APIService<DefaultSources> {
    constructor() {
        super("default-sources");
    }
}

export default new DefaultSourceService();

/**
 *
 *
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 8/24/20, 10:40 AM
 *
 */

import axios, { AxiosResponse } from "axios";
import authHeader from "./auth-header";
import APIService from "./api.service";
import AuthService from "./auth.service";
import { User, NeogenUserSettings } from "../typings/api";

const API_URL = process.env.REACT_APP_API_URL;

class UserService extends APIService<User> {
    constructor() {
        super("users");
    }

    async getPublicContent() {
        return await axios.get(API_URL + "all");
    }

    async getUserBoard() {
        return await axios.get(API_URL + "questions", { headers: authHeader() });
    }

    async getModeratorBoard() {
        return await axios.get(API_URL + "mod", { headers: authHeader() });
    }

    async getAdminBoard() {
        return await axios.get(API_URL + "admin", { headers: authHeader() });
    }

    async getSettingsByUserID(id: string): Promise<void | AxiosResponse<NeogenUserSettings[]>> {
        const filter = {
            where: {
                userId: id,
            },
        };
        return this.getURL("/user-settings?filter=" + encodeURIComponent(JSON.stringify(filter)));
    }

    async getSettingsByAllUserID(): Promise<void | AxiosResponse<NeogenUserSettings[]>> {
        return this.getURL("/user-settings");
    }

    async getAll(): Promise<void | AxiosResponse<User[]>> {
        if (await AuthService.canIAccess("LIST_ALL_USERS")) {
            return super.getAll();
        } else {
            // if the user doesn't have access to all phone numbers they should see a subset

            return super.getURL("my-users");

            // return [];
        }
    }
}

export default new UserService();

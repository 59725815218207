import ModalDialog from "../../../../layout/modal-dialog";
import Loader2 from "../../../utilities/Loader2";

function DeleteUserModal(props: DeleteUserModalProps) {
    return (
        <ModalDialog
            show={props.show}
            close={props.handleClose}
            title="Delete User"
            okAction={() => {
                props.deleteUser();
            }}
            okText="Delete User"
        >
            {props.deleting ? (
                <div className={"p-5"}>
                    <Loader2 />
                </div>
            ) : (
                <>
                    <p>Are you sure you want to delete this user?</p>
                    <br />
                    <p>
                        Name: {props.userBeingDeleted.name}
                        <br />
                        Email: {props.userBeingDeleted.email}
                        <br />
                    </p>
                </>
            )}
        </ModalDialog>
    );
}

export default DeleteUserModal;

type DeleteUserModalProps = {
    deleteUser: any;
    deleting: any;
    handleClose: any;
    show: any;
    userBeingDeleted: any;
};

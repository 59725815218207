/**
 *
 *
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 8/25/20, 6:24 AM
 *
 */

import APIService from "./api.service";
import { ProcessFlowGroupRoleGroup } from "../typings/api/processflow-group-role-group";

class ProcessFlowGroupRoleGroupService extends APIService<ProcessFlowGroupRoleGroup> {
    constructor() {
        super("processflow-group-role-groups");
    }
}

export default new ProcessFlowGroupRoleGroupService();

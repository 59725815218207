import { useEffect, useState } from "react";
import Main from "../../../layout/Main";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import RoleService from "../../../services/role.service";
import AddRoleModal from "./modals/add-role-modal";
import AuthService from "../../../services/auth.service";
import Loader2 from "../../utilities/Loader2";
import { NeogenRoles } from "../../../typings/api";
import PageDescription from "../../../layout/page-description";
import TableNeogen from "../../../layout/table-neogen";
import Swal from "sweetalert2";

// const debug = require("debug")("NeoGen:Roles");

export default function Roles() {
    const [showAddRoles, setShowAddRoles] = useState(false);
    const queryCache = useQueryClient();
    const [canIAccess, setCanIAccess] = useState(false);

    useEffect(() => {
        AuthService.canIAccess("ADD_ROLES").then((r) => {
            setCanIAccess(r);
        });
    }, []);

    const rolesQuery = useQuery(["roles"], async () => {
        const response = await RoleService.getAll();
        if (response) {
            return response.data;
        }
    });

    function closeAddRole() {
        setShowAddRoles(false);
    }

    function invalidateCache() {
        queryCache.invalidateQueries(["roles"]).then((r) => {
            // debug(r);
        });
    }

    return (
        <>
            <PageDescription
                title="Roles"
                description={
                    "Roles are used to define the permissions that a user has. You can create as many roles as you need." +
                    " Each role can have different permissions. For example, you can create a role for a specific type of user. "
                }
                buttons={[
                    {
                        label: canIAccess ? "Create Role" : undefined,
                        onClick: () => setShowAddRoles(true),
                        icon: canIAccess ? "fal fa-plus" : undefined,
                    },
                ]}
            />
            <Main>
                <div className=" rounded-xl  ">
                    <div className="card-body p-0">
                        {rolesQuery.isLoading ? (
                            <div className={"mt-5 pt-5 mb-5 pb-5 text-center"}>
                                <Loader2 />
                            </div>
                        ) : (
                            <TableNeogen
                                entries={rolesQuery.data ?? []}
                                actions={[
                                    {
                                        icon: "fal fa-edit",
                                        label: "Edit",
                                        onClick: (entry: NeogenRoles) => {
                                            // setShowAddRoles(true);
                                        },
                                    },
                                    {
                                        icon: "fal fa-trash-alt",
                                        label: "Delete",
                                        className: "bg-red-600 text-white",
                                        onClick: (entry: number) => {
                                            Swal.fire({
                                                title: "Are you sure?",
                                                text: "You won't be able to revert this!",
                                                icon: "warning",
                                                showCancelButton: true,
                                                confirmButtonText: "Yes, delete it!",
                                                confirmButtonColor: "#d33",
                                            }).then((result) => {
                                                if (result.value) {
                                                    RoleService.deleteByID(entry)
                                                        .then((r) => {
                                                            invalidateCache();
                                                            Swal.fire({
                                                                title: "Deleted!",
                                                                icon: "success",
                                                                showConfirmButton: false,
                                                                timer: 800,
                                                                text: "Your role has been deleted.",
                                                            });
                                                        })
                                                        .catch((e) => {
                                                            console.error(e);
                                                        });
                                                }
                                            });
                                        },
                                    },
                                ]}
                            />
                            // <table className={"table"}>
                            //     <thead>
                            //         <tr>
                            //             <th className={"text-left"}>Role Name</th>
                            //             <th className={"text-left"}>Role Code</th>
                            //             <th className={"text-left"}>Delete</th>
                            //         </tr>
                            //     </thead>
                            //     <tbody>
                            //         {rolesQuery.data?.map((row:NeogenRoles, idx:number) => {
                            //             return (
                            //                 <tr key={idx}>
                            //                     <td className={"text-left"}>
                            //                         <button className={"bg-transparent hover:bg-indigo-500 text-indigo-500  hover:text-white py-2 px-4 border border-indigo-300 hover:border-transparent rounded-lg"}><i
                            //                             className={"fa fa-pencil mr-3"}/> {row.name}</button>
                            //                     </td>
                            //                     <td className={"text-left"}>{row.roleCode}</td>
                            //                     <td className={"text-left"}>
                            //                         <button className={"bg-transparent hover:bg-red-500 text-red-500  hover:text-white py-2 px-4 border border-red-300 hover:border-transparent rounded-lg"}><i
                            //                             className={"fa fa-trash mr-3"}/> Delete Role
                            //                         </button>
                            //                     </td>
                            //                 </tr>
                            //             );
                            //         })}
                            //     </tbody>
                            // </table>
                        )}
                    </div>
                </div>
            </Main>
            <AddRoleModal
                RoleService={RoleService}
                handleClose={closeAddRole}
                loadData={invalidateCache}
                show={showAddRoles}
            />
        </>
    );
}

import { z } from "zod";
import { makeRequest, parseSchema } from "../../shared/utils";
import { userSchema } from "../domain/user";
import { userResponseSchema } from "./get-users";

export const authAsUser = async ({ authToken, id }: { authToken: string; id: string }) => {
    const response = await makeRequest({
        method: "post",
        path: `/users/${id}/auth`,
        authToken,
    });

    const data = parseSchema(z.object({ user: userResponseSchema, token: z.string() }), response.data);

    const user = parseSchema(userSchema, {
        ...data.user,
        company: data.user.company || undefined,
        name: data.user.name || undefined,
    });

    return { user, token: data.token };
};

import { atom, DefaultValue } from "recoil";
import { ProcessFlow } from "../typings/api/processflow";
import { ProcessFlowProgressData } from "../typings/api/processflow-progress-data";
import { ProcessFlowStage } from "../typings/api/processflow-stage";
import { localStorageEffect } from "./local-storage-effect";

const pfDebugAtom = atom<boolean>({
    key: "pfDebugAtom",
    default: false,
    effects: [localStorageEffect("pfDebugAtom")],
});

export default pfDebugAtom;

import { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router";
import Swal from "sweetalert2";
import processflowStageService from "../../../services/processflow-stage.service";
import { ProcessFlowStage } from "../../../typings/api/processflow-stage";
import InputControlled from "../../../layout/input-controlled";
import ModalDialog from "../../../layout/modal-dialog";
import TextAreaNeoGen from "../../../layout/text-area-neogen";
import TextAreaNeoGenControlled from "../../../layout/text-area-controlled";
import Loader2 from "../../utilities/Loader2";
import SelectNeoGen from "../../../layout/select-neogen";

export function AddEditStage(props: AddStageProps) {
    const [name, setName] = useState<string>("");
    const [order, setOrder] = useState<string>("");
    const [ruleGroupsAreOr, setRuleGroupsAreOr] = useState(0);
    const [description, setDescription] = useState<string>("");
    const cache = useQueryClient();
    const { processflowGroup } = useParams();
    const stageQuery = useQuery(["processflow-stages", "getAll"], async () => {
        const response = await processflowStageService.getAll();
        if (response) {
            return response.data;
        }
    });
    useEffect(() => {
        if (props.isEdit && stageQuery.isSuccess) {
            const stage = stageQuery.data?.find((stage: any) => stage.id === props.id);
            if (stage) {
                setName(stage.name);
                setOrder(stage.order.toString());
                setDescription(stage.description);
            }
        }
    }, [props.id, props.isEdit, stageQuery.data, stageQuery.isSuccess]);
    async function onSubmit() {
        console.log("submit");
        const fields: ProcessFlowStage = {
            name,
            order: Number(order),
            description,
            group: Number(processflowGroup),
            ruleGroupsAreOr,
            isPublic: 0,
        };
        let response;
        if (props.isEdit) {
            response = await processflowStageService.update(props.id, fields);
        } else {
            response = await processflowStageService.create(fields);
        }

        console.log(response);
        if (response) {
            props.close();
            Swal.fire({
                title: "Success",
                text: !props.isEdit ? "Stage added successfully" : "Stage updated successfully",
                icon: "success",
                showConfirmButton: false,
                timer: 1500,
            }).then(() => {
                cache.invalidateQueries(["processflow-stages"]);
            });
        } else {
            Swal.fire({
                title: "Error",
                text: !props.isEdit ? "Error adding stage" : "Error updating stage",
                icon: "error",
                confirmButtonText: "OK",
            });
        }
    }
    return (
        <>
            <ModalDialog
                title={props.isEdit ? "Edit Stage" : "Add Stage"}
                show={props.show}
                close={props.close}
                size="md"
                okAction={onSubmit}
                okText={props.isEdit ? "Save Stage" : "Add Stage"}
            >
                {stageQuery.isLoading ? (
                    <Loader2 />
                ) : (
                    <>
                        <InputControlled label="Stage Name" name="name" value={name} setValue={setName} />
                        <TextAreaNeoGenControlled
                            label="Description"
                            name="description"
                            value={description}
                            setValue={setDescription}
                        />
                        {/* <!-- Yes/No select box for ruleGroupsAreOr --> */}
                        <SelectNeoGen
                            label="Rule Groups Are Or"
                            // name="ruleGroupsAreOr"
                            value={ruleGroupsAreOr}
                            onChange={(i: number | string) => setRuleGroupsAreOr(Number(i))}
                            options={[
                                { id: 0, name: "No" },
                                { id: 1, name: "Yes" },
                            ]}
                        />

                        <InputControlled label="Order" name="order" value={order} setValue={setOrder} />
                    </>
                )}
            </ModalDialog>
        </>
    );
}

type AddStageProps = {
    show: boolean;
    close: () => void;
    isEdit: boolean;
    id: number;
};

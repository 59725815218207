import { useNavigate } from "react-router-dom";

export default function RecordingButton(props: RecordingButtonProps) {
    const navigate = useNavigate();
    return (
        <div
            className="text-gray-700 border p-5 bg-white hover:bg-gray-100 dark:bg-gray-800 dark:hover:bg-gray-800 border-gray-300 dark:border-gray-600 cursor-pointer text-center h-80 m-0 rounded-xl flex shadow-lg dark:shadow-sm "
            onClick={() => {
                if (props.link) {
                    navigate(props.link);
                }
                if (props.action) {
                    props.action();
                }
            }}
        >
            <div className="card-body align-items-center d-flex justify-content-center flex-column my-auto text-center mx-auto ">
                <div className={"flex-grow"}>
                    <div className="mb-3 my-auto text-3xl">
                        <i className={"fa-thin " + props.icon + " fa-2x"} />
                    </div>
                </div>
                <div className=" text-3xl font-light mt-5">{props.title}</div>
            </div>
        </div>
    );
}

type RecordingButtonProps = {
    title: string;
    link?: string;
    action?: () => void;
    icon: string;
};

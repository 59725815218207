/**
 *
 *
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 8/24/20, 10:39 AM
 *
 */

export default function authHeader() {
    const userString = localStorage.getItem("user");
    let user = JSON.parse(userString ?? "{}");
    if (!user.token) {
        user = {
            token: "",
        };
    }
    return { Authorization: "Bearer " + user.token };
}

export const getAuthToken = () => {
    const token = JSON.parse(localStorage.getItem("user") ?? "{}").token as string | undefined;

    if (!token) {
        throw new Error("Auth token not found");
    }

    return token;
};

export const getAuthTokenNoThrow = () => {
    const token = JSON.parse(localStorage.getItem("user") ?? "{}").token as string | undefined;
    return token;
};

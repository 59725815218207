import { useQuery } from "@tanstack/react-query";
import ModalDialog from "../../../layout/modal-dialog";
import PrintPre from "../../../layout/print-pre";
import interactionsService from "../../../services/interactions.service";
import Loader2 from "../../utilities/Loader2";

export default function ShowMessage({
    id,
    show,
    setShow,
}: {
    id: number;
    show: boolean;
    setShow: (show: boolean) => void;
}) {
    const messageQuery = useQuery(["messages", id], async () => {
        const response = await interactionsService.getOne(id);
        if (response) {
            return response.data;
        }
    });
    const messagesQuery = useQuery(["messages"], async () => {
        const response = await interactionsService.getAll();
        if (response) {
            return response.data;
        }
    });
    if (messageQuery.isLoading || messagesQuery.isLoading) {
        return <Loader2 />;
    }

    return (
        <>
            <ModalDialog
                size="md"
                title="Message"
                show={show}
                close={() => {
                    const updatedMessage = messageQuery.data;
                    if (updatedMessage) {
                        updatedMessage.read = 1;
                        updatedMessage.summary = "Read";
                        updatedMessage.activity = updatedMessage.activity || "";
                        updatedMessage.dateCreated = updatedMessage.dateCreated || new Date();
                        interactionsService.update(updatedMessage?.id, updatedMessage).then((response) => {
                            console.log("Updated message", response);
                            messagesQuery.refetch();
                        });
                    }
                    setShow(false);
                }}
                showOk={false}
                cancelText="Close"
            >
                <p>{messageQuery.data?.activity}</p>
            </ModalDialog>
        </>
    );
}

import { atom } from "recoil";
import { localStorageEffect } from "./local-storage-effect";

const groupAtom = atom({
    key: "groupAtom",
    default: -1,
    effects: [localStorageEffect("groupAtom")],
});

export default groupAtom;

/**
 *
 *
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 9/19/20, 10:58 AM
 *
 */

import { AxiosResponse } from "axios";
import { UTMLink } from "../typings/api/utm-links";
import APIService from "./api.service";
import AuthService from "./auth.service";

class UTMLinkService extends APIService<UTMLink> {
    constructor() {
        super("utm-links");
    }
    async getAll(): Promise<void | AxiosResponse<UTMLink[]>> {
        if (await AuthService.canIAccess("ADMIN_DASHBOARD")) {
            return super.getAll();
        } else {
            // if the user doesn't have access to all phone numbers they should see a subset
            // alert("sub");
            return super.getURL("my-utm-links");

            // return [];
        }
    }
}

export default new UTMLinkService();

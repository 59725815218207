import { useState } from "react";
import { User } from "../../../../typings/api";
import InputControlled from "../../../../layout/input-controlled";
import ModalDialog from "../../../../layout/modal-dialog";
import PrintPre from "../../../../layout/print-pre";
import AdminUserService from "../../../../services/users.service";
import { uuid4 } from "@sentry/utils";
import _ from "lodash";
import { useQueryClient } from "@tanstack/react-query";
import Swal from "sweetalert2";
import { ErcUser } from "../../../../typings/api/erc-user";

export default function ResetPassword(props: ResetPasswordProps) {
    const [password1, setPassword1] = useState("");
    const [password2, setPassword2] = useState("");
    const queryCache = useQueryClient();
    if (!props.user) {
        return <></>;
    }

    async function saveUser() {
        if (password1 !== password2) {
            Swal.fire("Error", "Passwords do not match", "error");
            return;
        }
        if (password1.length < 8) {
            Swal.fire("Error", "Password is not 8 characters or more", "error");
            return;
        }
        if (props.user === null) {
            return;
        }
        props.user.stPass = props.user.stPass ?? "";
        props.user.stUrl = props.user.stUrl ?? "";
        props.user.stCompany = props.user.stCompany ?? -1;
        if (props.user.id && props.user.verificationToken) {
            const pwd = await AdminUserService.resetPasswordComplete(
                props.user.id,
                props.user.verificationToken,
                password1,
            );
            const r = await queryCache.invalidateQueries(["users"]);
            Swal.fire("Success", "Password reset.", "success");
            props.close();
        } else if (props.user.id) {
            // We have a user but they need a verification token
            const user = _.cloneDeep(props.user);
            user.verificationToken = uuid4();
            props.user.verificationToken = user.verificationToken;
            delete user.lastLoggedIn;
            delete user.password;
            delete user.emailVerified;
            // delete (user.verificationToken);
            delete user.realm;
            const patch = await AdminUserService.patchURL("users/" + user.id, user).catch((e) => {
                console.log("PATCH: ", e.response);
            });
            const pwd = await AdminUserService.resetPasswordComplete(props.user.id, user.verificationToken, password1);
            console.log({ patch, pwd });
            const r = await queryCache.invalidateQueries(["users"]);
            Swal.fire("Success", "Password reset.", "success");
            props.close();
        }
    }

    return (
        <>
            <ModalDialog title="Reset Password" close={props.close} show={props.show} okAction={saveUser}>
                <InputControlled
                    label="New Password"
                    type="password"
                    value={password1}
                    setValue={setPassword1}
                    name="password1"
                />
                <InputControlled
                    label="New Password Again"
                    type="password"
                    value={password2}
                    setValue={setPassword2}
                    name="password2"
                />
            </ModalDialog>
        </>
    );
}

type ResetPasswordProps = {
    show: boolean;
    close: () => void;
    user: ErcUser | null;
};

import ModalDialog from "../../../layout/modal-dialog";
import { useForm } from "react-hook-form";
import usersService from "../../../services/users.service";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import PrintPre from "../../../layout/print-pre";
import { useState } from "react";
import { Company, User } from "../../../typings/api";
import { ErcUser } from "../../../typings/api/erc-user";
import companyService from "../../../services/company.service";
import { Clients } from "../../../typings/api/clients";
import { PayrollContact } from "../../../typings/api/payroll-contact";
import payrollContactService from "../../../services/payroll-contact.service";
import clientsService from "../../../services/clients.service";
import Swal from "sweetalert2";
import AddClientWizardTop from "./add-client-wizard-top";
import { PrevNextTab } from "./prev-next-tab";
import { AddCompanySection } from "./add-company-section";
import { AddClientSection } from "./add-client-section";
import { AddUsersSection } from "./add-users-section";
import { AddingCompanyButtons } from "./adding-company-buttons";

let steps = [
    { id: 1, name: "Create Companies", description: "Any they will submit for approval", href: "#", status: "current" },
    { id: 2, name: "Create User Logins", description: "With access to said companies", href: "#", status: "upcoming" },
    {
        id: 3,
        name: "Start Application",
        description: "Invite the clients to get started",
        href: "#",
        status: "upcoming",
    },
];

export default function AddEditClientModal(props: AddEditClientModalProps) {
    const [companies, setCompanies] = useState([]);
    const [addingCompany, setAddingCompany] = useState(false);
    const [accountManager, setAccountManager] = useState("");
    const [salesUser, setSalesUser] = useState("");
    const [companyCreatedBy, setCompanyCreatedBy] = useState(-1);
    const [currentStep, setCurrentStep] = useState(0);
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();
    const queryCache = useQueryClient();
    const onSubmit = async (data: any) => {
        console.log(data);
        const payrollContact: PayrollContact = {
            name: data.payrollContactName,
            email: data.payrollContactEmail,
            phone: data.payrollContactPhone,
        };
        const payrollResponse = await payrollContactService.create(payrollContact);
        if (payrollResponse) {
            console.log(payrollResponse);
            const payrollContactId = payrollResponse.data.id;
            const newClient: Clients = {
                name: data.name,
                salesUserId: salesUser,
                accountManagerId: accountManager,
                companyCreatedId: companyCreatedBy,
                phone: data.phone,
                email: data.email,
                dateCreated: new Date(),
                lastUpdated: new Date(),
                active: 1,
                payrollContactId: payrollContactId,
            };
            const response = await clientsService.create(newClient);
            if (response) {
                console.log(response);
                Swal.fire({
                    title: "Success",
                    text: "Application created successfully",
                    icon: "success",
                    showConfirmButton: false,
                    timer: 2000,
                }).then(() => {
                    queryCache.invalidateQueries(["Clients"]);
                    // props.close();
                });
            } else {
                Swal.fire({
                    title: "Error",
                    text: "Error creating client",
                    icon: "error",
                    showConfirmButton: false,
                    timer: 2000,
                });
            }
        }
    };

    const compareUser = (a: ErcUser, b: ErcUser) => {
        // console.log({ a, b });
        const aCompare = a.name?.toLocaleLowerCase() || a.email.toLowerCase();
        const bCompare = b.name?.toLocaleLowerCase() || b.email.toLowerCase();
        // console.log({ aCompare, bCompare });

        if (aCompare < bCompare) {
            return -1;
        }
        if (aCompare > bCompare) {
            return 1;
        }
        return 0;
    };
    const compareCompany = (a: any, b: any) => {
        if (a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase()) {
            return -1;
        }
        if (a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase()) {
            return 1;
        }
        return 0;
    };

    console.log(watch("example")); // watch input value by passing the name of it

    const usersQuery = useQuery(["Users"], async () => {
        const response = await usersService.getAll();
        if (response) {
            // console.error(response);
            return response.data.sort(compareUser).map((user) => {
                return {
                    id: user.id,
                    name: !user.name ? user.email : user.name + " (" + user.email + ")",
                };
            });
        }
    });

    const companyQuery = useQuery(["Company"], async () => {
        const response = await companyService.getAll();
        if (response) {
            // console.error(response);
            return response.data.sort(compareCompany);
        }
    });

    function previousTab() {
        // props.setTab(props.tab - 1);
        setCurrentStep(currentStep - 1);
        steps = steps.map((step) => {
            if (step.id === currentStep) {
                step.status = "upcoming";
            } else if (step.id === currentStep + 1) {
                step.status = "current";
            } else if (step.id === currentStep - 1) {
                step.status = "past";
            }
            return step;
        });
    }
    function nextTab() {
        // props.setTab(props.tab + 1);
        setCurrentStep(currentStep + 1);
        steps = steps.map((step) => {
            if (step.id === currentStep) {
                step.status = "current";
            } else if (step.id < currentStep) {
                step.status = "visited";
            } else {
                step.status = "upcoming";
            }
            return step;
        });
    }

    if (!props.show) {
        return null;
    }

    return (
        <ModalDialog
            close={props.close}
            show={props.show}
            showOk={false}
            showCancel={false}
            title={""}
            // title={props.isEdit ? "Edit Client" : "Add Client"}
            size="md"
            noPadding={true}
            okAction={handleSubmit(onSubmit)}
        >
            <AddClientWizardTop
                steps={steps}
                onStepClick={(step) => {
                    console.log(step);
                }}
            />
            {steps.find((step) => step.status === "current")?.id === 1 && (
                <AddCompanySection
                    accountManager={accountManager}
                    setAccountManager={setAccountManager}
                    salesUser={salesUser}
                    setSalesUser={setSalesUser}
                    companyCreatedBy={companyCreatedBy}
                    setCompanyCreatedBy={setCompanyCreatedBy}
                    register={register}
                    usersQuery={usersQuery}
                    data={companyQuery.data}
                    setAddingCompany={setAddingCompany}
                    addingCompany={addingCompany}
                    companies={companies}
                />
            )}
            {steps.find((step) => step.status === "current")?.id === 2 && (
                <AddClientSection
                    accountManager={accountManager}
                    setAccountManager={setAccountManager}
                    salesUser={salesUser}
                    setSalesUser={setSalesUser}
                    companyCreatedBy={companyCreatedBy}
                    setCompanyCreatedBy={setCompanyCreatedBy}
                    register={register}
                    usersQuery={usersQuery}
                    data={companyQuery.data}
                ></AddClientSection>
            )}
            {steps.find((step) => step.status === "current")?.id === 3 && (
                <AddUsersSection
                    accountManager={accountManager}
                    setAccountManager={setAccountManager}
                    salesUser={salesUser}
                    setSalesUser={setSalesUser}
                    companyCreatedBy={companyCreatedBy}
                    setCompanyCreatedBy={setCompanyCreatedBy}
                    register={register}
                    usersQuery={usersQuery}
                    data={companyQuery.data}
                ></AddUsersSection>
            )}
            {addingCompany && (
                <AddingCompanyButtons
                    steps={steps}
                    currentStep={currentStep}
                    previousTab={previousTab}
                    nextTab={nextTab}
                    saveAction={handleSubmit(onSubmit)}
                    setAddingCompany={setAddingCompany}
                />
            )}
            {!addingCompany && companies.length > 0 && (
                <PrevNextTab
                    steps={steps}
                    currentStep={currentStep}
                    previousTab={previousTab}
                    nextTab={nextTab}
                    saveAction={handleSubmit(onSubmit)}
                />
            )}
        </ModalDialog>
    );
}

type AddEditClientModalProps = {
    show: boolean;
    close: () => void;
    isEdit: boolean;
};

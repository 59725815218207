import { CheckIcon } from "@heroicons/react/24/outline";
import { useContext } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import processflowStageService from "../../../services/processflow-stage.service";
import UserContext from "../../../services/user-context";
import PrintPre from "../../../layout/print-pre";
import { useRecoilState } from "recoil";
import positionAtom, { Position } from "../../../atoms/positionAtom";
import useNavigateProcess from "../hooks/use-navigate-process";

/*
const [currentStage, setCurrentStage] = useRecoilState<number>(stageAtom);
    const [currentStep, setCurrentStep] = useRecoilState<ProcessFlow>(stepAtom);
   */

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
}

// TODO Change Stage to use steps from layout

export default function Stage({ processFlowGroupId, isPublic }: StageProps) {
    const [user] = useContext(UserContext);
    // const [progressData, setProgressData] = useState<ProcessFlowProgressData>();
    // const [internalCurrentStage, setInternalCurrentStage] = useStateCallback<number>(-1);

    const cache = useQueryClient();
    const [position, setPosition] = useRecoilState<Position>(positionAtom);
    // const [currentStep, setCurrentStep] = useRecoilState<ProcessFlow>(stepAtom);
    // const {getProgress} = useCurrentProgress(processFlowGroupId, isPublic??false);
    const { setStageId: setStage } = useNavigateProcess(processFlowGroupId);

    /**
     * A custom hook that returns the process flow stages for the given process flow group id.
     * @param {number} id - the id of the process flow
     * @returns {ProcessFlowStage[]} - the process flow stages for the given process flow id.
     */
    const stageQuery = useQuery(["processflow-stages", "getAllByGroupId", processFlowGroupId], async () => {
        const response = await processflowStageService.getAllByGroupIdIncludingRuleGroups(Number(processFlowGroupId));
        if (response) {
            // alert(processFlowGroupId);
            console.error({ stages: response.data?.length });
            return response.data;
        }
    });

    /**
     * If the stage query returns less than or equal to one result, hide it
     * @param {StageQuery} stageQuery - The stage query object.
     * @returns None
     */
    if ((stageQuery.data ?? []).length <= 1) {
        return <></>;
    }

    /**
     *
     */
    if (!user.id) {
        return <></>;
    }

    return (
        <div className="md:col-span-1 sm:block">
            {/* {user.id === "4bb81589-541b-4985-b9f4-1f44cf1d8748" && (
                <PrintPre>
                    {currentStage}



                </PrintPre>
            )} */}
            {/* <PrintPre>
                {position}



            </PrintPre> */}

            <div className="px-4 py-5 bg-white dark:bg-gray-800 space-y-6 sm:p-6 rounded-xl shadow">
                <p className="text-xl tracking-wider text-gray-600 sm:text-xl dark:text-gray-400 mb-3">Sections</p>
                <nav aria-label="Progress">
                    <ol role="list" className="overflow-hidden">
                        {stageQuery.data?.map((stage, stepIdx) => (
                            <li
                                key={stage.name}
                                className={classNames(
                                    stepIdx !== (stageQuery.data ?? []).length - 1 ? "pb-10" : "",
                                    "relative",
                                )}
                            >
                                {stage.status === "complete" ? (
                                    <>
                                        {stepIdx !== (stageQuery.data ?? []).length - 1 ? (
                                            <div
                                                className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-indigo-600"
                                                aria-hidden="true"
                                            />
                                        ) : null}
                                        <button
                                            onClick={() => {
                                                console.log("setting stage to " + stepIdx + " or " + stage.id);
                                                setStage(stage.id);
                                            }}
                                            className="relative flex items-start group"
                                        >
                                            <span className="h-9 flex items-center">
                                                <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-indigo-600 rounded-full group-hover:bg-indigo-800">
                                                    <CheckIcon className="w-5 h-5 text-white" aria-hidden="true" />
                                                </span>
                                            </span>
                                            <span className="ml-4 min-w-0 flex flex-col">
                                                <span className="text-xs text-left font-semibold tracking-wide uppercase dark:text-gray-300">
                                                    {stage.name}
                                                </span>
                                                <span className="text-sm text-gray-500 dark:text-gray-400">
                                                    {stage.description}
                                                </span>
                                            </span>
                                        </button>
                                    </>
                                ) : stage.id === position.stageId ? (
                                    <>
                                        {/* <PrintPre>{stage}</PrintPre> */}
                                        {stepIdx !== (stageQuery.data ?? []).length - 1 ? (
                                            <div
                                                className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300 dark:bg-gray-600"
                                                aria-hidden="true"
                                            />
                                        ) : null}
                                        <button
                                            onClick={() => {
                                                setStage(stage.id);
                                            }}
                                            className="relative flex items-start group"
                                        >
                                            <span className="h-9 flex items-left" aria-hidden="true">
                                                <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-white dark:bg-gray-900 border-2 border-indigo-600 rounded-full">
                                                    <span className="h-2.5 w-2.5 bg-indigo-600 rounded-full" />
                                                </span>
                                            </span>
                                            <span className="ml-4 min-w-0 flex flex-col">
                                                <span className="text-xs text-left font-semibold tracking-wide uppercase text-indigo-600 dark:text-green-400">
                                                    {stage.name}
                                                </span>
                                                <span className="text-sm text-left text-gray-500 dark:text-gray-400">
                                                    {stage.description}
                                                </span>
                                            </span>
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        {stepIdx !== (stageQuery.data ?? []).length - 1 ? (
                                            <div
                                                className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300 dark:bg-gray-600"
                                                aria-hidden="true"
                                            />
                                        ) : null}
                                        <button
                                            onClick={() => {
                                                // setCurrentStage(stage.id ?? -1);
                                                setStage(stage.id);
                                            }}
                                            className="relative flex items-start group"
                                        >
                                            <span className="h-9 flex items-left" aria-hidden="true">
                                                <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-white dark:bg-gray-900 dark:border-gray-700 border-2 border-gray-300 rounded-full group-hover:border-gray-400">
                                                    <span className="h-2.5 w-2.5 bg-transparent rounded-full group-hover:bg-gray-300" />
                                                </span>
                                            </span>
                                            <span className="ml-4 min-w-0 flex flex-col">
                                                <span className="text-xs text-left font-semibold tracking-wide uppercase text-gray-500 dark:text-gray-300">
                                                    {stage.name}
                                                </span>
                                                <span className="text-sm text-left text-gray-500 dark:text-gray-400">
                                                    {stage.description}
                                                </span>
                                            </span>
                                        </button>
                                    </>
                                )}
                            </li>
                        ))}
                    </ol>
                </nav>
            </div>
        </div>
    );
}

type StageProps = {
    // client: number,
    processFlowGroupId: number;
    isPublic?: boolean;
};

import { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router";
import Swal from "sweetalert2";
import businessRuleTypeService from "../../../services/business-rule-type.service";
import businessRuleService from "../../../services/business-rule.service";
import comparisonTypeService from "../../../services/comparison-type.service";
import { BusinessRuleGroup } from "../../../typings/api/business-rule-group";
import { BusinessRuleType } from "../../../typings/api/business-rule-type";
import InputControlled from "../../../layout/input-controlled";
import ModalDialog from "../../../layout/modal-dialog";
import PrintPre from "../../../layout/print-pre";
import SelectNeoGen from "../../../layout/select-neogen";
import TextAreaNeoGenControlled from "../../../layout/text-area-controlled";

export default function AddEditRuleType(props: AddEditRuleType) {
    const [name, setName] = useState(props.ruleType?.name ?? "");
    const [description, setDescription] = useState(props.ruleType?.description ?? "");
    const [error, setError] = useState("");
    const cache = useQueryClient();

    async function save() {
        if (props.ruleType) {
            await businessRuleTypeService.update(props.ruleType.id, { name, description });
            Swal.fire({
                title: "Success",
                text: "Rule type updated successfully",
                icon: "success",
                showConfirmButton: false,
                timer: 1500,
            });
            cache.invalidateQueries(["business-rule-types"]);
            await cache.invalidateQueries(["rule-types"]);
            await cache.invalidateQueries(["business-products"]);
            await cache.invalidateQueries(["business-rule-types"]);
            await cache.invalidateQueries(["business-rule-types2"]);
            props.close();
        } else {
            const response = await businessRuleTypeService.create({ name, description });
            if (response?.data.id) {
                Swal.fire({
                    title: "Success",
                    text: "Rule type created successfully",
                    icon: "success",
                    showConfirmButton: false,
                    timer: 1500,
                });
                cache.invalidateQueries(["business-rule-types"]);
                await cache.invalidateQueries(["rule-types"]);
                await cache.invalidateQueries(["business-products"]);
                await cache.invalidateQueries(["business-rule-types"]);
                await cache.invalidateQueries(["business-rule-types2"]);
                props.close();
            } else {
                console.error(response?.data);
                Swal.fire({
                    title: "Error",
                    text: "Error creating rule type",
                    icon: "error",
                    showConfirmButton: false,
                    timer: 1500,
                });
                // setError(response?.data?.message);
            }
        }
        // try {
        //     const response = await businessRuleService.create(name, description);
        //     if (response.id) {
        //         navigate("/business-rules");
        //     } else {
        //         setError(response.message);
        //     }
        // } catch (error: any) {
        //     setError(error.message);
        // }
    }

    useEffect(() => {
        setName(props.ruleType?.name ?? "");
        setDescription(props.ruleType?.description ?? "");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.show]);

    return (
        <>
            {props.show && (
                <ModalDialog
                    size="md"
                    show={props.show}
                    title={(props.ruleType ? "Edit" : "Add") + " Business Rule Type"}
                    okAction={save}
                    close={props.close}
                >
                    {/* <PrintPre>
                        {props.ruleType}
                    </PrintPre> */}
                    <InputControlled label="Name" value={name} setValue={setName} />
                    <TextAreaNeoGenControlled label="Description" value={description} setValue={setDescription} />

                    {/* <SelectNeoGen label="Rule Group" options={compr/> */}
                </ModalDialog>
            )}
        </>
    );
}

type AddEditRuleType = {
    show: boolean;
    close: () => void;
    ruleType?: BusinessRuleType;
};

import { useEffect, useState } from "react";
import PageDescription from "../../layout/page-description";
import SelectNeoGen from "../../layout/select-neogen";
import Stage from "./components/stage";

export default function Testing() {
    const [testType, setTestType] = useState("test");
    const [objectToDisplay, setObjectToDisplay] = useState<any>(undefined);

    useEffect(() => {
        switch (testType) {
            case "stages":
                setObjectToDisplay(<Stage processFlowGroupId={2} />);
                break;
            case "steps":
                console.log("steps");
                break;
        }
    }, [testType]);
    return (
        <>
            <PageDescription
                title="Testing"
                description={
                    "Here you can test each of the individual components and " +
                    "select a processflow to run them against"
                }
            />
            <div>
                <SelectNeoGen
                    label="Component Test Type"
                    onChange={(e) => setTestType(e.toString())}
                    options={[
                        { id: "stages", name: "Stages" },
                        { id: "steps", name: "Steps" },
                    ]}
                />
            </div>
        </>
    );
}

import { Fragment, useContext, useEffect, useLayoutEffect, useState } from "react";
import { Dialog, Switch, Transition } from "@headlessui/react";
import { Bars3CenterLeftIcon, XMarkIcon } from "@heroicons/react/24/outline";
import logo from "../../images/logo.png";
import { NavLink, useLocation } from "react-router-dom";
import UserContext from "../../services/user-context";
import authService from "../../services/auth.service";
import PrintPre from "../../layout/print-pre";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import Loader2 from "../utilities/Loader2";
import menuService from "../../services/menu.service";
import { Navigation } from "../../typings/api/navigations";
import { useRecoilState } from "recoil";
import userAtom from "../../atoms/userAtom";
import { ClearERCUser } from "../../typings/api/clear-erc-user";
import ButtonNeoGen from "../../layout/button";
import debugAtom from "../../atoms/debugAtom";
import Debug from "../admin/debug";

type NavigationType = {
    name: string;
    icon: string;
    href: string;
    current: boolean;
    requiredRoles?: string[];
};

function classNames(...classes: any) {
    return classes.filter(Boolean).join(" ");
}

const checker = (arr: string[], target: string[]) => target.every((v) => arr.includes(v));

export default function SideMenu(props: MenuProps): JSX.Element {
    const location = useLocation();
    const [usePadding, setUsePadding] = useState(true);
    const [user, setUser] = useRecoilState<ClearERCUser>(userAtom);
    // const [showDebug, setShowDebug] = useRecoilState<boolean>(debugAtom);
    const [navigation, setNavigation] = useState<Navigation[]>([]);
    const cache = useQueryClient();
    // const [navigation, setNavigation] = useState<NavigationType[]>(initialNavigation.filter(n => n.requiredRoles === null || checker(authService.roles, n.requiredRoles ?? [])));
    // const [navigation, setNavigation] = useState<NavigationType[]>(initialNavigation);
    function setWrapperEnabledDarkMode(enabled: boolean) {
        // $('body').toggleClass('dark-theme');

        // alert(enabled);

        props.setDarkMode(enabled);
        if (enabled) {
            document.body.classList.add("dark-theme");
            document.body.classList.add("dark");
            localStorage.setItem("theme", "dark");
        } else {
            document.body.classList.remove("dark-theme");
            document.body.classList.remove("dark");
            localStorage.setItem("theme", "light");
        }
        // window.location.reload();
    }

    const menusQuery = useQuery(["menus"], async () => {
        return await menuService.getAllVisibleOrdered();
        // if (result) {
        // return result.data
        // }
    });

    useLayoutEffect(() => {
        if (location.pathname === "/dashboard") {
            setUsePadding(false);
        } else {
            setUsePadding(true);
        }

        setNavigation((current) =>
            current.map((item) => {
                if (item.href === location.pathname) {
                    item.current = 1;
                } else {
                    item.current = 0;
                }
                return item;
            }),
        );

        // console.log({ navigation, secondaryNavigation });
    }, [location.pathname]);
    useEffect(() => {
        if (menusQuery.isSuccess) {
            const roles = authService.roles;
            // let newNav = initialNavigation.filter(n => n.requiredRoles === null || checker(authService.roles.map(r => r.roleCode ?? ""), n.requiredRoles ?? []))
            const newNav = (menusQuery.data ?? [])
                .map((item) => {
                    if (item.href === location.pathname) {
                        item.current = 1;
                    } else {
                        item.current = 0;
                    }
                    return item;
                })
                .filter(
                    (n) =>
                        n.requiredRoles === null ||
                        checker(
                            authService.roles.map((r) => r?.roleCode ?? ""),
                            JSON.parse(n.requiredRoles).roleGroups ?? [],
                        ),
                );
            setNavigation(newNav);

            // setNavigation()
            // });
            // .catch((err) => {
            //     console.log("Error getting roles: ", err);
            // });
        }
    }, [menusQuery.isSuccess, menusQuery.data, location.pathname]);

    // const [sidebarOpen, setSidebarOpen] = useState(false);

    if (menusQuery.isLoading) {
        return <Loader2 />;
    }

    return (
        <div className=" flex  bg-white sm:bg-slate-200 dark:bg-slate-900 overflow-hidden">
            {/* <PrintPre>
                {menusQuery.data}
            </PrintPre> */}
            {/* <PrintPre>
                {authService.roles}
            </PrintPre>
            {initialNavigation.map(n => (
                <div key={n.name}>
                    <PrintPre>
                        {n.requiredRoles}
                    </PrintPre>
                </div>
            ))} */}

            <Transition.Root show={props.sideBarOpen ?? false} as={Fragment}>
                <Dialog
                    as="div"
                    static
                    className="fixed inset-0 flex z-40 lg:hidden"
                    open={props.sideBarOpen}
                    onClose={() => {
                        props.setSideBarOpen ? props.setSideBarOpen(false) : null;
                    }}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                    </Transition.Child>
                    <Transition.Child
                        as={Fragment}
                        enter="transition ease-in-out duration-300 transform"
                        enterFrom="-translate-x-full"
                        enterTo="translate-x-0"
                        leave="transition ease-in-out duration-300 transform"
                        leaveFrom="translate-x-0"
                        leaveTo="-translate-x-full"
                    >
                        <div className="absolute flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-slate-600 dark:bg-slate-800">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-in-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in-out duration-300"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="absolute top-0 right-0 -mr-12 pt-2">
                                    <button
                                        className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                        onClick={() => (props.setSideBarOpen ? props.setSideBarOpen(false) : null)}
                                    >
                                        <span className="sr-only">Close sidebar</span>
                                        <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                    </button>
                                </div>
                            </Transition.Child>
                            <div className="flex-shrink-0 flex items-center px-4">
                                <img className="h-8 w-auto" src={logo} alt="ClearERC logo" />
                            </div>
                            <nav className="mt-5 flex-shrink-0 divide-y divide-indigo-800 " aria-label="Sidebar">
                                <div className="px-2 space-y-1">
                                    {navigation.map((item) => (
                                        <NavLink
                                            key={item.name}
                                            to={item.href}
                                            className={classNames(
                                                item.current
                                                    ? "bg-indigo-400 text-white"
                                                    : "text-indigo-200 hover:text-white hover:bg-indigo-400",
                                                "group flex items-center px-2 py-2 text-base font-medium rounded-md",
                                            )}
                                            aria-current={item.current ? "page" : undefined}
                                            onClick={() => (props.setSideBarOpen ? props.setSideBarOpen(false) : null)}
                                        >
                                            <span
                                                className={
                                                    "fad " + item.icon + " mr-4 flex-shrink-0 h-6 w-6 text-indigo-200"
                                                }
                                                aria-hidden="true"
                                            />
                                            {item.name}
                                        </NavLink>
                                    ))}
                                    <ButtonNeoGen
                                        className={classNames(
                                            "text-indigo-200 hover:text-white hover:bg-indigo-600",
                                            "group flex items-center px-2 py-2 text-base font-medium rounded-md",
                                        )}
                                        aria-current={undefined}
                                    >
                                        <span
                                            className={"fad fa-bug mr-4 flex-shrink-0 h-6 w-6 text-indigo-200"}
                                            aria-hidden="true"
                                        />
                                        {"Debug"}
                                    </ButtonNeoGen>
                                </div>
                            </nav>
                        </div>
                    </Transition.Child>
                    <div className="flex-shrink-0 w-14" aria-hidden="true">
                        {/* Dummy element to force sidebar to shrink to fit close icon */}
                    </div>
                </Dialog>
            </Transition.Root>

            {/* Static sidebar for desktop */}
            <div className="hidden lg:flex lg:flex-shrink-0 h-screen">
                <div className="flex flex-col w-64">
                    {/* Sidebar component, swap this element with another sidebar if you like */}
                    <div className="flex flex-col flex-grow bg-indigo-700 dark:bg-slate-800 pt-5 pb-4 overflow-hidden">
                        <div className="flex items-center flex-shrink-0 px-4">
                            <img className="h-8 w-auto" src={logo} alt="ClearERC logo" />
                        </div>
                        <nav className="flex-grow mt-5 flex-1 flex flex-col overflow-y-auto" aria-label="Sidebar">
                            <div className="px-2 space-y-1">
                                {navigation.map((item) => (
                                    <NavLink
                                        key={item.name}
                                        to={item.href}
                                        className={classNames(
                                            item.current
                                                ? "bg-indigo-800 text-white dark:text-green-400"
                                                : "text-indigo-100 dark:text-gray-500 hover:text-white hover:bg-indigo-600 dark:hover:text-green-400",
                                            "group flex items-center px-2 py-2  leading-6 font-medium rounded-md ",
                                        )}
                                        aria-current={item.current ? "page" : undefined}
                                    >
                                        <span
                                            className={
                                                "fal fa-lg " +
                                                item.icon +
                                                " mr-4 flex-shrink-0 h-1 w-6 text-indigo-200" +
                                                (item.current ? "" : "dark:text-gray-600") +
                                                "dark:group-hover:text-green-400 "
                                            }
                                            aria-hidden="true"
                                        />
                                        {item.name}
                                    </NavLink>
                                ))}
                                {/* <ButtonNeoGen
                                    className={classNames(
                                        "text-indigo-200 hover:text-white hover:bg-indigo-600",
                                        "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                                    )}
                                    onClick={() => setShowDebug(true)}
                                    aria-current={undefined}
                                >
                                    <span className={"fad fa-bug mr-4 flex-shrink-0 h-6 w-6 text-indigo-200"} aria-hidden="true" />
                                    {"Debug"}
                                </ButtonNeoGen> */}
                            </div>
                            <Switch.Group
                                as="li"
                                className="flex-shrink-0 py-4 px-5 flex items-center justify-between mr-4 pb-0"
                            >
                                <div className="flex flex-col">
                                    <Switch.Label
                                        as="p"
                                        className="text-sm font-medium text-gray-100 dark:text-gray-500"
                                        passive
                                    >
                                        {!props.darkMode ? "Light Mode" : "Dark Mode"}
                                    </Switch.Label>
                                </div>
                                <Switch
                                    checked={props.darkMode}
                                    onChange={setWrapperEnabledDarkMode}
                                    className={classNames(
                                        props.darkMode ? "bg-blue-800" : "bg-gray-200",
                                        "ml-4 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-light-blue-500",
                                    )}
                                >
                                    <span className="sr-only">Use setting</span>
                                    <span
                                        aria-hidden="true"
                                        className={classNames(
                                            props.darkMode ? "translate-x-5" : "translate-x-0",
                                            "inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200",
                                        )}
                                    />
                                </Switch>
                            </Switch.Group>
                            {/* <Switch.Group as="li" className="flex-shrink-0 py-4 px-5 flex items-center justify-between mr-4 pb-0">
                                <div className="flex flex-col">
                                    <Switch.Label as="p" className="text-sm font-medium text-gray-100 dark:text-gray-500" passive>
                                        {(showDebug) ? "Debug On " : "Debug Off"}
                                    </Switch.Label>

                                </div>
                                <Switch
                                    checked={showDebug}
                                    onChange={setShowDebug}
                                    className={classNames(
                                        (showDebug) ? "bg-green-500" : "bg-gray-200",
                                        "ml-4 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-light-blue-500"
                                    )}
                                >
                                    <span className="sr-only">Use setting</span>
                                    <span
                                        aria-hidden="true"
                                        className={classNames(
                                            (showDebug) ? "translate-x-5" : "translate-x-0",
                                            "inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                                        )}
                                    />
                                </Switch>
                            </Switch.Group> */}
                        </nav>
                    </div>
                </div>
            </div>

            <div className={"  bg-indigo-700 dark:bg-slate-800 w-full overflow-hidden h-screen min-h-screen "}>
                {/* <Debug open={showDebug} setOpen={setShowDebug} />             */}
                {props.children}
            </div>
        </div>
    );
}

type MenuProps = {
    children: JSX.Element;
    darkMode: boolean;
    setDarkMode: (darkMode: boolean) => void;
    sideBarOpen?: boolean;
    setSideBarOpen?: (sidebarOpen: boolean) => void;
};

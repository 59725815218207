import Loader2 from "../../sections/utilities/Loader2";
import { useState } from "react";
import ModalDialog from "../../layout/modal-dialog";
export default function Submitting() {
    const [open, setOpen] = useState(true);
    return (
        <>
            <ModalDialog
                show={open}
                close={() => setOpen(false)}
                title=""
                showOk={false}
                showCancel={false}
                noPadding={true}
            >
                <div className="m-5">
                    <h1 className="text-xl text-center">Please Wait</h1>
                    <h3 className="text-lg text-center">Your submission is being processed</h3>
                    <div className="m-10">
                        <Loader2 noMargin={true} noHeight={true} />
                    </div>
                </div>
            </ModalDialog>
        </>
    );
}

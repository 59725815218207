import { atom } from "recoil";
import { ProcessFlowProgressData } from "../typings/api/processflow-progress-data";
import { localStorageEffect } from "./local-storage-effect";

export const defaultProgressState: ProcessFlowProgressData = {
    completedSteps: [],
    entriesByField: {},
    currentStage: -1,
    currentStep: -1,
    numberOfRelevantInteractiveEntries: 0,
    numberOfCompletedEntries: 0,
    percentageComplete: 0,
    group: 0,
    furthestStageId: undefined,
};

const progressDataAtom = atom<ProcessFlowProgressData>({
    key: "progressDataAtom",
    default: defaultProgressState,
    effects: [localStorageEffect("progressDataAtom")],
});

export default progressDataAtom;

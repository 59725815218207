import { Navigation } from "../typings/api/navigations";
import APIService from "./api.service";

class MenuService extends APIService<Navigation> {
    constructor() {
        super("navigations");
    }
    getAllVisibleOrdered(): Promise<Navigation[]> {
        const filter = {
            where: {
                visible: 1,
            },
            order: ["order ASC"],
        };
        return this.getFiltered(filter).then((navigations) => {
            if (navigations) {
                // this is only needed so menu items with no entry get  5 as their sort
                return navigations.data.sort((a: Navigation, b: Navigation) => {
                    return (a.order ?? 5) - (b.order ?? 5);
                });
            }
            return [];
        });
    }
    getByURL(url: string): Promise<Navigation | null> {
        const filter = {
            where: {
                href: "/" + url,
            },
        };
        return this.getFiltered(filter).then((navigations) => {
            if (navigations) {
                return navigations.data[0];
            }
            return null;
        });
    }
}

// export default new MenuService();

// import { Log } from "../typings/api";
// import APIService from "./api.service";
//
// class LogService extends APIService<Log> {
// constructor() {
// super("logs");
// }
//
// }

export default new MenuService();

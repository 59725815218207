import { useEffect, useState } from "react";

export default function ProgressBar(props: ProgressBarProps) {
    return (
        <>
            <div className="relative pt-1">
                <div className="overflow-hidden h-8 mb-4 text-xs flex rounded-lg bg-indigo-500 border ">
                    <div
                        style={{ width: props.percentDone + "%" }}
                        className="text-lg transition-all shadow-none duration-1000 flex flex-col text-center whitespace-nowrap text-white justify-center bg-gradient-to-r from-indigo-300 to-blue-200"
                    >
                        <div className="absolute my-auto w-full text-gray-100">
                            {props.percentDone > 0 && <span>{props.percentDone < 100 ? props.percentDone : 100}%</span>}
                        </div>
                    </div>
                </div>
                <div className="text-center">{props.additionalText}</div>
            </div>
        </>
    );
}

type ProgressBarProps = {
    percentDone: number;
    additionalText?: string;
};

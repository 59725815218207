import ButtonNeoGen from "../../../layout/button";
import PrintPre from "../../../layout/print-pre";

export function PrevNextTab(props: PrevNextTabProps) {
    return (
        <div
            className={
                "p-6 text-center grid grid-cols-" +
                (props.currentStep === 0 || props.currentStep === props.steps.length ? 1 : 2) +
                " gap-10"
            }
        >
            {props.currentStep > 0 && (
                <ButtonNeoGen
                    block
                    onClick={async () => {
                        const response = await props.saveAction();
                        console.log(response);
                        props.previousTab();
                    }}
                    size="lg"
                    type="info"
                    text="Previous Tab"
                    icon="fas fa-chevron-left"
                    iconAlign="left"
                />
            )}
            {props.currentStep === 1 && <div className="col-span-1">a</div>}

            <PrintPre>{props.currentStep}</PrintPre>

            {props.currentStep < props.steps.length && (
                <ButtonNeoGen
                    block
                    onClick={async () => {
                        const response = await props.saveAction();
                        console.log(response);
                        await props.nextTab();
                    }}
                    size="lg"
                    text="Next Tab"
                    icon="fas fa-chevron-right"
                    iconAlign="right"
                />
            )}
        </div>
    );
}

type PrevNextTabProps = {
    currentStep: number;
    steps: any[];
    previousTab: () => void;
    nextTab: () => void;
    saveAction: () => void;
};

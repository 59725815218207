/**
 *
 *
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 8/25/20, 6:24 AM
 *
 */

import APIService from "./api.service";
import { ProcessFlowGroup } from "../typings/api/processflow-group";

class ProcessFlowGroupService extends APIService<ProcessFlowGroup> {
    constructor() {
        super("processflow-groups");
    }
    async getOneIncludingRequiredRoles(id: number) {
        const filter = {
            where: { id },
            include: [{ relation: "roleGroups" }],
        };
        const x = await this.getFiltered(filter);
        console.error({ x });
        return x;
    }
    /*
    {

        "include": [
            {
            "relation": "processflows"
        }
        ]
        }
        */
    getAllIncludingFlows() {
        return this.getFiltered({
            include: [{ relation: "processflows" }],
        });
    }
}

export default new ProcessFlowGroupService();

import { atom, DefaultValue } from "recoil";
import { ProcessFlow } from "../typings/api/processflow";
import { ProcessFlowProgressData } from "../typings/api/processflow-progress-data";
import { ProcessFlowStage } from "../typings/api/processflow-stage";
import { localStorageEffect } from "./local-storage-effect";

export type SaveType = {
    saving: boolean;
    from?: string | string[];
};

const savingAtom = atom<SaveType>({
    key: "savingAtom",
    default: { saving: false },
    effects: [localStorageEffect("savingAtom")],
});

export default savingAtom;

/**
 *
 *
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 8/25/20, 6:24 AM
 *
 */

import { AxiosResponse } from "axios";
import APIService from "./api.service";
import { ProcessFlowStage } from "../typings/api/processflow-stage";
//

// const API_URL = process.env.REACT_APP_API_URL;

class ProcessFlowStageService extends APIService<ProcessFlowStage> {
    constructor() {
        super("processflow-stages");
    }
    getAllByGroupIdIncludingRuleGroups(groupId: number): Promise<void | AxiosResponse<ProcessFlowStage[] | void>> {
        // const filter = {
        // group: groupId
        // };
        const filter: { where?: any; include: any; order: any } = {
            where: {
                group: groupId,
            },
            include: [
                {
                    relation: "businessRuleGroups",
                    scope: {
                        include: [
                            {
                                relation: "businessRules",
                            },
                        ],
                    },
                },
            ],
            order: "order ASC",
        };
        if (groupId === -1) {
            delete filter.where;
        }
        return this.getFiltered(filter);
    }
    deleteByGroupId(groupId: number) {
        const filter = {
            group: groupId,
        };
        return this.deleteWhere(this.endpoint, filter);
    }
    async getallIncludingRules(): Promise<void | AxiosResponse<ProcessFlowStage[] | void>> {
        // async getOneIncludingRequiredRoles(id: number) {
        const filter = {
            include: [
                {
                    relation: "businessRuleGroups",
                    scope: {
                        include: [
                            {
                                relation: "businessRules",
                            },
                        ],
                    },
                },
            ],
        };
        const x = await this.getFiltered(filter);
        console.error({ x });
        return x;
        // }
    }
}

export default new ProcessFlowStageService();

import { z } from "zod";
import { makeRequest, parseSchema } from "../../../jason-proof-of-concept/shared/utils";
import { processFlowGroupSchema } from "../domain/processflow-group";

const duplicateGroupSchema = processFlowGroupSchema;

export type ProcessFlowGroupData = z.infer<typeof duplicateGroupSchema>;

export const duplicateProcessFlowGroup = async ({
    authToken,
    data,
    id,
}: {
    authToken: string;
    data: ProcessFlowGroupData;
    id: number;
}) => {
    const response = await makeRequest({
        method: "post",
        path: `processflow-groups/${id}/duplicate`,
        authToken,
        data,
    });

    const processFlowGroup = parseSchema(duplicateGroupSchema, response.data);
    return processFlowGroup;
};

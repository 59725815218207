import { z } from "zod";
import { makeRequest, parseSchema } from "../../shared/utils";
import { businessRuleTypeSchema } from "../domain/business-rule-type";

const businessRuleTypeResponseSchema = z.object({
    id: z.number(),
    name: z.string(),
    comparisonType: z.number().nullable(),
    usesField2: z.number().nullable(),
    dataType: z.string(),
    description: z.string(),
    matchValue: z.number().nullable(),
});

const getBusinessRuleTypesResponseSchema = z.array(businessRuleTypeResponseSchema);

export const getBusinessRuleTypes = async ({ authToken }: { authToken?: string }) => {
    const response = await makeRequest({
        method: "get",
        path: "/business-rule-types",
        authToken,
    });

    const businessRuleTypesDtos = parseSchema(getBusinessRuleTypesResponseSchema, response.data);

    const businessRuleTypes = parseSchema(
        z.array(businessRuleTypeSchema),
        businessRuleTypesDtos.map((dto) => ({
            ...dto,
        })),
    );

    return businessRuleTypes;
};

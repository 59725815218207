import { useState } from "react";
import { Entry } from "../../../layout/add-to-list";
import { ProcessFlow } from "../../../typings/api/processflow";
import { ProcessFlowProgressData } from "../../../typings/api/processflow-progress-data";

export default function useStepValidation() {
    // const [isValid, setIsValid] = useState(false);
    // const [isDirty, setIsDirty] = useState(false);

    function checkStepValidation(step: Entry, progressflowData?: ProcessFlowProgressData | null) {
        for (const [idx, field] of Object.entries(step)) {
            // console.log({idx, field});
        }
        // setIsValid(true);
        // setIsDirty(true);
    }

    return [checkStepValidation];
}

import PageDescription from "../../layout/page-description";
import ProcessFlowWizard from "../processflows/processflow-wizard";

export default function NewApplication() {
    return (
        <>
            <ProcessFlowWizard id={6} />
        </>
    );
}

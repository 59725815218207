import PageDescription from "../../layout/page-description";

export default function Affiliates() {
    return (
        <>
            <PageDescription
                title="Affiliates"
                description="A list of people that are working for you to bring in clients."
            />
        </>
    );
}

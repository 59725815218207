import { atom } from "recoil";
import { ClearERCUser } from "../typings/api/clear-erc-user";
import { localStorageEffect } from "./local-storage-effect";

const userAtom = atom<ClearERCUser>({
    key: "userAtom",
    default: {
        company: -1,
        email: "",
    },
    effects: [localStorageEffect("userAtom")],
});

export const assumedUserAtom = atom<ClearERCUser | undefined>({
    key: "assumedUserAtom",
    default: undefined,
    effects: [localStorageEffect("assumedUserAtom")],
});

export default userAtom;

/**
 *
 *
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 8/25/20, 6:24 AM
 *
 */

import APIService from "./api.service";
import { ProcessFlowProgress } from "../typings/api/processflow-progress";
// import _ from "lodash";
// import { useRecoilValue_TRANSITION_SUPPORT_UNSTABLE } from "recoil";

class ProcessFlowProgressService extends APIService<ProcessFlowProgress> {
    constructor() {
        super("processflow-progresses");
    }
    getProgress(userId: string, groupId: number) {
        const filter = {
            where: {
                and: [{ userId: userId }, { group: Number(groupId) }],
            },

            order: ["id DESC"],
        };
        // console.error({ filter });
        return this.getFiltered(filter);
    }
    async updateByUserId(processflowProgress: ProcessFlowProgress) {
        // const alter = _.cloneDeep(processflowProgress);
        // delete alter.userId;
        // console.error(JSON.parse(alter.data)?.currentStage);
        // if (processflowProgress.userId) {
        try {
            console.trace("updateByUser", processflowProgress);
            const result = await this.update(processflowProgress.id, processflowProgress);
            return result;
        } catch (e) {
            console.error(e);
        }

        // }
    }
}

export default new ProcessFlowProgressService();

import PopoverComponent from "./popover-component";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import { ReactNode } from "react";

export const Label = ({
    isRequired,
    text,
    isDarkBackground,
    helpDocument,
    htmlFor,
    isError,
}: {
    isRequired?: boolean;
    text: ReactNode;
    isDarkBackground?: boolean;
    helpDocument?: any;
    htmlFor?: string;
    isError?: boolean;
}) => {
    return (
        <label
            htmlFor={htmlFor}
            className={
                "inline-block relative text-sm font-normal tracking-wider leading-normal " +
                (isDarkBackground ? "text-gray-300" : "text-gray-500") +
                " dark:text-gray-400"
            }
        >
            {text}
            {isRequired && (
                <span
                    className="inline-block text-lg leading-none text-red-500 font-extralight pl-[1px] mr-[1px]"
                    style={{ top: 10 }}
                >
                    *
                </span>
            )}
            {isError && (
                <div className={"inline-block  pointer-events-none -mt-6 mr-1"}>
                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                </div>
            )}
            {helpDocument && (
                <div className="inline-block ml-1">
                    <PopoverComponent document={helpDocument} />
                </div>
            )}
        </label>
    );
};

// classes above are from input controlled.
// checkbox controlled: classes="font-medium text-gray-700 dark:text-gray-300 "
// text-area controlled: className="block text-sm font-medium text-gray-700 dark:text-gray-400 "
// file upload widget block text-sm font-medium text-gray-600 dark:text-gray-400

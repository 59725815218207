/**
 *
 *
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 9/28/20, 9:16 AM
 *
 */

import PropTypes from "prop-types";

/**
 * @component
 */
export default function Main(props) {
    return (
        <main>
            <div className={props.fluid ? "container-fluid" : "container mx-auto px-2"}>{props.children}</div>
        </main>
    );
}

Main.propTypes = {
    children: PropTypes.any,
    fluid: PropTypes.bool,
};

import Badge, { BadgeColors } from "./badge";
import ButtonNeoGen, { ButtonNeoGenTypes } from "./button";

/* This example requires Tailwind CSS v2.0+ */
export default function CardHeading(props: CardHeadingProps) {
    return (
        <div className="bg-white dark:bg-gray-800 dark:border-gray-900  px-0 pb-2 border-b border-gray-200 sm:px-0 rounded-xl shadow overflow-hidden">
            <div className="flex justify-between items-center flex-wrap ">
                <div className="flex-none ">
                    <h3 className="text-lg pl-3 pt-3 leading-6 font-medium text-gray-900 dark:text-gray-300 overflow-clip">
                        {props.title}
                    </h3>
                    <p className="pt-1 pl-3 text-sm text-gray-500 overflow-hidden">{props.description}</p>
                </div>
                <div className=" flex-grow px-3">
                    {props.buttons?.map((button, index) => (
                        <div key={index} className="sm:flex-none inline-block mr-3 mb-3">
                            {button.text && !button.disabled && (
                                <div className="mt-4 sm:flex-auto">
                                    <ButtonNeoGen
                                        // className={
                                        //     "inline-flex items-center justify-center rounded-md border" +
                                        //     " border-transparent " + (button.bg ?? "bg-indigo-600") + " px-4 py-2 text-sm " +
                                        //     "font-medium text-white shadow-sm hover:bg-indigo-700 " +
                                        //     "focus:outline-none focus:ring-2 focus:ring-indigo-500 " +
                                        //     "focus:ring-offset-2 sm:w-auto"
                                        // }

                                        // onClick={() => showAddEditUser(null)}
                                        onClick={button.action}
                                        size={button.size}
                                        type={button.type}
                                    >
                                        {button.icon && <span className={button.icon + " mr-2"} />}
                                        {button.text}
                                        {button.bg}
                                    </ButtonNeoGen>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
            <div className="px-3">
                {props.badges?.map((badge, index) => (
                    <Badge
                        className="mt-5 mr-3"
                        key={index}
                        label={badge.text ?? ""}
                        color={badge.color ?? "indigo"}
                        // icon={badge.icon}
                    />
                ))}
            </div>
            <div className="overflow-x-scroll">{props.children}</div>
        </div>
    );
}

type CardHeadingButton = {
    text?: string;
    icon?: string;
    action?: () => void;
    disabled?: boolean;
    bg?: string;
    size?: "xxs" | "xs" | "sm" | "md" | "lg" | "xl";
    type?: ButtonNeoGenTypes;
};

type CardHeadingBadge = {
    text?: string;
    color?: BadgeColors;
    icon?: string;
};

type CardHeadingProps = {
    title: string;
    description: string;
    badges?: CardHeadingBadge[];
    children?: React.ReactNode;
    buttons?: CardHeadingButton[];
};

import { useState } from "react";
import { useRecoilState } from "recoil";
import userAtom from "../../../atoms/userAtom";
import ModalDialog from "../../../layout/modal-dialog";
import { search as ssSearch } from "ss-search";
import { usePromise } from "../../../jason-proof-of-concept/shared/hooks";
import InputControlled from "../../../layout/input-controlled";
import { ErcUser } from "../../../typings/api/erc-user";
import TableNeogen from "../../../layout/table-neogen";
import usersService from "../../../services/users.service";
import { compare } from "../dashboard-header";
import { setUser } from "@sentry/react";
import authService from "../../../services/auth.service";
import { useNavigate } from "react-router";
import { useQueryClient } from "@tanstack/react-query";
import { getAuthToken } from "../../../services/auth-header";
import { ClearERCUser } from "../../../typings/api/clear-erc-user";
import Highlighter from "react-highlight-words";
import Avatar from "react-avatar";
import ButtonNeoGen from "../../../layout/button";
import { authAsUser } from "../../../jason-proof-of-concept/users/actions/authAsUser";

export const UserSelectorModal = ({
    onClose,
    onSelectUser,
}: {
    onClose: () => any;
    onSelectUser: (user: any) => any;
}) => {
    const [authUser] = useRecoilState(userAtom);
    const [search, setSearch] = useState("");
    const [selectedUser, setSelectedUser] = useState<ErcUser | undefined>();
    const usersAction = usePromise(async () => {
        const response = await usersService.getAll();
        return response?.data || [];
    }, []);
    const users = usersAction.result || [];
    const cache = useQueryClient();

    const filteredUsers = (
        search
            ? (ssSearch(users, ["firstName", "lastName", "email", "phone", "name"], search) as ClearERCUser[])
            : users
    )
        .filter((user) => user.id !== authUser.id)
        .map((u) => {
            if (!u.email) {
                u.email = "Unknown";
            }
            if (!u.name) {
                u.name = "-";
            }
            return u;
        })
        .sort(compare);

    async function login(user: ErcUser) {
        try {
            const rbac_response = await authService.API.getURL(`users/${user.id}/roles`);
            authService.roles = rbac_response?.data;
            authService.roles.push({ roleCode: "URL_LOGOUT" }); // Everyone can log out
            authService.roles.push({ roleCode: "URL_DASHBOARD" }); // Everyone can log out
            authService.user = user;
            localStorage.setItem("userId", user?.id ?? "-1");
            localStorage.setItem("user", JSON.stringify(user));
            localStorage.setItem("roles", JSON.stringify(authService.roles));
            setUser(user);
            window.location.href = "/dashboard";
        } catch (error: any) {
            console.error(error);
        }
    }

    return (
        <ModalDialog title="Become user" size="lg" show={true} showOk={false} close={() => onClose()}>
            <div style={{ minHeight: 565 }}>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        flex: 1,
                        justifyContent: "space-between",
                    }}
                >
                    <div style={{ flex: 2 }}></div>
                    <div style={{ flex: 1 }}>
                        <InputControlled
                            darkBackground={true}
                            type="text"
                            value={search}
                            setValue={setSearch}
                            name={"search"}
                            placeholder="Search users..."
                            noDebounce={true}
                        />
                    </div>
                </div>
                <div className="max-h-[500px] overflow-y-auto overflow-x-hidden">
                    <table
                        className="min-w-full divide-y divide-gray-300 dark:divide-gray-900"
                        style={{ tableLayout: "fixed" }}
                    >
                        <thead
                            className="bg-gray-50 dark:bg-gray-800 rounded-t-xl"
                            style={{ position: "sticky", top: 0 }}
                        >
                            <tr>
                                <th
                                    scope="col"
                                    className="dark:text-gray-500 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                >
                                    Name
                                </th>
                                <th
                                    scope="col"
                                    className="dark:text-gray-500 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                >
                                    Company
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody className="rounded-xl divide-y divide-gray-200 bg-white dark:bg-gray-800 dark:divide-gray-900">
                            {(filteredUsers ?? [])?.map((person: ClearERCUser) => (
                                <tr key={person.id}>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                                        <div className="flex items-center">
                                            <div className="h-10 w-10 flex-shrink-0">
                                                <Avatar
                                                    size="40px"
                                                    name={[person.firstName, person.lastName].join(" ")}
                                                    round={true}
                                                    email={person.email}
                                                />
                                            </div>
                                            <div className="ml-4">
                                                <div className="font-medium text-gray-900 dark:text-gray-300">
                                                    <Highlighter
                                                        searchWords={[search]}
                                                        autoEscape={true}
                                                        textToHighlight={[person.firstName, person.lastName].join(" ")}
                                                    />
                                                </div>
                                                <div className="text-gray-500">
                                                    <Highlighter
                                                        searchWords={[search]}
                                                        autoEscape={true}
                                                        textToHighlight={person.email}
                                                    />
                                                </div>
                                                <div className="text-gray-500">
                                                    <Highlighter
                                                        searchWords={[search]}
                                                        autoEscape={true}
                                                        textToHighlight={person.phone || ""}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        <Highlighter
                                            searchWords={[search]}
                                            autoEscape={true}
                                            textToHighlight={person.name || "-"}
                                        />
                                    </td>
                                    <td>
                                        <ButtonNeoGen
                                            onClick={async () => {
                                                if (!person.id) {
                                                    throw new Error("No person id");
                                                }
                                                const token = getAuthToken();
                                                const authResponse = await authAsUser({
                                                    authToken: token,
                                                    id: person.id,
                                                });
                                                const userSelected = filteredUsers.find((u) => u.id === person.id);
                                                if (userSelected) {
                                                    userSelected.token = authResponse.token;
                                                    await cache.clear();
                                                    await onSelectUser(userSelected);
                                                    await login(userSelected);
                                                }
                                            }}
                                        >
                                            Become user
                                        </ButtonNeoGen>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </ModalDialog>
    );
};

import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import PrintPre from "../../layout/print-pre";
import interactionsService from "../../services/interactions.service";
import usersService from "../../services/users.service";
import ShowMessage from "../messages/modals/show-message";
import Loader2 from "../utilities/Loader2";

/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/line-clamp'),
    ],
  }
  ```
*/

export default function MessagesList() {
    const [idToShow, setIdToShow] = useState<number>(-1);
    const [showModal, setShowModal] = useState(false);
    const messagesQuery = useQuery(["messages"], async () => {
        const response = await interactionsService.getAll();
        if (response) {
            return response.data;
        }
    });

    // TODO: this wont work because they wont have access to the users
    const usersQuery = useQuery(["users"], async () => {
        const response = await usersService.getAll();
        if (response) {
            return response.data;
        }
    });

    if (messagesQuery.isLoading || usersQuery.isLoading) return <Loader2 />;
    return (
        <>
            {/* <PrintPre>{messagesQuery.data}</PrintPre> */}
            <ul role="list" className="divide-y divide-gray-200 dark:divide-gray-800">
                {messagesQuery.data?.map((message) => (
                    <li
                        key={message.id}
                        className="relative bg-white dark:bg-gray-800 py-5 px-4 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 hover:bg-gray-50 dark:hover:bg-gray-700"
                    >
                        <div className="flex justify-between space-x-3">
                            <div className="min-w-0 flex-1">
                                <button
                                    onClick={() => {
                                        setIdToShow(message.id ?? -1);
                                        setShowModal(true);
                                    }}
                                    className="block focus:outline-none"
                                >
                                    <span className="absolute inset-0" aria-hidden="true" />
                                    <p className="truncate text-sm font-medium text-gray-900 dark:text-gray-300">
                                        {usersQuery.data?.find((u) => message.interactionFrom === u.id)?.name}
                                    </p>
                                    {message.read === 0 && (
                                        <p className="truncate text-xs text-red-500 dark:text-red-400 text-left">
                                            Unread
                                        </p>
                                    )}
                                </button>
                            </div>
                            <time
                                dateTime={new Date(message.dateCreated)?.toLocaleString()}
                                className="flex-shrink-0 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400"
                            >
                                {new Date(message.dateCreated)?.toLocaleString()}
                            </time>
                        </div>
                        <div className="mt-1">
                            <p className="text-sm text-gray-600 line-clamp-2 dark:text-gray-500">{message.activity}</p>
                        </div>
                    </li>
                ))}
            </ul>
            <ShowMessage id={idToShow} show={showModal} setShow={setShowModal} />
        </>
    );
}

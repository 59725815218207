/**
 *
 *
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 9/19/20, 10:58 AM
 *
 */

import { PayrollContact } from "../typings/api/payroll-contact";
import APIService from "./api.service";

class PayrollContactService extends APIService<PayrollContact> {
    constructor() {
        super("payroll-contacts");
    }
}

export default new PayrollContactService();

import ModalDialog from "../../../layout/modal-dialog";
import Loader2 from "../../utilities/Loader2";
import { z } from "zod";
import { useForm } from "../../../hooks/useForm";
import { Form, FormModalButtons } from "../../../layout/form/form";
import { TextField } from "../../../layout/form/text-field";
import { useMutation } from "@tanstack/react-query";
import { duplicateProcessFlowGroup, ProcessFlowGroupData } from "../actions/duplicate-group";
import { getAuthTokenNoThrow } from "../../../services/auth-header";
import { useNavigate, useParams } from "react-router";

const schema = z.object({
    name: z.string().nullish(),
    description: z.string().nullish(),
});

type Data = z.infer<typeof schema>;

type DuplicateGroup = {
    close: () => void;
    show: boolean;
    title?: string;
    description?: string;
};

export default function DuplicateGroup({ close, show, title, description }: DuplicateGroup) {
    const { processflowGroup } = useParams();
    const authToken = getAuthTokenNoThrow() || "no-token";
    const navigate = useNavigate();

    const form = useForm({
        schema,
        defaultValues: { name: `${title} (copy)`, description: `${description} (copy)` },
    });

    const duplicateGroupMutation = useMutation({
        mutationFn: async ({ authToken, data, id }: { id: number; authToken: string; data: ProcessFlowGroupData }) => {
            return await duplicateProcessFlowGroup({ authToken, data, id });
        },
    });

    const handleSubmit = async (data: Data) => {
        const processFlow = await duplicateGroupMutation.mutateAsync({
            authToken,
            data,
            id: parseInt(processflowGroup as string),
        });
        if (processFlow.id) {
            navigate("/processflow/" + processFlow.id);
            close();
        }
    };

    return (
        <ModalDialog
            showOk={false}
            showCancel={false}
            close={close}
            size="sm"
            show={show}
            title={"Duplicate processflow group"}
        >
            {duplicateGroupMutation.isLoading ? (
                <>
                    <p className="text-center">Process Flow {processflowGroup} is being duplicated.</p>
                    <Loader2 />
                </>
            ) : (
                <div className="mt-5">
                    <div className="bg-yellow-50 p-2 border-yellow-100  border-2 rounded-lg mb-5 text-xs text-yellow-500">
                        You are about to duplicate Process Flow {processflowGroup} with all of it&apos;s associated
                        items
                    </div>
                    <Form onSubmit={form.handleSubmit(handleSubmit)}>
                        <TextField label="Name" {...form.getFieldProps("name")} />
                        <TextField label="Description" {...form.getFieldProps("description")} />
                        <FormModalButtons
                            form={form}
                            onCancel={close}
                            okText="Duplicate ProcessFlow"
                            isLoading={duplicateGroupMutation.isLoading}
                        />
                    </Form>
                </div>
            )}
        </ModalDialog>
    );
}

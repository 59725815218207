import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useParams } from "react-router";
import ButtonNeoGen from "../../../layout/button";
import TableNeogen from "../../../layout/table-neogen";
import interactionsService from "../../../services/interactions.service";
import usersService from "../../../services/users.service";
import { ClearERCUser } from "../../../typings/api/clear-erc-user";
import NewMessage from "../../messages/modals/new-message";
import ShowMessage from "../../messages/modals/show-message";
import CustomerCard from "../customer-card";
import NotesList from "./notes-list";

export default function NotesCard({ user }: { user?: ClearERCUser }) {
    const { id } = useParams();
    const [show, setShow] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [messageId, setMessageId] = useState(0);
    const [selectedUser, setSelectedUser] = useState("");
    // const [users, setUsers] = useState<ErcUser[]>([]);
    const [page, setPage] = useState("basic");
    const usersQuery = useQuery(["users"], async () => {
        const response = await usersService.getAll();
        if (response) {
            return response.data;
        }
    });

    const messagesQuery = useQuery(["messages"], async () => {
        const response = await interactionsService.getAll();
        if (response) {
            return response.data;
        }
    });
    return (
        <>
            <h1 className="text-3xl font-bold tracking-tight text-blue-gray-900">Notes</h1>
            {/* <form className=" mt-6 space-y-8 divide-y"> */}
            {/* <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6"> */}
            {/* <div className="sm:col-span-6"> */}
            <div className="mt-3">
                <ButtonNeoGen text="Add Notes" onClick={() => setShow(true)} />
                {/* </div> */}
                {/* </div> */}
                <div className="mt-5 w-full overflow-hidden">
                    <NotesList />
                    {/* <TableNeogen
                        compact={false}
                        formatters={[
                            {
                                field: "dateCreated",
                                type: "DateTime",
                            },
                            {
                                field: "followupDatetime",
                                type: "DateTime",
                            },
                            {
                                field: "needsFollowUp",
                                type: "Boolean",
                            },
                            {
                                field: "read",
                                type: "Boolean",
                            },
                            {
                                field: "interactionFrom",
                                type: "User",
                            },
                            {
                                field: "interactionTo",
                                type: "User",
                            },
                            {
                                field: "interactionRegarding",
                                type: "User",
                            },
                            {
                                field: "type",
                                type: "MessageType",
                            },
                        ]}
                        ignoreFields={["activity", "summary"]}
                        entries={messagesQuery.data?.filter((m) => m.type > 1) ?? []}
                        actions={[
                            {
                                label: "Open Interaction",
                                onClick: (id: any) => {
                                    // setIsEdit(true);
                                    // setEditID(id);
                                    setMessageId(id);
                                    setShowMessage(true);
                                },
                                icon: "fal fa-folder-open",
                                className: "bg-blue-500 hover:bg-blue-600 text-white",
                            },
                        ]}
                    /> */}
                </div>
                {/* <CustomerCard /> */}
                {/* </form> */}

                <NewMessage show={show} setShow={setShow} type={3} to={id} />
                <ShowMessage id={messageId} show={showMessage} setShow={setShowMessage} />
            </div>
        </>
    );
}

/*
 *
 *
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 8/22/20, 10:29 AM
 *
 */

// src/Title.js

import PropTypes from "prop-types";
import "./print-pre.css";

/**
 * Makes a shimmering title for the top of a page
 * @component
 * @example
 * const text = 'My Title'
 * return (
 *   <Title title={text} />
 * )
 */

const getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key, value) => {
        if (typeof value === "object" && value !== null) {
            if (seen.has(value)) {
                return;
            }
            seen.add(value);
        }
        return value;
    };
};

const PrintPre = ({ children }) => {
    return <pre className={"print-pre"}>{JSON.stringify(children, getCircularReplacer(), 2)}</pre>;
};

export default PrintPre;

PrintPre.propTypes = {
    /**
     * The title to display
     */
    children: PropTypes.any,
};

import { Label } from "./label";
import PopoverComponent from "./popover-component";
import PrintPre from "./print-pre";

export default function CheckBoxNeoGenControlled(props: CheckBoxNeoGenControlledProps) {
    return (
        <fieldset className={(!props.noSpace ? " " : "") + props.className}>
            <legend className="sr-only">{props.name}</legend>
            <div className="relative flex items-center ">
                <div className="flex items-center h-5">
                    <input
                        id={props.id ?? props.name}
                        aria-describedby="comments-description"
                        name={props.name}
                        type="checkbox"
                        checked={props.value || false}
                        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 dark:text-indigo-300 dark:border-gray-900 rounded checkboxes"
                        onChange={props.setValue}
                    />
                </div>
                <div className="ml-3 text-sm ">
                    <Label
                        text={props.label || ""}
                        htmlFor={props.name}
                        helpDocument={props.helpDocument}
                        isRequired={!!props.required}
                    />
                    {props.description && (
                        <p id="comments-description" className="text-gray-500">
                            {props.description}
                        </p>
                    )}
                </div>
            </div>
        </fieldset>
    );
}
type CheckBoxNeoGenControlledProps = {
    setValue: (event: React.ChangeEvent<HTMLInputElement>) => void;
    value: boolean;
    label?: string;
    name: string;
    description?: string;
    id?: string;
    noSpace?: boolean;
    helpDocument?: string | undefined;
    className?: string;
    required?: boolean;
};

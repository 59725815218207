/**
 *
 *
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 8/25/20, 6:24 AM
 *
 */

import { BusinessRuleType } from "../typings/api/business-rule-type";
import APIService from "./api.service";

// const debug        = require('debug')('Neogen:CDRService');

class BusinessRuleTypeService extends APIService<BusinessRuleType> {
    constructor() {
        super("business-rule-types");
    }

    // createBusinessRuleGroupsPerProduct(businessRuleGroupsPerProduct: BusinessRuleGroupsPerProduct) {

    // getAllByProductId(productId: number) {
    //     const filter = {

    //     };
    //     return this.getFilteredWhere(filter);
    // }
}

export default new BusinessRuleTypeService();

/* This example requires Tailwind CSS v2.0+ */
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon, ClockIcon } from "@heroicons/react/24/outline";
import {
    CheckIcon,
    EnvelopeIcon,
    HandThumbUpIcon,
    PencilSquareIcon,
    PhoneIcon,
    UserIcon,
} from "@heroicons/react/24/solid";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loader2 from "../sections/utilities/Loader2";
import { sortByDate, sortByDateReverse, sortByName } from "../sections/utilities/sort";
import interactionsService from "../services/interactions.service";
import logService from "../services/log-service";
import usersService from "../services/users.service";
import { Log } from "../typings/api";
import PrintPre from "./print-pre";
const faqs = [
    {
        question: "What's the best thing about Switzerland?",
        answer: "I don't know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.",
    },
    // More questions...
];

type TimelineEntry = {
    id: number;
    content: string;
    target: string;
    extraDetail: string;
    href: string;
    date: Date;
    datetime: Date;
    icon: any;
    iconBackground: string;
};

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
}

export default function Feed() {
    const { id } = useParams();
    const [entries, setEntries] = useState<any[]>([]);
    const logsQuery = useQuery(["logs", id], async () => {
        const response = await logService.getLogins(id ?? "");
        if (response) {
            return response.data;
        }
    });
    const usersQuery = useQuery(["users"], async () => {
        const response = await usersService.getAll();
        if (response) {
            return response.data;
        }
    });

    const messagesQuery = useQuery(["messages", id], async () => {
        const response = await interactionsService.getAllForId(id ?? "");
        if (response) {
            return response.data;
        }
    });

    useEffect(() => {
        if (logsQuery.data) {
            const newEntries: TimelineEntry[] = [];
            logsQuery.data.forEach((log: Log) => {
                newEntries.push(
                    // ...entries,
                    {
                        id: log.id ?? -1,
                        content: "User Logged In",
                        extraDetail: log.body ?? "",
                        // extraDetail: JSON.parse(log.body ?? "{}"),
                        target: usersQuery.data?.find((u) => u.id === (log.userId ?? ""))?.name ?? "",
                        href: "log.href",
                        date: new Date(log.eventTimestamp ?? ""),
                        datetime: new Date(log.eventTimestamp ?? ""),
                        icon: UserIcon,
                        iconBackground: "bg-green-400",
                    },
                );
            });
            messagesQuery.data?.forEach((message) => {
                let icon = HandThumbUpIcon;
                let content = "User Liked";
                let iconBg = "bg-blue-400";
                let target =
                    (usersQuery.data?.find((u) => u.id === (message.interactionFrom ?? ""))?.name ?? "") +
                    " to " +
                    (usersQuery.data?.find((u) => u.id === (message.interactionTo ?? ""))?.name ?? "");

                switch (message.type) {
                    case 1:
                        icon = EnvelopeIcon;
                        content = "Message";
                        iconBg = "bg-blue-400";
                        break;
                    case 2:
                        icon = PhoneIcon;
                        content = "Phone Call";
                        iconBg = "bg-orange-400";
                        break;
                    case 3:
                        icon = PencilSquareIcon;
                        content = "Wrote Notes";
                        iconBg = "bg-purple-400";
                        target = usersQuery.data?.find((u) => u.id === (message.interactionFrom ?? ""))?.name ?? "";

                        break;
                }

                newEntries.push(
                    // ...entries,
                    {
                        id: message.id ?? -1,
                        content: content,
                        extraDetail: message.activity ?? "",
                        // extraDetail: JSON.parse(log.body ?? "{}"),
                        target: target,
                        href: "log.href",
                        date: new Date(message.dateCreated ?? ""),
                        datetime: new Date(message.dateCreated ?? ""),
                        icon: icon,
                        iconBackground: iconBg,
                    },
                );
            });

            setEntries(newEntries.sort(sortByDateReverse));
        }
    }, [logsQuery.data, messagesQuery.data, usersQuery.data]);

    if (logsQuery.isLoading) {
        return <Loader2 />;
    }

    return (
        <div className="">
            {/* <PrintPre>{logsQuery.data}</PrintPre> */}
            {/* <PrintPre>{timeline}</PrintPre> */}

            <div className="flex flex-col mt-2">
                <div className="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg p-8 bg-slate-100 dark:bg-slate-700">
                    <div className="flow-root">
                        <ul role="list" className="-mb-8">
                            {entries.map((event, eventIdx) => (
                                <li key={event.id}>
                                    <dl>
                                        <Disclosure as="div" key={event.id} className="pt-0">
                                            {({ open }) => (
                                                <>
                                                    <dt className="text-lg">
                                                        <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                                                            <div className="relative pb-8">
                                                                {eventIdx !== entries.length - 1 ? (
                                                                    <span
                                                                        className="absolute left-4 -ml-px h-full w-0.5  dark:bg-gray-700   bg-gray-200"
                                                                        aria-hidden="true"
                                                                    />
                                                                ) : null}
                                                                <div className="relative flex space-x-5">
                                                                    <div>
                                                                        <span
                                                                            className={classNames(
                                                                                event.iconBackground,
                                                                                "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white dark:ring-gray-600",
                                                                            )}
                                                                        >
                                                                            <event.icon
                                                                                className="h-5 w-5 text-white"
                                                                                aria-hidden="true"
                                                                            />
                                                                        </span>
                                                                    </div>
                                                                    <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                                                                        <div>
                                                                            <p className="text-sm text-gray-500 dark:text-indigo-300">
                                                                                <span
                                                                                    // href={event.href}
                                                                                    className="font-medium text-gray-900 dark:text-gray-400"
                                                                                >
                                                                                    {event.target}
                                                                                </span>
                                                                                &nbsp;
                                                                                {event.content}{" "}
                                                                            </p>
                                                                        </div>
                                                                        <div className="text-right text-sm whitespace-nowrap text-gray-500">
                                                                            <time
                                                                                dateTime={event.datetime.toLocaleString()}
                                                                            >
                                                                                {event.date.toLocaleString()}
                                                                            </time>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <span className="ml-6 h-7 flex items-center">
                                                                <ChevronDownIcon
                                                                    className={classNames(
                                                                        open ? "-rotate-180" : "rotate-0",
                                                                        "h-6 w-6 transform",
                                                                    )}
                                                                    aria-hidden="true"
                                                                />
                                                            </span>
                                                        </Disclosure.Button>
                                                    </dt>
                                                    <Disclosure.Panel as="dd" className="mt-0 mb-8 pr-6 z-20">
                                                        <p
                                                            className="text-sm text-gray-500
                                                        bg-white p-2 z-20 rounded-lg shadow-lg dark:bg-gray-800 dark:text-gray-300
                                                            
                                                        "
                                                        >
                                                            {event.extraDetail}
                                                        </p>
                                                    </Disclosure.Panel>
                                                </>
                                            )}
                                        </Disclosure>
                                    </dl>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

import { useController } from "react-hook-form";
import { GetFieldPropsReturn } from "../../hooks/useForm";
import { Field, FormFieldProps } from "./field";
import { Option, Select } from "./select-input";

type Other = { options: Option[] };

export const SelectField = ({
    name,
    form,
    isRequired,
    label,
    ...other
}: FormFieldProps<string> & GetFieldPropsReturn<any> & Other) => {
    const { field, fieldState } = useController({
        name,
        control: form.control,
        rules: { required: isRequired ? "This field is required" : undefined },
        defaultValue: other.defaultValue || "",
    });
    const error = fieldState.error?.message;
    return (
        <Field label={label} isRequired={isRequired} helpText={other.helperText} error={error}>
            <Select {...field} options={other.options} />
        </Field>
    );
};

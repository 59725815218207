import { useQuery } from "@tanstack/react-query";
import Main from "../../layout/Main";
import PageDescription from "../../layout/page-description";
import TableNeogen from "../../layout/table-neogen";
import utmLogService from "../../services/utm-log.service";
import Loader2 from "../utilities/Loader2";

export default function UTMLogs() {
    const utmLogsQuery = useQuery(["utm-logs"], async () => {
        const response = await utmLogService.getAll();
        if (response) {
            return response.data;
        }
    });

    return (
        <>
            <PageDescription
                title="UTM Logs"
                description="A Logs section for UTM, which allows you to view the logs of the UTM in a tabular format."
            />
            <Main>{utmLogsQuery.isLoading ? <Loader2 /> : <TableNeogen entries={utmLogsQuery.data ?? []} />}</Main>
        </>
    );
}

import { useState } from "react";
import InputControlled from "../../layout/input-controlled";
import { LockClosedIcon } from "@heroicons/react/24/solid";
import logo from "../../images/logo.png";
import Loader2 from "../utilities/Loader2";
import { z } from "zod";
import { usePromiseLazy } from "../../jason-proof-of-concept/shared/hooks";
import { makeRequest } from "../../jason-proof-of-concept/shared/utils";
import ErrorSection from "../../layout/error-section";
import { Link } from "react-router-dom";

export default function ForgotPassword() {
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isComplete, setIsComplete] = useState(false);

    const action = usePromiseLazy(async () => {
        await makeRequest({ method: "post", path: `/users/reset/${email}` });
    }, []);

    const handleSubmit = async () => {
        setError("");
        if (!email) {
            setError("Email address must be provided.");
        }
        try {
            z.string().email().parse(email);
            setIsLoading(true);
            const { error } = await action.execute();
            if (!error) {
                setIsComplete(true);
            }
            setIsLoading(false);
        } catch (e) {
            setError("Please enter a valid email address.");
        }
    };

    return (
        <>
            <div className="bg-slate-300 dark:bg-slate-600 h-screen m-0 p-0 min-h-screen">
                <>
                    <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-12 ">
                        <form>
                            <div className="max-w-lg md:w-[600px] space-y-10 p-14 rounded-xl  bg-indigo-800 shadow-2xl shadow-slate-700/90 border-white/80 dark:border-slate-500 border    ">
                                <div>
                                    <img className="mx-auto h-12 w-auto" src={logo} alt="Workflow" />
                                </div>

                                {isComplete ? (
                                    <div>
                                        <p style={{ color: "white", marginTop: 10, textAlign: "center" }}>
                                            We&quot;ve sent a password reset link to your email inbox assuming its in
                                            our system.
                                        </p>
                                        <p style={{ color: "white", marginTop: 10, textAlign: "center" }}>
                                            Please check your email inbox for further instructions.
                                        </p>
                                        <p style={{ color: "white", marginTop: 10, textAlign: "center" }}>
                                            Be sure to also check your spam folder.
                                        </p>
                                    </div>
                                ) : (
                                    <>
                                        <h1 style={{ color: "white", textAlign: "center" }}>
                                            Reset your ClearERC password
                                        </h1>
                                        <p style={{ color: "white", marginTop: 10, textAlign: "center" }}>
                                            Enter your account email address below, and we&quot;ll send you a link to
                                            use to reset your account password.
                                        </p>

                                        {action.error && (
                                            <ErrorSection
                                                errors={["Failed to request password reset. Please try again."]}
                                            />
                                        )}

                                        <div className="rounded-md shadow-sm -space-y-px">
                                            {error.length > 0 && <p className="text-red-500 text-sm mb-5">{error}</p>}
                                            <div>
                                                <InputControlled
                                                    darkBackground={true}
                                                    label={"Email Address"}
                                                    type="email"
                                                    value={email}
                                                    setValue={setEmail}
                                                    name={"email"}
                                                    noDebounce={true}
                                                />
                                            </div>
                                        </div>

                                        {isLoading ? (
                                            <Loader2 />
                                        ) : (
                                            <>
                                                <div>
                                                    <button
                                                        type="button"
                                                        className={
                                                            "group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md " +
                                                            "text-white bg-indigo-600 hover:bg-indigo-500 hover:text-gray-200 focus:outline-none focus:ring-2 " +
                                                            "focus:ring-offset-2 focus:ring-indigo-500 transition-all duration-500"
                                                        }
                                                        onClick={() => {
                                                            handleSubmit();
                                                        }}
                                                    >
                                                        <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                                                            {isLoading ? (
                                                                <svg
                                                                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    fill="none"
                                                                    viewBox="0 0 24 24"
                                                                >
                                                                    <circle
                                                                        className="opacity-25"
                                                                        cx="12"
                                                                        cy="12"
                                                                        r="10"
                                                                        stroke="currentColor"
                                                                        strokeWidth="4"
                                                                    ></circle>
                                                                    <path
                                                                        className="opacity-75"
                                                                        fill="currentColor"
                                                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                                    ></path>
                                                                </svg>
                                                            ) : (
                                                                <LockClosedIcon
                                                                    className="h-5 w-5  c group-hover:text-indigo-400 transition-all duration-500"
                                                                    aria-hidden="true"
                                                                />
                                                            )}
                                                        </span>
                                                        Request password reset
                                                    </button>
                                                </div>
                                            </>
                                        )}
                                    </>
                                )}
                                <div className="flex items-center justify-between">
                                    <div className="text-sm">
                                        <Link to="/login" className="font-medium text-indigo-200 hover:text-indigo-500">
                                            Sign in
                                        </Link>
                                    </div>
                                    <div className="text-sm">
                                        <Link
                                            to="/sign-up"
                                            className="font-medium text-indigo-200 hover:text-indigo-500"
                                        >
                                            Create a new Account
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </>
            </div>
        </>
    );
}

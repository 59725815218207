/* eslint-disable no-case-declarations */
import { useEffect, useRef, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { Quill } from "react-quill";
import { useParams, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import authService from "../../services/auth.service";
import processflowStageService from "../../services/processflow-stage.service";
import processflowService from "../../services/processflow.service";
import { ProcessFlow } from "../../typings/api/processflow";
import { ProcessFlowProgressData } from "../../typings/api/processflow-progress-data";
import Loader2 from "../utilities/Loader2";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ImageActions } from "@xeger/quill-image-actions/dist/main";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ImageFormats } from "@xeger/quill-image-formats/dist/main";
import { useRecoilSnapshot, useRecoilState } from "recoil";
import loadedAtom from "../../atoms/loadedAtom";
import progressDataAtom from "../../atoms/progressDataAtom";
//import stageAtom from "../../atoms/stageAtom";
import userAtom from "../../atoms/userAtom";
import { ClearERCUser } from "../../typings/api/clear-erc-user";
import useRulePasses from "../business-rules/hooks/use-rule-passes";
import useStepValidation from "./hooks/use-step-validation";
import ProcessflowDebug from "./processflow-debug";
import FormFill from "./components/form-fill";
import positionAtom, { Position } from "../../atoms/positionAtom";
import _ from "lodash";
import savingAtom, { SaveType } from "../../atoms/savingAtom";
import pfDebugAtom from "../../atoms/pfDebugAtom";
import stepAtom from "../../atoms/stepAtom";
import groupAtom from "../../atoms/groupAtom";
import enabledEntriesAtom from "../../atoms/enabledEntriesAtom";

// Quill.register("modules/imageActions", ImageActions);
// Quill.register("modules/imageFormats", ImageFormats);

type ProcessFlowWizardProps = {
    id?: number;
    showTabs?: boolean;
    showStages?: boolean;
    isPublic?: boolean;
};

export default function ProcessFlowWizard(props: ProcessFlowWizardProps) {
    const ref = useRef<HTMLDivElement>(null);
    const [position, setPosition] = useRecoilState<Position>(positionAtom);
    const [group, setGroup] = useRecoilState<number>(groupAtom);
    const [user, setUser] = useRecoilState<ClearERCUser>(userAtom);
    const [saving, setSaving] = useRecoilState<SaveType>(savingAtom);
    // const [enabledEntries, setEnabledEntries] = useState<ProcessFlow[]>([]);
    const [enabledEntries, setEnabledEntries] = useRecoilState<ProcessFlow[]>(enabledEntriesAtom);
    const [canSkipStages, setCanSkipStages] = useState<boolean>(false);
    const [loaded, setLoaded] = useRecoilState<boolean>(loadedAtom);
    // const [pfDebug, setPfDebug] = useState<boolean>(false);
    const [pfDebug, setPfDebug] = useRecoilState<boolean>(pfDebugAtom);
    const [searchParams] = useSearchParams();
    const [checkStepValidation] = useStepValidation();
    // const [position, setPosition] = useRecoilState<Position>(positionAtom);
    const [currentEntry, setCurrentEntry] = useRecoilState<ProcessFlow>(stepAtom);
    let { processflow: id } = useParams();
    const [ready, setReady] = useState<{ ready1: boolean; ready2: boolean; ready3: boolean; ready4: boolean }>({
        ready1: false,
        ready2: false,
        ready3: false,
        ready4: false,
    });

    if (!id) {
        id = (props.id ?? "-1").toString();
    }

    const { debugErrors, checkIsLoadingRuleChecks } = useRulePasses(Number(id));

    // TODO: bring this back
    // const {getProgress, } = useCurrentProgress(Number(id), props.isPublic??false);

    /**
     * This function is called when the component is mounted.
     * It checks if the user has the ability to skip stages and sets the
     * canSkipStages state accordingly.
     * @returns None
     */
    useEffect(() => {
        authService
            .canIAccess("SKIP_STAGES")
            .then((r) => {
                setCanSkipStages(r);
            })
            .catch(() => {
                setCanSkipStages(false);
            });
        authService
            .canIAccess("SEE_PF_DEBUG")
            .then((r) => {
                setPfDebug(r);
            })
            .catch(() => {
                setPfDebug(false);
            });
    }, [setPfDebug]);

    useEffect(() => {
        console.error("Setting group to " + id);
        setGroup(Number(id));
    }, [id, setGroup]);

    const allStagesForGroupQuery = useQuery(processflowStageService.getCacheKey({ group: id ?? "" }), async () => {
        const response = await processflowStageService.getAllByGroupIdIncludingRuleGroups(Number(id));
        setLoaded(true);
        if (response) {
            // console.error({ stages: response.data });
            return response.data;
        } else {
            Swal.fire({
                title: "Error",
                text: "Could not load process flow stages",
                icon: "error",
                showConfirmButton: true,
            });
        }
    });

    /**
     * Queries the process flow entries for the current stage and group.
     *
     * @param {number} position.stageId - the current stage of the process flow
     * @param {string} id - the id of the group that the process flow is for
     * @returns {ProcessFlow[]} - the process flow entries for the current stage and group
     */
    const allEntriesForStageAndGroupQuery = useQuery(
        processflowService.getCacheKey({
            currentStage: position.stageId?.toString(),
            processFlowGroup: id ?? "",
            relation: "relation",
            include: "businessRules",
        }),
        async () => {
            if (position.stageId === -1) {
                console.error("No current stage");
                return;
            }

            const response = await processflowService.getAllByOrderForStageAndGroup(position.stageId, Number(id));
            if (response && response.data) {
                // console.info({ response })
                return response.data;
            }
            // return [];
        },
    );

    /**
     * Sets the current entry to the entry with the given id.
     *
     * @param {number} position.step - the id of the entry to set as the current entry.
     * @param {Entry[]} enabledEntries - the list of entries that are enabled.
     * @returns None
     */
    useEffect(() => {
        if ((allEntriesForStageAndGroupQuery?.data ?? []).length > 0) {
            console.log("Looking for current step: " + position.stepId + " in ", allEntriesForStageAndGroupQuery?.data);
            if ((allEntriesForStageAndGroupQuery?.data ?? []).find((e) => e.id === position.stepId)) {
                const entry = (allEntriesForStageAndGroupQuery?.data ?? []).find((e) => e.id === position.stepId);
                if (entry) {
                    setCurrentEntry(entry);
                }

                // console.log("Set to x");
            } else {
                const entry = allEntriesForStageAndGroupQuery?.data?.[0];
                if (entry) {
                    setCurrentEntry(entry);
                }

                // if (position.step !== -1) {
                //     // alert("not found: " + position.step)

                //     const newTab = allEntriesForStageAndGroupQuery?.data?.[0]?.id;
                //     // console.error({ newTab });
                //     alert("setposition.step(newTab ?? -1);");
                // }
            }
        }
        // else {
        // console.error("No enabled entries");
        // }
    }, [position.stepId, allEntriesForStageAndGroupQuery.data, setCurrentEntry]);

    async function sleep(ms: number) {
        return new Promise((res) => setTimeout(res, ms));
    }

    // }, [position.step, initial]);

    useEffect(() => {
        async function doItAll() {
            // console.error(position.stageId);
            let x = 0;
            while (!loaded) {
                console.error("Not loaded");
                await sleep(1000);
                // return;
                x++;
                if (x > 10) {
                    return;
                }
            }
            if (position.stageId <= 0) {
                console.error("position.stageId <= 0");
                return;
            }
            if (!allStagesForGroupQuery.isSuccess) {
                console.error("stageQuery not success");
                return;
            }
            /**
             * If there are no entries for the current stage and group, display an error message.
             */
            if ((allEntriesForStageAndGroupQuery.data ?? []).length === 0) {
                if (allEntriesForStageAndGroupQuery.isSuccess) {
                    Swal.fire({
                        title: "No entries found",
                        text:
                            "Stage: " +
                            allStagesForGroupQuery.data?.find((i) => i.id === position.stageId)?.name +
                            " (" +
                            position.stageId +
                            ") " +
                            JSON.stringify(allEntriesForStageAndGroupQuery.data),
                        icon: "error",
                        confirmButtonText: "OK",
                    });
                }
                return;
            } else {
                const enabledEntriesCalc = allEntriesForStageAndGroupQuery.data?.map((entry: ProcessFlow) => {
                    const entryCloned = _.cloneDeep(entry);
                    const newFields = {
                        id: entryCloned.id,
                        name: entryCloned.title,
                        current: entryCloned.id === position.stepId,
                    };
                    Object.assign(entryCloned, newFields);
                    return entryCloned;
                });

                setEnabledEntries(enabledEntriesCalc ?? []);
                // setEnabledEntries( []);
            }
        }
        doItAll();
    }, [
        allEntriesForStageAndGroupQuery.data,
        id,
        allEntriesForStageAndGroupQuery.isSuccess,
        loaded,
        position.stageId,
        allStagesForGroupQuery.isSuccess,
        allStagesForGroupQuery.data,
        position.stepId,
        setEnabledEntries,
    ]);

    // TODO: Bring th\s
    // useEffect(() => {
    //     console.log("Loading");
    //     // load in all the questions and validations for this script and then set them to the state
    //     getProgress(Number(id), (user.id ?? "")).then((pr:any) => {
    //         if (pr) {
    //             setProgressData(pr);
    //         }
    //     });
    // }, [getProgress, id, setProgressData, user.id]);

    return (
        <div className="min-h-">
            <>
                {pfDebug && (
                    <ProcessflowDebug
                        pfDebug={pfDebug}
                        setPfDebug={setPfDebug}
                        saving={saving.saving}
                        currentStageName={
                            allStagesForGroupQuery.data?.find((e) => e.id === position.stageId)?.name ?? ""
                        }
                        currentStepName={currentEntry?.title ?? ""}
                        debugErrors={debugErrors}
                        id={Number(id)}
                        isPublic={props.isPublic ?? false}
                    />
                )}
                <div className={"max-w-full  flex " + (props.showStages !== false ? "md:gap-6" : "")}>
                    {!loaded ||
                    checkIsLoadingRuleChecks() ||
                    allStagesForGroupQuery.isLoading ||
                    allEntriesForStageAndGroupQuery.isLoading ? (
                        <>
                            <Loader2 />
                        </>
                    ) : (
                        <>
                            <FormFill
                                isPublic={props.isPublic}
                                id={Number(id)}
                                showTabs={!(props.showTabs === false)}
                                processFlow={allEntriesForStageAndGroupQuery.data?.find(
                                    (e) => e.id === position.stepId,
                                )}
                            />
                        </>
                    )}
                </div>
            </>
        </div>
    );
}

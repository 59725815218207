import _ from "lodash";
import { useEffect, useState } from "react";
import { Entry } from "../../../layout/add-to-list";
import InputControlled from "../../../layout/input-controlled";
import ModalDialog from "../../../layout/modal-dialog";
import SelectNeoGen from "../../../layout/select-neogen";
import { Calculation } from "./modal-value-calculator";

export default function AddCalculation(props: AddCalculationProps) {
    const [options, setOptions] = useState<Entry[]>([]);
    const [field1number, setField1number] = useState<string>("");
    const [field2number, setField2number] = useState<string>("");
    const [field1, setField1] = useState<string>("");
    const [field2, setField2] = useState<string>("");
    const [operator, setOperator] = useState<string>("");

    useEffect(() => {
        const newOptions = _.cloneDeep(props.entries)
            .filter((entry: Entry) => entry.type !== "wysiwyg")
            .map((entry: Entry) => {
                return {
                    id: entry.field,
                    name: entry.field,
                };
            });
        newOptions.push({
            name: "** A Number",
            id: "**num",
        });
        props.calculations.forEach((calculation: Calculation, idx: number) => {
            newOptions.push({
                name: calculation.field1 + " " + calculation.operator + " " + calculation.field2,
                id: "Calculation " + (idx + 1),
            });
        });
        setOptions(newOptions);
        setField1("0");
        setField2("0");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.show]);
    return (
        <ModalDialog
            close={props.close}
            show={props.show}
            title={"Add Calculation " + (Number((props.calculations ?? []).length) + 1)}
            okAction={() => {
                props.addCalculation(field1, field2, operator, field1number, field2number);
            }}
        >
            <SelectNeoGen
                label="Field 1"
                options={options || []}
                value={field1}
                onChange={(value: string | number) => {
                    setField1(value as string);
                }}
            />
            <div className="mt-5">
                {field1 === "**num" && (
                    <InputControlled
                        label={"Field 1 Number"}
                        name={"field1number"}
                        value={field1number}
                        setValue={(value: string) => setField1number(value)}
                        description="The number you would like to use"
                    />
                )}
            </div>

            <SelectNeoGen
                label={"Math Operator"}
                options={[
                    { id: "+", name: "Plus" },
                    { id: "-", name: "Minus" },
                    { id: "*", name: "Times" },
                    { id: "/", name: "Divided by" },
                ]}
                onChange={(value: string | number) => {
                    setOperator(value as string);
                }}
            />
            <SelectNeoGen
                label="Field 2"
                options={options}
                value={field2}
                onChange={(value: string | number) => {
                    setField2(value as string);
                }}
            />

            <div className="mt-5">
                {field2 === "**num" && (
                    <InputControlled
                        label={"Field 2 Number"}
                        name={"field2number"}
                        value={field2number}
                        setValue={(value: string) => setField2number(value)}
                        description="The number you would like to use"
                    />
                )}
            </div>
        </ModalDialog>
    );
}

type AddCalculationProps = {
    show: boolean;
    close: () => void;
    entries: any;
    addCalculation: (
        field1: string,
        field2: string,
        operator: string,
        field1number?: string,
        field2number?: string,
    ) => void;
    calculations: Calculation[];
};

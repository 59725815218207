import { atom, DefaultValue } from "recoil";
import { ProcessFlow } from "../typings/api/processflow";
import { ProcessFlowProgressData } from "../typings/api/processflow-progress-data";
import { ProcessFlowStage } from "../typings/api/processflow-stage";
import { localStorageEffect } from "./local-storage-effect";

const processflowProgressIdAtom = atom<number>({
    key: "processflowProgressIdAtom",
    default: -1,
    effects: [localStorageEffect("processflowProgressIdAtom")],
});

export default processflowProgressIdAtom;

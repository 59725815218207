import PageDescription from "../../layout/page-description";

export default function Application() {
    return (
        <PageDescription
            title="Your ERC Applications"
            description="This is the list of all your ERC Applications. You can view, edit, and delete them here."
        />
    );
}

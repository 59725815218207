import { useController } from "react-hook-form";
import { GetFieldPropsReturn } from "../../hooks/useForm";
import { Field, FormFieldProps } from "./field";
import { TextInput, TextInputProps } from "./text-input";

export const TextField = ({
    name,
    form,
    isRequired,
    label,
    ...other
}: FormFieldProps<string> & GetFieldPropsReturn<any> & TextInputProps) => {
    const { field, fieldState } = useController({
        name,
        control: form.control,
        rules: { required: isRequired ? "This field is required" : undefined },
        defaultValue: other.defaultValue || "",
    });
    const error = fieldState.error?.message;
    return (
        <Field label={label} isRequired={isRequired} helpText={other.helperText} error={error}>
            <TextInput {...other} {...field} hasError={!!error} />
        </Field>
    );
};

/* eslint-disable @typescript-eslint/no-unsafe-call */
import { FieldValues, Path, useForm as uf, UseFormProps, UseFormReturn } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { ZodSchema } from "zod";

export type GetFieldPropsReturn<T extends FieldValues> = {
    name: Path<T>;
    form: UseFormReturn<T>;
    isRequired?: boolean;
};

export type GetFieldProps<T extends FieldValues> = (name: Path<T>) => GetFieldPropsReturn<T>;

export const useForm = <T extends FieldValues>(
    props?: UseFormProps<T> & { schema?: ZodSchema<T> },
): UseFormReturn<T> & {
    getFieldProps: GetFieldProps<T>;
} => {
    const form = uf<T>({
        ...props,
        resolver: props?.schema ? zodResolver(props.schema) : undefined,
    });

    return {
        ...form,
        getFieldProps: (name: Path<T>): GetFieldPropsReturn<T> => {
            return {
                form,
                name,
                isRequired: !(props as any)?.schema?.shape?.[name]?.isOptional(),
            };
        },
    };
};

export const getSubmitButtonProps = (form: any) => {
    return {
        isDisabled: !form.formState.isDirty || (form.formState.submitCount > 0 && !form.formState.isValid),
    };
};

import { useState } from "react";
import { SaveDetailsType } from "../typings/save-details-type";
import { Entry } from "./add-to-list";
import BadgeDismissible from "./badge-dismissible";
import ButtonNeoGen from "./button";
import InputControlled from "./input-controlled";
import ModalDialog from "./modal-dialog";
import ModalSingleText from "./modal-single-text";
import PrintPre from "./print-pre";
import SelectNeoGen from "./select-neogen";
import TextAreaNeoGenControlled from "./text-area-controlled";

export default function ModalFieldValue(props: ModalFieldValueProps) {
    const [field, setField] = useState<string>(props.field ?? "");
    const [label, setLabel] = useState<string>(props.value ?? "");
    const [description, setDescription] = useState<string>(props.description ?? "");
    const [selectBoxEntries, setSelectBoxEntries] = useState<string[]>(
        props.entry?.selectBoxEntries ? props.entry.selectBoxEntries : [],
    );
    const [showAddEntry, setShowAddEntry] = useState<boolean>(false);
    const [required, setRequired] = useState<boolean>(props.entry?.required ?? false);
    const [password, setPassword] = useState<boolean>(props.entry?.password ?? false);
    const [widthOf300Cols, setWidthOf300Cols] = useState<string>(props.entry?.widthOf300Cols ?? "150");
    function saveRecord() {
        if (props.isEdit && props.saveEdit) {
            // // { field, value, description, required, widthOf300Cols, selectBoxEntries, helpDocument, password, ruleGroupsAreOr }
            props.saveEdit({
                field,
                value: label,
                description,
                required,
                widthOf300Cols,
                selectBoxEntries,
                helpDocument: props.entry?.helpDocument ?? "",
                password,
                ruleGroupsAreOr: false,
            });
        } else {
            // save: ({ field, value, description, required, widthOf300Cols, selectBoxEntries, helpDocument, password, ruleGroupsAreOr }: SaveDetailsType) => void;
            // save: ({ field, value, description, required, widthOf300Cols, selectBoxEntries, helpDocument, password, ruleGroupsAreOr }: SaveDetailsType) => void;
            props.save({
                field,
                value: label,
                description,
                required,
                widthOf300Cols,
                selectBoxEntries,
                helpDocument: props.entry?.helpDocument ?? "",
                password,
                ruleGroupsAreOr: false,
            });
        }
    }

    function addEntry() {
        setShowAddEntry(true);
    }

    return (
        <>
            <ModalDialog
                title={props.title}
                close={props.close}
                size="md"
                show={props.show}
                okAction={saveRecord}
                okText="Close"
                showCancel={false}
            >
                <InputControlled
                    label={"Field Name"}
                    name={props.name}
                    value={field}
                    setValue={(value: string) => setField(value)}
                    description="This is how it will be stored in the database. Keep it short but you can't have two field names the same in the system."
                />

                <InputControlled
                    label={"Label"}
                    name={props.label}
                    value={label}
                    setValue={(value: string) => setLabel(value)}
                    description="This is the text that appears above the field (like the word Label). It should be short like first name etc."
                />
                <TextAreaNeoGenControlled
                    label={"Description Line"}
                    name={props.description}
                    value={description}
                    description="You're currently reading a description line. This sits below the field and should give enough information so 
                    that somebody can understand what it is you want them to put in the field or instructions regarding how it should be formatted"
                    setValue={(value: string) => setDescription(value)}
                />
                <SelectNeoGen
                    label={"Is this required?"}
                    className="mb-5"
                    options={[
                        { id: 1, name: "Yes" },
                        { id: 0, name: "No" },
                    ]}
                    onChange={(value: number | string) => setRequired(value === 1)}
                    value={required ? 1 : 0}
                />
                <SelectNeoGen
                    label={"Is this a password field?"}
                    className="mb-5"
                    options={[
                        { id: 1, name: "Yes" },
                        { id: 0, name: "No" },
                    ]}
                    onChange={(value: number | string) => setPassword(value === 1)}
                    value={password ? 1 : 0}
                />
                <SelectNeoGen
                    className="mt-5"
                    label={"Width"}
                    options={[
                        { id: "50", name: "1/5 page" },
                        { id: "75", name: "1/4 page" },
                        { id: "100", name: "1/3 page" },
                        { id: "150", name: "1/2 page" },
                        { id: "300", name: "Full Width" },
                    ]}
                    onChange={(value: number | string) => setWidthOf300Cols(value.toString())}
                    value={widthOf300Cols}
                />
                {/* <PrintPre>
                    {props}
                </PrintPre> */}
                {props.isSelect && (
                    <>
                        <div className="my-5">
                            {selectBoxEntries.map((entry: string, index: number) => (
                                <BadgeDismissible
                                    isLarge={true}
                                    key={index}
                                    label={entry}
                                    onRemove={() => {
                                        setSelectBoxEntries(
                                            selectBoxEntries.filter(
                                                (_entry: string, _index: number) => index !== _index,
                                            ),
                                        );
                                    }}
                                />
                            ))}
                        </div>
                        <ButtonNeoGen onClick={() => addEntry()} text="Add Option" />
                    </>
                )}
                <ModalSingleText
                    title="Add Entry"
                    label="Entry"
                    name="entry"
                    show={showAddEntry}
                    close={() => setShowAddEntry(false)}
                    save={(value: string) => {
                        setSelectBoxEntries([...selectBoxEntries, value]);
                        setShowAddEntry(false);
                    }}
                />
            </ModalDialog>
        </>
    );
}

type ModalFieldValueProps = {
    title: string;
    show: boolean;
    close: () => void;
    label: string;
    description: string;
    name: string;
    isEdit?: boolean;
    field?: string;
    value?: string;
    widthOf300Cols: string;
    save: ({
        field,
        value,
        description,
        required,
        widthOf300Cols,
        selectBoxEntries,
        helpDocument,
        password,
        ruleGroupsAreOr,
    }: SaveDetailsType) => void;
    saveEdit?: ({
        field,
        value,
        description,
        required,
        widthOf300Cols,
        selectBoxEntries,
        helpDocument,
        password,
        ruleGroupsAreOr,
    }: SaveDetailsType) => void;
    isSelect?: boolean;
    entry: Entry | undefined;
};

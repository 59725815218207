const FILE_API_URL = process.env.REACT_APP_FILE_SERVER ?? process.env.REACT_APP_API_URL ?? "https://api.clearerc.com/";

export function downloadFile(url: string, originalName: string) {
    const a = document.createElement("a");
    a.href = FILE_API_URL + "/files/" + url;
    a.download = originalName ?? "#";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}

/**
 *
 *
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 8/25/20, 6:24 AM
 *
 */

import { BusinessProduct } from "../typings/api/business-product";
import APIService from "./api.service";

// const debug        = require('debug')('Neogen:CDRService');

class BusinessProductService extends APIService<BusinessProduct> {
    constructor() {
        super("business-products");
    }
    getAllIncludingChildren() {
        const filter = {
            include: [
                {
                    relation: "businessRuleGroups",
                    scope: {
                        include: [
                            {
                                relation: "businessRules",
                            },
                        ],
                    },
                },
            ],
        };
        return this.getFiltered(filter);
    }
    async getOneIncludingChildren(id: number) {
        const filter = {
            where: { id: id },
            include: [
                {
                    relation: "businessRuleGroups",
                    scope: {
                        include: [
                            {
                                relation: "businessRules",
                            },
                        ],
                    },
                },
            ],
        };
        const result = await this.getFiltered(filter);
        // return result.data[0];
        if (result && result.data?.length > 0) {
            return result.data?.[0];
        }
    }
}

export default new BusinessProductService();

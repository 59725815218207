import { atom } from "recoil";
import { localStorageEffect } from "./local-storage-effect";

const positionAtom = atom({
    key: "positionAtom",
    default: {
        stageId: -1,
        stepId: -1,
        // processflowProgressId: -1,
    },
    effects: [localStorageEffect("positionAtom")],
});

export default positionAtom;

export type Position = {
    stageId: number;
    stepId: number;
    // processflowProgressId?: number, // The ID of the current progress
};

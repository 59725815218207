/* eslint-disable prettier/prettier */
/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { useState } from "react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Combobox } from "@headlessui/react";
import ButtonNeoGen from "./button";

// const people = [
//     {
//         id: 1,
//         name: "Leslie Alexander",
//         imageUrl:
//             "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
//     },
//     // More users...
// ];

function classNames(...classes: (string | boolean)[]) {
    return classes.filter(Boolean).join(" ");
}

type Entry = {
    id?: number | string;
    name?: string | null;
};

type FilterProps = {

    options?: (unknown & Entry)[];
    label?: string;
    value?: number | string;
    onChange: (idx: number | string, text?: string) => void;
    className?: string;
    description?: string;
    ref?: React.Ref<any>;
    hideReset?: boolean;
};


export default function Filter(props: FilterProps) {
    const [query, setQuery] = useState("");
    // const [selectedPerson, setSelectedPerson] = useState(null);

    const filteredPeople =
        (query === ""
            ? props.options
            : (props.options ?? []).filter((person: any) => {
                return (person.name ?? "").toLowerCase().includes(query.toLowerCase());
            })) ?? [];

    return (
        <div className="flex">
            <Combobox
                className={"flex-grow"}
                as="div" value={(props.options ?? []).find(i => i.id === props.value)} onChange={(idx: any) => {
                    // alert(idx.id)
                    props.onChange(idx.id);
                }}>
                <Combobox.Label className="block text-sm  text-gray-500">{props.label}</Combobox.Label>
                <div className="relative mt-1">
                    <Combobox.Input
                        className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
                        onChange={(event) => setQuery(event.target.value)}
                        displayValue={(person: any) => person?.name}
                    />
                    <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                        <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </Combobox.Button>

                    {filteredPeople.length > 0 && (
                        <Combobox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {filteredPeople.map((person) => (
                                <Combobox.Option
                                    key={person.id}
                                    value={person}
                                    className={({ active }) =>
                                        classNames(
                                            "relative cursor-default select-none py-2 pl-3 pr-9",
                                            active ? "bg-indigo-600 text-white" : "text-gray-900",
                                        )
                                    }
                                >
                                    {({ active, selected }) => (
                                        <>
                                            <div className="flex items-center">
                                                {/* <img
                                                src={person.imageUrl}
                                                alt=""
                                                className="h-6 w-6 flex-shrink-0 rounded-full"
                                            /> */}
                                                <span className={classNames("ml-3 truncate", selected && "font-semibold")}>
                                                    {person.name}
                                                </span>
                                            </div>

                                            {selected && (
                                                <span
                                                    className={classNames(
                                                        "absolute inset-y-0 right-0 flex items-center pr-4",
                                                        active ? "text-white" : "text-indigo-600",
                                                    )}
                                                >
                                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                </span>
                                            )}
                                        </>
                                    )}
                                </Combobox.Option>
                            ))}
                        </Combobox.Options>
                    )}
                </div>
            </Combobox>
            {props.value && !props.hideReset && (
                <div className="mt-auto ml-5 h-full">
                    <ButtonNeoGen
                        onClick={() => {
                            props.onChange("");
                        }
                        }
                        text="Reset Filter"
                    />
                </div>
            )}

        </div>
    );
}

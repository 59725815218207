import { EllipsisVerticalIcon } from "@heroicons/react/24/solid";
import { useState } from "react";
import { QueryClient, useQuery, useQueryClient } from "@tanstack/react-query";
import { NavLink } from "react-router-dom";
import Swal from "sweetalert2";
import processflowGroupService from "../../services/processflow-group.service";
import processflowStageService from "../../services/processflow-stage.service";
import processflowService from "../../services/processflow.service";
import BoxChoices from "../../layout/box-choices";
import PageDescription from "../../layout/page-description";
import Loader2 from "../utilities/Loader2";
import AddEditGroup, { Group } from "./modals/add-edit-group";

export default function ProcessFlowGroup() {
    const [showAddEditProcessFlowGroup, setShowAddEditProcessFlowGroup] = useState(false);
    const [group, setGroup] = useState<Group | null>(null);
    // const [isEdit, setIsEdit] = useState(false);
    const cache = useQueryClient();

    const groupQuery = useQuery(["processflow-groups"], async () => {
        const response = await processflowGroupService.getAll();
        if (response) {
            return response.data;
        }
    });

    return (
        <>
            <div className="">
                <PageDescription
                    title="Process Flow"
                    description={
                        "Progress Flows are the main way to manage your workflow. You can create a process flow for each of your client types, and then assign it to a client."
                    }
                    // description2={"Each group can suit different types of processes. For example, you can create a group for a process flow that is used for a specific type of user. "}
                    buttons={[
                        {
                            label: "Create Process Flow",
                            onClick: () => {
                                // setIsEdit(false)
                                setShowAddEditProcessFlowGroup(true);
                            },
                        },
                    ]}
                />
            </div>
            {groupQuery.isLoading ? (
                <Loader2 />
            ) : (
                <>
                    <BoxChoices
                        icon="fa-list-check"
                        urlBase="/processflow/"
                        data={groupQuery.data ?? []}
                        deleteFunction={(groupId: number | string) => {
                            // alert("hi")
                            // e.stopPropagation();
                            // e.preventDefault();
                            Swal.fire({
                                title: "Are you sure?",
                                text: "You won't be able to revert this!",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#3085d6",
                                confirmButtonText: "Yes, delete it!",
                            }).then(async (result) => {
                                if (result.value) {
                                    try {
                                        await processflowGroupService.deleteByID(Number(groupId));
                                    } catch (e) {
                                        console.log(e);
                                    }
                                    try {
                                        await processflowService.deleteByGroupId(Number(groupId));
                                    } catch (e) {
                                        console.log(e);
                                    }
                                    try {
                                        await processflowStageService.deleteByGroupId(Number(groupId));
                                    } catch (e) {
                                        console.log(e);
                                    }
                                    cache.invalidateQueries(["processflow-stages", groupId]);
                                    cache.invalidateQueries(["processflows"]);
                                    cache.invalidateQueries(["processflow-groups"]);
                                    Swal.fire({
                                        title: "Deleted!",
                                        text: "Your process flow has been deleted.",
                                        icon: "success",
                                        showConfirmButton: false,
                                        timer: 1500,
                                    });
                                }
                            });
                        }}
                    />
                    <AddEditGroup
                        isEdit={false}
                        show={showAddEditProcessFlowGroup}
                        close={() => setShowAddEditProcessFlowGroup(false)}
                    />
                </>
            )}
        </>
    );
}

import { useEffect, useState } from "react";
import AddCompanyModal from "./modals/add-edit-company-modal";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import Loader2 from "../utilities/Loader2";
import { Company } from "../../typings/api/company";
import PageDescription from "../../layout/page-description";
import authService from "../../services/auth.service";
import TableNeogen from "../../layout/table-neogen";
import Swal from "sweetalert2";
import companyService from "../../services/company.service";

function compare(a: any, b: any) {
    if (a.name?.toLowerCase() < b.name?.toLowerCase()) {
        return -1;
    }
    if (a.name?.toLowerCase() > b.name?.toLowerCase()) {
        return 1;
    }
    return 0;
}

/**
 * @component
 */
function Companies() {
    const [showDelete, setShowDelete] = useState(false);
    const [showAdd, setShowAdd] = useState(false);
    const [currentCompany, setCurrentCompany] = useState<Company | null>(null);
    const queryCache = useQueryClient();
    const [canIAccess, setCanIAccess] = useState(false);

    useEffect(() => {
        authService.canIAccess("LIST_ALL_COMPANIES").then((r) => {
            setCanIAccess(r);
        });
    }, []);

    const companiesQuery = useQuery(
        ["companies"],
        async () => {
            const response = await companyService.getAll();
            if (response) {
                const tempResponse = response.data.sort(compare).map((c) => {
                    const result: Company = {
                        name: c.name,
                        address: c.address,
                        city: c.city,
                        state: c.state,
                        country: c.country,
                        phone: c.phone,
                        email: c.email,
                        website: c.website,
                        ownedBy: c.ownedBy,
                        id: c.id,
                    };
                    return result;
                });
                return tempResponse;
            }
        },
        {
            cacheTime: 60000,
            staleTime: 60000,
            // refetchOnMount:       false,
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
        },
    );

    //
    // useEffect(() => {
    //     loadData();
    // }, [loadData]);

    function handleClose() {
        setShowDelete(false);
    }

    // function invalidateCache() {
    //     queryCache.invalidateQueries(["companies"]).then(r => {
    //         console.log(r);
    //         console.log("Invalidated");
    //     });
    // }

    // function deleteCompany() {
    //     setDeleting(true);
    //     console.log("deleteCompany");
    //     console.log(currentCompany);

    //     if (currentCompany) {
    //         CompanyService.deleteByID(currentCompany.id).then(r => {
    //             invalidateCache();
    //             handleClose();
    //             setDeleting(false);
    //         });
    //     }

    // }

    function handleAddClose() {
        setShowAdd(false);
    }

    return (
        <>
            <PageDescription
                title="Companies"
                description2="This is a list of all companies. You can add, edit and delete companies."
                buttons={[
                    {
                        label: "Add Company",
                        icon: "fas fa-plus",
                        onClick: () => {
                            console.log("Showing Add Company");
                            setCurrentCompany(null);
                            setShowAdd(true);
                        },
                        disabled: !canIAccess,
                    },
                ]}
            >
                {/* <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <p className="text-xl font-bold text-gray-600 sm:text-2xl dark:text-gray-400 mb-3">Companies</p>
                        <p className="mb-5">A list of all the companies in your account</p>
                    </div>
                    {canIAccess && (
                        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                            <button
                                type="button"
                                className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                                // onClick={() => showAddEditUser(null)}
                                onClick={() => {
                                    console.log("Showing Add Company");
                                    setCurrentCompany(null);
                                    setShowAdd(true);
                                }}
                            >
                                Add company
                            </button>
                        </div>
                    )}
                </div> */}
            </PageDescription>
            <div className=" rounded-xl shadow-md">
                {companiesQuery.isLoading ? (
                    <div className={"p-5 text-center"}>
                        <Loader2 />
                    </div>
                ) : (
                    <>
                        <TableNeogen
                            //Address	City	Country	Email	Owned By	Name	Phone	State	Website	Default Folder

                            formatters={[
                                {
                                    field: "ownedBy",
                                    type: "Company",
                                },
                                {
                                    field: "phone",
                                    type: "Phone",
                                },
                            ]}
                            ignoreFields={["ownedBy"]}
                            entries={companiesQuery.data ?? []}
                            actionsAreDropDown={true}
                            actions={[
                                {
                                    label: "Edit",
                                    icon: "fas fa-pencil",
                                    onClick: (company: number) => {
                                        console.log("Edit company");
                                        console.log(company);
                                        setCurrentCompany(companiesQuery.data?.find((c) => c.id === company) ?? null);
                                        setShowAdd(true);
                                    },
                                },
                                {
                                    label: "Delete",
                                    onClick: (company: number) => {
                                        setCurrentCompany(companiesQuery.data?.find((c) => c.id === company) ?? null);
                                        Swal.fire({
                                            title: "Are you sure?",
                                            text: "You won't be able to revert this!",
                                            icon: "warning",
                                            showCancelButton: true,
                                            confirmButtonText: "Yes, delete it!",
                                            confirmButtonColor: "#3085d6",
                                            cancelButtonColor: "#d33",
                                        }).then((result) => {
                                            if (result.value) {
                                                companyService.deleteByID(Number(company)).then((r) => {
                                                    queryCache.invalidateQueries(["companies"]);
                                                    Swal.fire({
                                                        title: "Deleted!",
                                                        text: "The company has been deleted.",
                                                        icon: "success",
                                                        showConfirmButton: false,
                                                        timer: 1500,
                                                    });
                                                });
                                            }
                                        });
                                    },
                                    className: "bg-red-600 text-white",
                                    icon: "fas fa-trash",
                                    disabled: (entry: Company) => {
                                        return false;
                                    },
                                },
                            ]}
                        />
                    </>
                )}
            </div>

            {/* <DeleteCompanyModal show={showDelete} handleClose={handleClose} deleteCompany={deleteCompany}
                deleting={deleting} /> */}
            {showAdd && (
                <AddCompanyModal
                    show={showAdd}
                    handleClose={handleAddClose}
                    company={currentCompany}
                    isEdit={currentCompany != null}
                />
            )}
        </>
    );
}

export default Companies;

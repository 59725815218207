/**
 *
 *
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 8/25/20, 6:24 AM
 *
 */

import { NeogenRoles } from "../typings/api";
import APIService from "./api.service";

// const debug        = require('debug')('Neogen:CDRService');

class RoleService extends APIService<NeogenRoles> {
    constructor() {
        super("roles");
    }
}

export default new RoleService();

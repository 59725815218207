import { Clients } from "../typings/api/clients";
import APIService from "./api.service";

class ClientsService extends APIService<Clients> {
    constructor() {
        super("clients");
    }
    async getAllWithRelations(companyCreatedId: number) {
        const filter = {
            where: {
                companyCreatedId,
            },
            include: ["salesUser", "accountManager", "companyCreated", "payrollContact"],
        };
        const result = await this.getFiltered(filter);
        if (result) {
            result.data = result.data.map((client) => {
                if (!client.companyCreated) {
                    client.companyCreated = {};
                }
                if (!client.payrollContact) {
                    client.payrollContact = {
                        name: "",
                        email: "",
                        phone: "",
                    };
                }
                if (client.salesUser && typeof client.salesUser !== "string") {
                    console.log(client.salesUser);
                    delete client.salesUser.password;
                    delete client.salesUser.emailVerified;
                    delete client.salesUser.lastLoggedIn;
                    delete client.salesUser.id;
                    delete client.salesUser.realm;
                    delete client.salesUser.stPass;
                    delete client.salesUser.stCompany;
                    delete client.salesUser.stUrl;
                    delete client.salesUser.verificationToken;
                } else {
                    client.salesUser = {
                        name: "",
                        email: "",
                        company: -1,
                    };
                }

                if (client.accountManager && typeof client.accountManager !== "string") {
                    delete client.accountManager.password;
                    delete client.accountManager.emailVerified;
                    delete client.accountManager.lastLoggedIn;
                    delete client.accountManager.id;
                    delete client.accountManager.realm;
                    delete client.accountManager.stPass;
                    delete client.accountManager.stCompany;
                    delete client.accountManager.stUrl;
                    delete client.accountManager.verificationToken;
                } else {
                    client.accountManager = {
                        name: "",
                        email: "",
                        company: -1,
                    };
                }

                const newClient: Clients = {
                    id: client.id,
                    salesUserId: client.salesUserId,
                    salesUser: client.salesUser,
                    accountManager: client.accountManager,
                    accountManagerId: client.accountManagerId,
                    email: client.email,
                    name: client.name,
                    phone: client.phone,
                    companyCreated: client.companyCreated,
                    companyCreatedId: client.companyCreatedId,
                    dateCreated: client.dateCreated,
                    lastUpdated: client.lastUpdated,
                    active: client.active,
                    payrollContact: client.payrollContact,
                    payrollContactId: client.payrollContactId,
                };

                return newClient;
            });
            return result;
        }
    }
}

export default new ClientsService();

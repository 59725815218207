import { createContext } from "react";
import { User } from "../typings/api";

const UserContext = createContext<[User, (n: User) => void]>([
    { email: "", company: -1 },
    (c: User) => {
        // do nothing
        console.log(c);
    },
]);

export default UserContext;

// export const RoleProvider = roleContext.Provider;
// export const RoleConsumer = roleContext.Consumer;

/**
 *
 *
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 8/25/20, 6:24 AM
 *
 */

import APIService from "./api.service";
import { ProcessFlow } from "../typings/api/processflow";
//
//
// const API_URL = process.env.REACT_APP_API_URL;
// {
//     "where": {
//         "id": 1
//     },
//     "include": [
//         {
//             "relation": "businessRuleGroups",
//             "scope": {
//                 "include": [
//                     {
//                         "relation": "businessRules"
//                     }
//                 ]
//             }
//         }
//     ]
// }

class ProcessFlowService extends APIService<ProcessFlow> {
    constructor() {
        super("processflows");
    }
    async getAllByGroupId(groupId: number) {
        // alert("hi");
        const filter = {
            where: {
                group: groupId,
            },
            include: [
                {
                    relation: "businessRuleGroups",
                    scope: {
                        include: [
                            {
                                relation: "businessRules",
                            },
                        ],
                    },
                },
            ],
        };
        // console.error({ filter });
        const response = await this.getFiltered(filter);
        if (response) {
            response.data = response.data.map((pf: ProcessFlow) => {
                return {
                    // title: pf.title,
                    businessRuleGroups: pf.businessRuleGroups ?? [],
                    ...pf,
                };
            });
            return response;
        }
    }
    deleteByGroupId(groupId: number) {
        const filter = {
            group: groupId,
        };
        return this.deleteWhere(this.endpoint, filter);
    }
    getAllByOrder() {
        // Loopback 4 order by order field
        const filter = {
            order: ["order ASC", "id ASC"],
        };
        return this.getFiltered(filter);
    }
    getAllByOrderForStageAndGroup(stageIn: number, groupIn: number) {
        // Loopback 4 order by order field
        const filter = {
            where: {
                and: [{ stage: stageIn }, { group: groupIn }],
            },

            order: ["order ASC", "id ASC"],
        };
        // console.error({ "XXXXXXXX": JSON.stringify(filter) })
        // console.error(filter)
        return this.getFiltered(filter);
    }
    getAllByOrderForGroup(groupIn: number) {
        // Loopback 4 order by order field
        const filter = {
            where: { group: groupIn },

            include: [
                {
                    relation: "businessRuleGroups",
                    scope: {
                        include: [
                            {
                                relation: "businessRules",
                            },
                        ],
                    },
                },
            ],
            order: ["order ASC", "id ASC"],
        };
        // console.error({ "XXXXXXXX": JSON.stringify(filter) })
        // console.error(filter)
        return this.getFiltered(filter);
    }
}

export default new ProcessFlowService();

import { useEffect, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import Swal from "sweetalert2";
import fileUploadService from "../services/file.service";
import ButtonNeoGen from "./button";
import { FileUpload, FileUploadWithMultiple } from "../typings/api/file-upload";
import { downloadFile } from "../sections/utilities/download-file";
import PopoverComponent from "./popover-component";
import { useRecoilState } from "recoil";
import userAtom from "../atoms/userAtom";
import { ClearERCUser } from "../typings/api/clear-erc-user";
import { Label } from "./label";
import { Entry } from "../jason-proof-of-concept/processflows/domain/processflow";
import PrintPre from "./print-pre";
import FileUploadWidgetEntry from "./file-upload-widget-entry";
import React from "react";

function classNames(...classes: any) {
    return classes.filter(Boolean).join(" ");
}

export default function FileUploadWidget(props: AddFileProps) {
    const [user, setUser] = useRecoilState<ClearERCUser>(userAtom);
    const [isReplacing, setIsReplacing] = useState(false);
    const [newFileDetails, setNewFileDetails] = useState<FileUploadWithMultiple>();
    const queryCache = useQueryClient();
    useEffect(() => {
        if (props.fileDetails) {
            if (props.fileDetails?.hasMultiple) {
                setNewFileDetails(props.fileDetails);
            } else {
                setNewFileDetails({
                    multipleFiles: [props.fileDetails],
                    hasMultiple: true,
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.fileDetails, props.entry]);

    async function onFileChangeFromEvent(event: any) {
        return await onFileChange(event.target.files[0]);
    }

    function passUpFileDetails(fileUploadWithMultiple?: FileUploadWithMultiple) {
        if (fileUploadWithMultiple) {
            // existingArrayw.mu[

            // const fileUploadWithMultiple: FileUploadWithMultiple = {
            //     multipleFiles: newFileDetails?.multipleFiles ?? [],
            //     hasMultiple: true,
            // };
            // filesToUpload.hasMultiple = true;
            // multipleFiles: existingArray,
            // filesToUpload.multipleFiles.push(res.data);
            // props.setFileDetails(newFileDetails);

            props.setFileDetails(fileUploadWithMultiple);
            queryCache.invalidateQueries(["files"]);
            setIsReplacing(false);
            Swal.fire({
                title: "Success",
                text: "File uploaded successfully",
                icon: "success",
                showConfirmButton: false,
                timer: 1500,
            });
            return;
        }
    }

    async function onFileChange(file: File) {
        // event.target.files[0];
        // Create an object of formData
        if (!file) {
            Swal.fire({
                title: "Error",
                text: "You need to select a file",
                icon: "error",
            });
            return;
        } else {
            try {
                // alert(props.fileKey);
                let key;
                if (typeof props.fileKey === "object") {
                    key = { ...props.fileKey };
                } else {
                    key = JSON.parse(props.fileKey ?? "");
                }
                if (!key) {
                    Swal.fire({
                        title: "Error",
                        text: "You need to select a file",
                        icon: "error",
                    });
                    return;
                }
                const res = await fileUploadService.uploadFile(file, user.id ?? "", key);
                if (!res || res.status !== 200 || !res.data) {
                    console.error(1);
                    console.error(res, newFileDetails);
                }
                if (res && res.status === 200 && res.data) {
                    // existingArrayw.mu[
                    const existingArray = [...(newFileDetails?.multipleFiles ?? [])];
                    existingArray.push(res.data);
                    const filesToUpload: FileUploadWithMultiple = {
                        multipleFiles: existingArray,
                        hasMultiple: true,
                    };
                    filesToUpload.multipleFiles = existingArray;
                    // multipleFiles: existingArray,
                    // filesToUpload.multipleFiles.push(res.data);
                    props.setFileDetails(filesToUpload);
                    queryCache.invalidateQueries(["files"]);
                    setIsReplacing(false);
                    Swal.fire({
                        title: "Success",
                        text: "File uploaded successfully",
                        icon: "success",
                        showConfirmButton: false,
                        timer: 1500,
                    });
                    return;
                }
            } catch (error) {
                console.error(error);
            }
        }

        Swal.fire({
            title: "Error",
            text: "File upload failed",
            icon: "error",
            showConfirmButton: false,
            timer: 1500,
        });
    }
    return (
        <>
            <Label
                htmlFor="file"
                text={props.label || ""}
                helpDocument={props.helpDocument}
                isRequired={!!props.required}
            />
            {newFileDetails && !isReplacing && (
                <>
                    <div className="my-5 p-3 text-sm text-gray-700 dark:text-green-500 rounded-lg dark:bg-slate-900  bg-slate-200 flex flex-col w-full gap-3">
                        {newFileDetails?.multipleFiles.map((file: FileUpload, index: number) => {
                            return (
                                <React.Fragment key={index}>
                                    <FileUploadWidgetEntry
                                        file={file}
                                        key={index}
                                        setIsReplacing={setIsReplacing}
                                        onFileChange={onFileChange}
                                        newFileDetails={newFileDetails}
                                        setNewFileDetails={setNewFileDetails}
                                        passUpFileDetails={passUpFileDetails}
                                        // deleteFile={deleteFile}
                                    />
                                </React.Fragment>
                            );
                        })}
                        <div className="flex flex-row justify-between gap-5 mt-0">
                            <ButtonNeoGen
                                type="info"
                                className="w-full mt-0"
                                icon="fas fa-plus"
                                onClick={() => {
                                    setIsReplacing(false);
                                    // Provide a dialog to select a file
                                    const input = document.createElement("input");
                                    input.type = "file";
                                    input.onchange = (e) => {
                                        const file = (e.target as HTMLInputElement)?.files?.[0];
                                        if (file) {
                                            onFileChange(file);
                                        }
                                    };
                                    input.click();
                                }}
                                text="Upload File(s)"
                                size="md"
                            />
                        </div>
                    </div>
                </>
            )}
            {isReplacing && (
                <>
                    <div className="flex w-full  bg-grey-lighter my-auto justify-center flex-grow">
                        <label className="w-full md:w-64 flex flex-col items-center px-4 py-6 bg-white text-blue rounded-lg  tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue hover:text-blue-500 dark:bg-gray-900 border-1 my-5 shadow-lg dark:border-gray-700 dark:hover:bg-emerald-900/30">
                            <svg
                                className="w-8 h-8"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                            >
                                <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                            </svg>
                            <span className="mt-2 text-base leading-normal">Select a file</span>
                            <input type="file" className="hidden" onChange={onFileChangeFromEvent} />
                        </label>
                    </div>
                </>
            )}
            {!props.fileDetails && !isReplacing && (
                <>
                    <div className="flex w-full  bg-grey-lighter my-auto justify-center flex-grow">
                        <label className="w-full md:w-64 flex flex-col items-center px-4 py-6 bg-white text-blue rounded-lg  tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue hover:text-blue-500 dark:bg-gray-900 border-1 my-5 shadow-lg dark:border-gray-700 dark:hover:bg-emerald-900/30">
                            <svg
                                className="w-8 h-8"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                            >
                                <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                            </svg>
                            <span className="mt-2 text-base leading-normal">Select a file</span>
                            <input type="file" className="hidden" onChange={onFileChangeFromEvent} />
                        </label>
                    </div>
                </>
            )}
        </>
    );
}

export type FileDetails = {
    id: number;
    file?: FileUpload;
    multipleFiles?: FileUploadWithMultiple;
};

type AddFileProps = {
    label?: string;
    name?: string;
    setFileDetails: (filesDetails: FileUploadWithMultiple) => void;
    fileDetails?: FileUpload | FileUploadWithMultiple;
    helpDocument?: string;
    required?: boolean;
    fileKey?: object | string;
    entry: Entry;
};

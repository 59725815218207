export const formatCurrency = (amount: number) => {
    return amount.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        compactDisplay: "short",
        maximumFractionDigits: 0,
    });
};

export function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
}

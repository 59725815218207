import "./wdyr"; // <--- first import
//
// //import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import packageJson from "../package.json";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import "./i18n";

const commitId = process.env.REACT_APP_COMMIT_ID;

if (process.env.NODE_ENV !== "development") {
    Sentry.init({
        dsn: "https://9bf5ba06dc5042b99b9a7590544bfcec@o4504022380118016.ingest.sentry.io/4504022381690880",
        environment: process.env.REACT_APP_ENVIRONMENT,
        // This sets the sample rate to be 10%. You may want this to be 100% while
        // in development and sample at a lower rate in production
        replaysSessionSampleRate: 0.1,
        // If the entire session is not sampled, use the below sample rate to sample
        // sessions when an error occurs.
        replaysOnErrorSampleRate: 1.0,
        integrations: [
            new Sentry.Replay({
                // Additional SDK configuration goes in here, for example:
                maskAllText: true,
                blockAllMedia: true,
            }),
            new BrowserTracing(),
        ],
        release: commitId,
        tracesSampleRate: 1.0,
    });
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore This is allowed
global.appVersion = commitId;

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );
// After
import { createRoot } from "react-dom/client";
const container = document.getElementById("root");
if (container) {
    const root = createRoot(container);
    root.render(<App />);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { useState } from "react";
import InputControlled from "../../../../layout/input-controlled";
import ModalDialog from "../../../../layout/modal-dialog";

/**
 * @component
 */
function AddRoleModal(props: AddRoleModalProps) {
    const [roleName, setRoleName] = useState("");
    const [roleCode, setRoleCode] = useState("");

    return (
        <ModalDialog
            title="Add Role"
            show={props.show}
            close={props.handleClose}
            okAction={() => {
                const data = {
                    name: roleName,
                    roleCode: roleCode,
                };
                props.RoleService.create(data).then((r: any) => {
                    props.handleClose();
                    props.loadData();
                });
            }}
        >
            <InputControlled
                label="Role Name:"
                name="name"
                value={roleName}
                setValue={(e: string) => {
                    setRoleName(e);
                }}
            />
            <InputControlled
                label="Role Code:"
                name="roleCode"
                value={roleCode}
                setValue={(e: string) => {
                    setRoleCode(e);
                }}
            />
        </ModalDialog>
    );
}

export default AddRoleModal;

type AddRoleModalProps = {
    RoleService: any;
    handleClose: any;
    loadData: any;
    show: boolean;
};

import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router";
import ModalDialog from "../modal-dialog";
import SelectNeoGen from "../select-neogen";
import fileService from "../../services/file.service";

type Fields = { name: string; id: string; stage: number };
function compare(b: any, a: any) {
    if (a.dateUploaded < b.dateUploaded) {
        return -1;
    }
    if (a.dateUploaded > b.dateUploaded) {
        return 1;
    }
    return 0;
}
export default function AddVideo(props: AddVideoProps) {
    const [value, setValue] = useState("");
    const [fields, setFields] = useState<Fields[]>([]);
    const { processflowGroup: id } = useParams();
    useEffect((): void => {
        setValue("");
    }, []);

    const filesQuery = useQuery(["videoFiles"], async () => {
        const response = await fileService.getAll();
        if (response) {
            return response.data.sort(compare).filter((file) => file.mimetype.startsWith("video/mp4"));
        }
    });

    return (
        <ModalDialog
            title="Add Video"
            close={props.close}
            show={props.show}
            okAction={(): void => {
                props.setValue(value);
                props.close();
            }}
        >
            {/* <PrintPre> */}
            {/* {fields} */}
            {/* </PrintPre> */}
            {/*
            <PrintPre>
                {entriesQuery.data}
            </PrintPre> */}
            <SelectNeoGen
                label="Video"
                options={filesQuery.data?.map((file) => ({ id: file.filename, name: file.originalFilename })) ?? []}
                className="mb-5"
                onChange={(value: number | string) => {
                    setValue(value.toString());
                }}
                // value={field}
            />
            {/* <InputNeoGenControlled
                label="Response Variable"
                value={value}
                setValue={(e): void => {
                    setValue(e);
                }}
            /> */}
        </ModalDialog>
    );
}

type AddVideoProps = {
    // currentStage?: any;
    setValue: (value: string) => void;
    show: boolean;
    close: () => void;
};

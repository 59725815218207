import { XCircleIcon } from "@heroicons/react/20/solid";

type ErrorSectionProps = {
    errors: string[];
    overrideDarkMode?: boolean;
};

export default function ErrorSection(props: ErrorSectionProps) {
    return (
        <div
            className={
                "rounded-xl shadow-xl border border-red-500  p-4 mb-5 mt-5 " +
                (props.overrideDarkMode ? " bg-red-900 dark:bg-red-900 " : " bg-red-50 dark:bg-red-900 ")
            }
        >
            <div className="flex">
                <div className="flex-shrink-0">
                    <XCircleIcon
                        className={
                            "h-5 w-5 " +
                            (props.overrideDarkMode
                                ? "text-white opacity-100 dark:text-white dark:opacity-100"
                                : "text-red-400 dark:text-white dark:opacity-100")
                        }
                        aria-hidden="true"
                    />
                </div>
                <div className="ml-3">
                    <h3
                        className={
                            "text-sm font-medium " +
                            (props.overrideDarkMode
                                ? "text-red-100 dark:text-red-100"
                                : "text-red-800 dark:text-red-100")
                        }
                    >
                        {props.errors.length === 1 ? (
                            <>There was a problem with your submission</>
                        ) : (
                            <>There were {props.errors.length} errors with your submission</>
                        )}
                    </h3>
                    <div
                        className={
                            "mt-2 text-sm " +
                            (props.overrideDarkMode
                                ? "text-red-200 dark:text-red-200"
                                : "text-red-700 dark:text-red-200")
                        }
                    >
                        <ul role="list" className="list-disc space-y-1 pl-5">
                            {props.errors.map((error, index) => (
                                <li key={index}>{error}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

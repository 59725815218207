import { useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./styles.css";

export default function Help() {
    const [value, setValue] = useState("");

    const modules = {
        toolbar: [
            [{ header: [1, 2, false] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
            ["link", "image"],
            ["clean"],
        ],
        // imageResize: {
        // parchment: Quill.import("parchment"),
        // See optional "config" below
        // },
    };

    const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
    ];

    return (
        <>
            <ReactQuill
                className="bg-gray-300 dark:bg-gray-800 dark:text-gray-400 text-gray-200 rounded-lg shadow-md h-96"
                // theme="snow"
                modules={modules}
                formats={formats}
                value={value}
                onChange={setValue}
            />
            {value}
        </>
    );
}

import { makeRequest } from "../../shared/utils";
import { ProcessflowProgress } from "../domain/processflow-progress";
import { getProcessflowProgress } from "./get-processflow-progress";

export const updateProcessflowProgress = async ({
    authToken,
    id,
    data,
}: {
    authToken?: string;
    id: number;
    data: Partial<ProcessflowProgress>;
}) => {
    await makeRequest({
        method: "patch",
        path: `/processflow-progresses/${id}`,
        authToken,
        data: { ...data, data: JSON.stringify(data.data) },
    });

    const processflowProgress = await getProcessflowProgress({ authToken, id });

    return processflowProgress;
};
